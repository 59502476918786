import React, { forwardRef } from 'react';

import { useTheme } from '@material-ui/core';
import {
  borders,
  display,
  flexbox,
  grid,
  positions,
  shadows,
  sizing,
  typography,
} from '@material-ui/system';
import styled from 'styled-components';
import { get } from 'lodash';

import { Spacing, BorderRadius } from '../Theme';

import {
  BoxProps,
  StyledBoxProps,
  SpacingValues,
  RadiusSize,
} from './Box.types';

/**
 * Возвращает строку радиуса с использованием значений из темы.
 *
 * @param {RadiusSize} [radius] - Размер радиуса, который может быть строкой или массивом строк.
 * @param {BorderRadius} [radiusTokens] - Объект с токенами радиусов из темы.
 * @returns {string | undefined} - Строка с радиусом или undefined, если radius или radiusTokens не заданы.
 */
const getRadiusString = (radius?: RadiusSize, radiusTokens?: BorderRadius) => {
  if (!radius || !radiusTokens) {
    return undefined;
  }

  return Array.isArray(radius)
    ? radius.map((el) => `${radiusTokens[el]}px`).join(' ')
    : radiusTokens[radius];
};

/**
 * Настраиваемый компонент-Box, созданный с использованием Material-UI и styled-components.
 *
 * @component
 * @param {BoxProps} props - Свойства для компонента Box.
 * @param {ReactNode} [props.children] - Содержимое, отображаемое внутри Box.
 * @param {string} [props.className] - Имя класса для Box.
 * @param {React.CSSProperties} [props.style] - Инлайн-стили для Box.
 * @param {string} [props.id] - Идентификатор для Box.
 * @param {number} [props.tabIndex] - Индекс табуляции для Box.
 * @param {'pointer' | 'text' | 'inherit' | 'default' | 'initial' | 'unset' | 'wait' | 'auto' | 'grab' | 'grabbing' | 'crosshair' | 'not-allowed' | 'zoom-in' | 'zoom-out' | 'move' | 'vertical-text' | 'progress' | 'col-resize' | 'row-resize' | 'n-resize' | 'e-resize' | 's-resize' | 'w-resize' | 'ne-resize' | 'nw-resize' | 'se-resize' | 'sw-resize' | 'all-scroll' | 'no-drop' | 'none'} [props.cursor] - Тип курсора для Box.
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onClick] - Обработчик события клика.
 * @param {React.FocusEventHandler<HTMLDivElement>} [props.onFocus] - Обработчик события фокуса.
 * @param {React.FocusEventHandler<HTMLDivElement>} [props.onBlur] - Обработчик события потери фокуса.
 * @param {React.ChangeEventHandler<HTMLDivElement>} [props.onChange] - Обработчик события изменения.
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onContextMenu] - Обработчик события контекстного меню.
 * @param {React.DragEventHandler<HTMLDivElement>} [props.onDrag] - Обработчик события начала перетаскивания.
 * @param {React.DragEventHandler<HTMLDivElement>} [props.onDragEnd] - Обработчик события окончания перетаскивания.
 * @param {React.DragEventHandler<HTMLDivElement>} [props.onDragEnter] - Обработчик события входа перетаскиваемого элемента.
 * @param {React.DragEventHandler<HTMLDivElement>} [props.onDragExit] - Обработчик события выхода перетаскиваемого элемента.
 * @param {React.DragEventHandler<HTMLDivElement>} [props.onDragLeave] - Обработчик события покидания перетаскиваемым элементом.
 * @param {React.DragEventHandler<HTMLDivElement>} [props.onDragOver] - Обработчик события прохождения над перетаскиваемым элементом.
 * @param {React.DragEventHandler<HTMLDivElement>} [props.onDragStart] - Обработчик события начала перетаскивания.
 * @param {React.DragEventHandler<HTMLDivElement>} [props.onDrop] - Обработчик события отпускания перетаскиваемого элемента.
 * @param {React.KeyboardEventHandler<HTMLDivElement>} [props.onKeyDown] - Обработчик события нажатия клавиши.
 * @param {React.KeyboardEventHandler<HTMLDivElement>} [props.onKeyPress] - Обработчик события удержания клавиши.
 * @param {React.KeyboardEventHandler<HTMLDivElement>} [props.onKeyUp] - Обработчик события отпускания клавиши.
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onMouseDown] - Обработчик события нажатия мыши.
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onMouseEnter] - Обработчик события входа мыши.
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onMouseLeave] - Обработчик события выхода мыши.
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onMouseMove] - Обработчик события перемещения мыши.
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onMouseOut] - Обработчик события покидания мыши.
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onMouseOver] - Обработчик события наведения мыши.
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onMouseUp] - Обработчик события отпускания мыши.
 * @param {string} [props.testId] - Тестовый идентификатор для Box.
 * @param {string | React.ElementType} [props.component] - Компонент, который будет использоваться в качестве корневого элемента.
 * @param {string | number} [props.gap] - Расстояние между элементами сетки.
 * @param {string} [props.transform] - Свойство transform.
 * @param {SpacingValues} [props.m] - Значение отступа.
 * @param {SpacingValues} [props.mt] - Значение отступа сверху.
 * @param {SpacingValues} [props.mr] - Значение отступа справа.
 * @param {SpacingValues} [props.mb] - Значение отступа снизу.
 * @param {SpacingValues} [props.ml] - Значение отступа слева.
 * @param {SpacingValues} [props.mx] - Значение отступа слева и справа.
 * @param {SpacingValues} [props.my] - Значение отступа сверху и снизу.
 * @param {SpacingValues} [props.p] - Значение заполнения.
 * @param {SpacingValues} [props.pt] - Значение заполнения сверху.
 * @param {SpacingValues} [props.pr] - Значение заполнения справа.
 * @param {SpacingValues} [props.pb] - Значение заполнения снизу.
 * @param {SpacingValues} [props.pl] - Значение заполнения слева.
 * @param {SpacingValues} [props.px] - Значение заполнения слева и справа.
 * @param {SpacingValues} [props.py] - Значение заполнения сверху и снизу.
 * @param {SpacingValues} [props.margin] - Значение отступа.
 * @param {SpacingValues} [props.marginTop] - Значение отступа сверху.
 * @param {SpacingValues} [props.marginRight] - Значение отступа справа.
 * @param {SpacingValues} [props.marginBottom] - Значение отступа снизу.
 * @param {SpacingValues} [props.marginLeft] - Значение отступа слева.
 * @param {SpacingValues} [props.marginX] - Значение отступа слева и справа.
 * @param {SpacingValues} [props.marginY] - Значение отступа сверху и снизу.
 * @param {SpacingValues} [props.padding] - Значение заполнения.
 * @param {SpacingValues} [props.paddingTop] - Значение заполнения сверху.
 * @param {SpacingValues} [props.paddingRight] - Значение заполнения справа.
 * @param {SpacingValues} [props.paddingBottom] - Значение заполнения снизу.
 * @param {SpacingValues} [props.paddingLeft] - Значение заполнения слева.
 * @param {SpacingValues} [props.paddingX] - Значение заполнения слева и справа.
 * @param {SpacingValues} [props.paddingY] - Значение заполнения сверху и снизу.
 * @param {BoxColors} [props.bgcolor] - Цвет фона.
 * @param {BoxColors} [props.color] - Цвет текста.
 * @param {BoxColors} [props.borderColor] - Цвет границы.
 * @param {RadiusSize} [props.radius] - Радиус границы.
 * @returns {JSX.Element} Компонент Box.
 */

export const Box = styled(
  forwardRef(({ component, testId = 'box', ...props }: BoxProps, ref) => {
    const {
      children,
      className,
      style,
      id,
      tabIndex,
      cursor,
      onClick,
      onFocus,
      onBlur,
      onChange,
      onContextMenu,
      onDrag,
      onDragEnd,
      onDragEnter,
      onDragExit,
      onDragLeave,
      onDragOver,
      onDragStart,
      onDrop,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
      onMouseOut,
      onMouseOver,
      onMouseUp,
    } = props;

    const htmlProps = {
      ref,
      children,
      className: `aqa_box${className ? ` ${className}` : ''}`,
      style,
      id,
      tabIndex,
      cursor,
      onClick,
      onFocus,
      onBlur,
      onChange,
      onContextMenu,
      onDrag,
      onDragEnd,
      onDragEnter,
      onDragExit,
      onDragLeave,
      onDragOver,
      onDragStart,
      onDrop,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
      onMouseOut,
      onMouseOver,
      onMouseUp,
      'data-testid': testId,
    };

    if (!component) {
      return React.createElement('div', htmlProps);
    } else if (typeof component === 'string') {
      return React.createElement(component, htmlProps);
    } else {
      const Component = component;

      return (
        <Component
          ref={ref}
          {...props}
          className={`aqa_box${className ? ` ${className}` : ''}`}
        />
      );
    }
  }),
)<StyledBoxProps>(
  ({
    gap,
    transform,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginX,
    marginY,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingX,
    paddingY,
    bgcolor,
    color,
    borderColor,
    radius,
    cursor,
    pointerEvents,
    ...props
  }: StyledBoxProps) => {
    const theme = useTheme();

    const styles = {
      ...borders({
        ...props,
        borderRadius: getRadiusString(radius, theme.tokens.radius),
        borderColor: undefined,
      }),
      ...display(props),
      ...flexbox(props),
      ...grid(props),
      ...positions(props),
      ...shadows(props),
      ...sizing(props),
      ...typography(props),
      cursor,
      pointerEvents: pointerEvents || 'auto',
      gap:
        gap &&
        theme.tokens.spacing[
          Math.abs(parseInt(gap, 10)).toString() as keyof Spacing
        ],
      transform,
      ...(bgcolor && {
        backgroundColor: get(theme.tokens.colors, bgcolor),
      }),
      ...(color && {
        color: get(theme.tokens.colors, color),
      }),
      ...(borderColor && {
        borderColor: get(theme.tokens.colors, borderColor),
      }),

      createMediaQuery(
        breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
        propertyName: string,
        value: SpacingValues | undefined,
      ) {
        if (typeof value === 'undefined' || value === null) return;

        this[theme.breakpoints.up(breakpoint)] = {
          ...this[theme.breakpoints.up(breakpoint)],
          [propertyName]:
            value === 'auto' || value === 'unset'
              ? value
              : (value.startsWith('-') ? -1 : 1) *
                theme.tokens.spacing[
                  Math.abs(parseInt(value, 10)).toString() as keyof Spacing
                ],
        };
      },
      createSpacingStyles(
        propertyName: string,
        value:
          | SpacingValues
          | {
              xs?: SpacingValues;
              sm?: SpacingValues;
              md?: SpacingValues;
              lg?: SpacingValues;
              xl?: SpacingValues;
            },
      ) {
        if (typeof value === 'undefined' || value === null) return;

        if (typeof value === 'string') {
          this[propertyName] =
            value === 'auto' || value === 'unset'
              ? value
              : (value.startsWith('-') ? -1 : 1) *
                theme.tokens.spacing[
                  Math.abs(parseInt(value, 10)).toString() as keyof Spacing
                ];
        } else {
          if (!Object.keys(value).length || Array.isArray(value)) return;

          const { xs, sm, md, lg, xl } = value;
          this.createMediaQuery('xs', propertyName, xs);
          this.createMediaQuery('sm', propertyName, sm);
          this.createMediaQuery('md', propertyName, md);
          this.createMediaQuery('lg', propertyName, lg);
          this.createMediaQuery('xl', propertyName, xl);
        }
      },
    };

    styles.createSpacingStyles('margin', margin || m);
    styles.createSpacingStyles('marginTop', marginTop || mt);
    styles.createSpacingStyles('marginRight', marginRight || mr);
    styles.createSpacingStyles('marginBottom', marginBottom || mb);
    styles.createSpacingStyles('marginLeft', marginLeft || ml);
    styles.createSpacingStyles('marginLeft', marginX || mx);
    styles.createSpacingStyles('marginRight', marginX || mx);
    styles.createSpacingStyles('marginTop', marginY || my);
    styles.createSpacingStyles('marginBottom', marginY || my);
    styles.createSpacingStyles('padding', padding || p);
    styles.createSpacingStyles('paddingTop', paddingTop || pt);
    styles.createSpacingStyles('paddingRight', paddingRight || pr);
    styles.createSpacingStyles('paddingBottom', paddingBottom || pb);
    styles.createSpacingStyles('paddingLeft', paddingLeft || pl);
    styles.createSpacingStyles('paddingLeft', paddingX || px);
    styles.createSpacingStyles('paddingRight', paddingX || px);
    styles.createSpacingStyles('paddingTop', paddingY || py);
    styles.createSpacingStyles('paddingBottom', paddingY || py);

    return styles;
  },
);
