import { injectable } from 'inversify';

import type { FlowTreeNode } from '@vk-hr-tek/core/flow';
import { AppError } from '@vk-hr-tek/core/error';

import type { EventNodeFlow, NodeAction, Transition } from '@app/gen/events';

import { EventsFlowCommonMapper } from './events.flow-common.mapper';

const REJECT_ACTION_TYPE = ['return'];

const CUSTOM_ACTION_TYPE = [
  'system_booking_hook',
  'system_booking_approve',
  'system_booking_trip_create',
  'system_booking_trip_change',
  'system_booking_trip_ordering',
  'system_booking_limits_exceeded_approve',
  'system_booking_trip_limit_approved',
  'system_booking_trip_cancel',
];

@injectable()
export class EventsFlowMapper {
  constructor(private eventsFlowCommonMapper: EventsFlowCommonMapper) {}

  private nodes: EventNodeFlow[];

  private constrictions: Set<string>;

  private inPaper: boolean;

  private paperNodeId: number;

  private action: NodeAction;

  private setNodeAction(actions: NodeAction[]): void {
    const action = actions.find(({ is_primary }) => is_primary);

    if (action) {
      this.action = action;
    }
  }

  private rawNodeToResultNode(
    node: EventNodeFlow,
    startNode = false,
  ): FlowTreeNode {
    this.setNodeAction(node.actions);

    if (startNode) {
      return this.toStartNode(node);
    }

    if (this.action.type === 'system' && !node.transitions?.length) {
      return this.toFinalNode(node);
    }

    const {
      node_id: id,
      status,
      actor,
      documents,
      activity_date: date,
      name,
      custom_state: customState,
      is_optional: isOptional,
    } = node;
    const state = this.eventsFlowCommonMapper.mapStatus(status);

    const allExecutors = state === 'active' || state === 'skipped' || !date;

    const nodeTitle = customState?.name
      ? customState.name
      : this.eventsFlowCommonMapper.getNodeTitle(this.action);

    const customActionTitles = CUSTOM_ACTION_TYPE.includes(this.action.type);

    const dataTitle = customActionTitles ? name : nodeTitle;

    const returnedData =
      this.inPaper && state === 'feature'
        ? this.getPaperNode(node)
        : ({
            id,
            title: dataTitle,
            info: status === 'active' ? documents : [],
            enabled: status !== 'inactive',
            state: state,
            tooltip: this.eventsFlowCommonMapper.getTooltip(node, this.action),
            optionalNode: isOptional,
          } as FlowTreeNode);

    if (allExecutors) {
      returnedData.executors = this.eventsFlowCommonMapper.getExecutorsData(
        this.action,
      );
    } else {
      returnedData.executor = { position: actor?.role, name: actor?.fio ?? '' };
    }

    return returnedData;
  }

  private getConstrictionNode(transitions: Transition[]): FlowTreeNode {
    let constrictionChild: FlowTreeNode | null = null;
    const constrictOf = [];

    for (const { to_node_id: toNodeId } of transitions) {
      let rawNode = this.nodes.find(
        ({ node_id: nodeId }) => nodeId === toNodeId,
      );
      let parentNode = rawNode ? this.rawNodeToResultNode(rawNode) : null;

      if (parentNode) {
        constrictOf.push(parentNode);

        loop: while (rawNode) {
          const { transitions: nodeTransitions = [] } = rawNode;
          const children = [];

          for (const { to_node_id: id } of nodeTransitions) {
            rawNode = this.nodes.find(({ node_id: nodeId }) => nodeId === id);

            if (this.constrictions.has(id)) {
              if (constrictionChild) {
                if (constrictionChild.id !== id) {
                  throw new AppError('client', {
                    code: 400,
                    message: 'Bad Request',
                    error: 'Bad Request',
                  });
                }
              } else if (rawNode) {
                constrictionChild = this.getNode(rawNode.node_id);
                if (!constrictionChild) {
                  throw new AppError('client', {
                    code: 400,
                    message: 'Bad Request',
                    error: 'Bad Request',
                  });
                }
              }
              break loop;
            } else if (rawNode) {
              const node = this.rawNodeToResultNode(rawNode);
              children.push(node);
              parentNode.children = children;
              parentNode = node;
            }
          }
        }
      }
    }

    const constrictionNode: FlowTreeNode = {
      id: `constriction-to-${constrictionChild?.id ?? 'none'}`,
      constrictOf,
      state: constrictionChild?.state ?? 'feature',
      enabled: true,
      title: 'constriction',
    };

    if (constrictionChild) {
      constrictionNode.children = [constrictionChild];
    }

    return constrictionNode;
  }

  private getNode(id: string, startNode = false): FlowTreeNode | null {
    const rawNode = this.nodes.find(({ node_id: nodeId }) => nodeId === id);

    if (!rawNode) {
      return null;
    }

    const resultNode = this.rawNodeToResultNode(rawNode, startNode);

    if (resultNode.state !== 'paper') {
      const { actions, transitions } = rawNode;

      if (actions.length === 1 && transitions.length > 1) {
        const constriction = this.getConstrictionNode(transitions);
        resultNode.children = [constriction];
      } else {
        const children = transitions?.reduce(
          (res: FlowTreeNode[], { to_node_id: toNodeId }) => {
            const child = this.getNode(toNodeId);
            if (child) {
              res.push(child);
            }
            return res;
          },
          [],
        );

        if (children.length) {
          resultNode.children = children;
        } else if (this.inPaper) {
          resultNode.children = [this.getPaperNode(rawNode)];
        }
      }
    }

    return resultNode;
  }

  private getPaperNode(node: EventNodeFlow): FlowTreeNode {
    return {
      id: `paper_node_${node.node_id}_${this.paperNodeId++}`,
      title: 'В бумаге',
      enabled: true,
      state: 'paper',
      tooltip: this.eventsFlowCommonMapper.getTooltip(node, this.action, true),
      optionalNode: node.is_optional,
    };
  }

  private toStartNode({
    actor,
    node_id: id,
    status,
  }: EventNodeFlow): FlowTreeNode {
    return {
      id,
      title: 'Старт',
      state: this.eventsFlowCommonMapper.mapStatus(status),
      enabled: status !== 'inactive',
      executor: { position: actor?.role ?? '' },
    };
  }

  private toFinalNode(node: EventNodeFlow): FlowTreeNode {
    const { node_id: id, status, name } = node;

    const processStatus = name === 'Отказ от подписания' ? 'canceled' : status;

    return {
      id,
      title: name || 'Завершено',
      state: this.eventsFlowCommonMapper.mapStatus(processStatus),
      enabled: status !== 'inactive',
      tooltip: this.eventsFlowCommonMapper.getTooltip(node, this.action),
    };
  }

  public init(nodes: EventNodeFlow[], inPaper: boolean) {
    this.nodes = nodes.map(({ actions, transitions, ...node }) => ({
      ...node,
      actions: actions.filter(({ type }) => !REJECT_ACTION_TYPE.includes(type)),
      transitions: transitions.filter(
        ({ action_type: type }) => !REJECT_ACTION_TYPE.includes(type),
      ),
    }));

    this.inPaper = inPaper;
    this.paperNodeId = 0;

    const found = new Set<string>();
    this.constrictions = new Set<string>();

    for (const { transitions } of nodes) {
      for (const { to_node_id: toNodeId } of transitions) {
        if (found.has(toNodeId)) {
          this.constrictions.add(toNodeId);
        } else {
          found.add(toNodeId);
        }
      }
    }
  }

  public getFlowTree(id: string): FlowTreeNode | null {
    return this.getNode(id, true);
  }
}
