import { injectable } from 'inversify';
import { format, parseISO } from 'date-fns';

import { t } from '@vk-hr-tek/core/translations/t';
import type {
  FlowTreeNodeState,
  FlowTreeNodeTooltip,
  Executor,
} from '@vk-hr-tek/core/flow';

import type {
  EventNodeFlow,
  EventNodeFlowActor,
  NodeAction,
} from '@app/gen/events';

const CUSTOM_ACTION_TYPE = [
  'system_booking_hook',
  'system_booking_approve',
  'system_booking_trip_create',
  'system_booking_trip_change',
  'system_booking_trip_ordering',
  'system_booking_limits_exceeded_approve',
  'system_booking_trip_limit_approved',
  'system_booking_trip_cancel',
];

@injectable()
export class EventsFlowCommonMapper {
  public mapStatus(
    status: 'finished' | 'skipped' | 'active' | 'canceled' | 'inactive',
  ): FlowTreeNodeState {
    switch (status) {
      case 'finished':
        return 'success';
      case 'skipped':
        return 'skipped';
      case 'canceled':
        return 'rejected';
      case 'active':
        return 'active';
      case 'inactive':
      default:
        return 'feature';
    }
  }

  public getTooltipComment(
    cancelReason:
      | 'undefined'
      | 'node_deadline'
      | 'event_deadline'
      | 'manual_employee'
      | 'manual_company'
      | 'dismiss'
      | 'workflow_change',
    action: NodeAction,
    date?: string,
  ): string | string[] | null {
    switch (cancelReason) {
      case 'node_deadline':
      case 'event_deadline': {
        switch (action.type) {
          case 'ukep_sign':
          case 'ukep_sign_batch':
          case 'unep_sign':
          case 'unep_sign_batch':
          case 'pep_sign':
          case 'pep_sign_batch':
            return 'В связи с тем, что документ(ы) не были подписаны в срок, установленный работодателем для подписания электронной подписью';
          case 'upload':
          case 'generate_document_from_template':
            return 'Отменена в связи с тем, что документ не был добавлен в срок, установленный работодателем';
          case 'accept':
          case 'system':
            return 'Отменена в связи с тем, что заявка не была обработана в срок, установленный работодателем';

          default:
            return '';
        }
      }
      case 'dismiss':
        return date
          ? [
              t('event.mappers.flow.cancelForEmployeeDismissalText'),
              `Дата увольнения: ${this.dateFormat(date, true)}`,
            ]
          : t('event.mappers.flow.byEmployeeDismissalText');
      case 'workflow_change':
        return 'Так как компания внесла изменения в порядок оформления. Заявку необходимо создать заново. Приносим извинения за доставленные неудобства.';
      default:
        return null;
    }
  }

  public getTooltipInfo(
    actions: NodeAction[],
    nodeDocuments: string[],
  ): string[] {
    const fromActions = actions.reduce(
      (res: string[], { documents, extra }) => {
        if (extra?.document_type_name) {
          if (!res.includes(extra.document_type_name)) {
            res.push(extra.document_type_name);
          }

          return res;
        }

        return documents
          ? documents.reduce((r, { document_type_name: name }) => {
              if (!r.includes(name)) {
                r.push(name);
              }
              return r;
            }, res)
          : res;
      },
      [],
    );

    return fromActions.length ? fromActions : nodeDocuments;
  }

  public getNodeTitle(action: NodeAction): string {
    switch (action.type) {
      case 'upload':
      case 'generate_document_from_template':
        return 'Загрузка';
      case 'ukep_sign':
      case 'ukep_sign_batch':
      case 'unep_sign':
      case 'unep_sign_batch':
      case 'pep_sign':
      case 'pep_sign_batch':
        return 'Подписание';
      case 'accept':
      case 'system_condition_positive':
      case 'system_condition_negative':
        return 'Проверка';
      case 'system':
        return 'Обработка';
      case 'decline':
        return 'Отказ';
      case 'system_sync_scheduled_vacations':
        return 'Согласование';
      case 'system_set_vacation_days':
      case 'competency_eval':
      case 'competency_profile':
        return 'Заполнение';
      default:
        return '';
    }
  }

  public getTooltipTitle({
    name,
    status,
    action,
    actions,
    custom_state: customState,
  }: EventNodeFlow & { action: NodeAction }): string {
    if (customState?.name) {
      return customState.name;
    }

    switch (status) {
      case 'finished': {
        if (actions.find(({ type }) => type === 'decline_sign')) {
          return 'Отказ от подписания';
        } else {
          switch (action.type) {
            case 'upload':
            case 'generate_document_from_template':
              return 'Загружено';
            case 'ukep_sign':
            case 'ukep_sign_batch':
            case 'unep_sign':
            case 'unep_sign_batch':
            case 'pep_sign':
            case 'pep_sign_batch':
              return 'Подписано';
            case 'accept':
            case 'system_condition_positive':
            case 'system_condition_negative':
              return 'Проверено';
            case 'system':
              return 'Обработано';
            case 'decline':
            case 'decline_sign':
              return 'Отказано';
            case 'competency_eval':
              return 'Заполнение';
            case 'competency_profile':
              return 'Заполнение';
            case 'system_set_vacation_days':
              return 'Заполнение';
            case 'system_sync_scheduled_vacations':
              return 'Согласование';
            default:
              return 'Завершено';
          }
        }
      }
      case 'active':
      case 'inactive':
        return this.getNodeTitle(action);
      case 'canceled':
        return 'Отменено';
      case 'skipped':
        switch (action.type) {
          case 'upload':
          case 'generate_document_from_template':
            return 'Не загружено';
          case 'ukep_sign':
          case 'ukep_sign_batch':
          case 'unep_sign':
          case 'unep_sign_batch':
          case 'pep_sign':
          case 'pep_sign_batch':
            return 'Не подписано';
          case 'accept':
            return 'Не проверено';
          case 'system':
            return 'Не обработано';
          case 'decline':
          case 'decline_sign':
            return 'Не отказано';
          case 'system_set_vacation_days':
            return 'Заполнение';
          case 'system_sync_scheduled_vacations':
            return 'Согласование';
          case 'competency_eval':
            return 'Заполнение';
          case 'competency_profile':
            return 'Заполнение';
          default:
            return 'Пропущено';
        }
      default:
        return name;
    }
  }

  public getTooltip(
    node: EventNodeFlow,
    action: NodeAction,
    inPaper?: boolean,
  ): FlowTreeNodeTooltip {
    const {
      activity_date: date,
      actor,
      cancel_reason: cancelReason,
      actions,
      deadline,
      documents,
      status,
      name,
      is_optional: isOptional,
      transitions,
    } = node;

    const nextNodeIsOptional = transitions.some(
      ({ action_type }) => action_type === 'system_deadline',
    );

    if (inPaper) {
      return {
        title: 'В бумаге',
        comment: 'Документы по заявке нужно подписать на бумаге, как раньше',
        date: this.dateFormat(date),
      };
    }

    const tooltipTitle = CUSTOM_ACTION_TYPE.includes(action.type)
      ? name
      : this.getTooltipTitle({ ...node, action });

    const tooltip: FlowTreeNodeTooltip = {
      title: tooltipTitle,
      date: this.dateFormat(date),
      info: this.getTooltipInfo(actions, documents),
    };

    if (isOptional) {
      tooltip.subtitle = 'Опциональный этап';
    }

    if (isOptional && status === 'active') {
      tooltip.date = `Дедлайн: ${this.dateFormat(deadline)}`;
    }

    if (status === 'canceled' || status === 'skipped') {
      tooltip.comment = cancelReason
        ? this.getTooltipComment(cancelReason, action, date)
        : null;
      tooltip.executors = this.getExecutorsData(action);

      if (!cancelReason && !isOptional) {
        tooltip.info = ['Заявка перешла на следующий этап автоматически'];
      }

      if (isOptional && nextNodeIsOptional) {
        tooltip.description =
          'Заявка автоматически перешла на опциональный этап';
      }

      if (
        (!cancelReason ||
          ['manual_employee', 'manual_company', 'undefined'].includes(
            cancelReason,
          )) &&
        actor
      ) {
        tooltip.executor = this.getExecutor(actor);
      }
    } else if (!date) {
      tooltip.executors = this.getExecutorsData(action);
    } else if (status === 'active') {
      tooltip.executors = this.getExecutorsData(action);
    } else if (actor) {
      tooltip.executor = this.getExecutor(actor);
    }

    if (status === 'inactive' && isOptional) {
      tooltip.description =
        'Заявка автоматически перейдет на опциональный этап, если исходный этап не будет выполнен вовремя. При своевременном выполнении исходного этапа опциональный этап не будет задействован';
    }

    return tooltip;
  }

  public getExecutor({ role, fio }: EventNodeFlowActor): Executor {
    const executor: Executor = { position: role ?? '', name: fio ?? '' };

    return executor;
  }

  public getExecutorsData(
    action: NodeAction,
  ): { position: string; names: string[] }[] {
    const executorsObj = action.responsible.reduce((acc, item) => {
      acc[item.role] = {
        names: acc[item.role]?.names
          ? [...acc[item.role]?.names, item.name]
          : [item.name],
        position: item.role,
      };

      return acc;
    }, {} as Record<string, { names: string[]; position: string }>);

    const executorsData = Object.values(executorsObj);

    return executorsData;
  }

  public dateFormat(date?: string, dateOnly = false): string {
    return date
      ? format(parseISO(date), dateOnly ? 'dd.MM.yyyy' : 'dd.MM.yyyy HH:mm')
      : '';
  }
}
