import { createSelector } from '@reduxjs/toolkit';

import { Inject } from '@vk-hr-tek/core/ioc';

import { RootState } from '@app/store';

export const selectAuthInstance = createSelector(
  [(state: RootState) => state.auth.authInstances.instances],
  (instances) =>
    instances?.filter((instance) => instance.auth_type !== 'openid_jwt')[0] ||
    {},
);

export const selectAuthInstances = createSelector(
  [(state: RootState) => state.auth.authInstances.instances],
  (instances) => instances || [],
);

export const selectAuthInstancesWithoutOpenId = createSelector(
  [(state: RootState) => state.auth.authInstances.instances],
  (instances) =>
    instances.filter((instance) => instance.auth_type !== 'openid_jwt') || [],
);

export const selectAuthInstanceById = createSelector(
  [
    (state: RootState) => state.auth.authInstances.instances,
    (_: RootState, inject: Inject, id?: string) => id,
  ],
  (items, authInstanceId) => items?.find(({ id }) => authInstanceId === id),
);

export const selectAuthInstanceStatus = (state: RootState) =>
  state.auth.authInstances.status;

export const selectAuthInstanceError = (state: RootState) =>
  state.auth.authInstances.error;

export const selectNotification = (state: RootState) => state.auth.notification;

export const selectStage = (state: RootState) => state.auth.stage;

export const selectPhone = (state: RootState) => state.auth.values.phone;

export const selectSnils = (state: RootState) => state.auth.values.snils;

export const selectInviteCode = (state: RootState) => state.auth.inviteCode;

export const selectAuthCode = (state: RootState) => state.auth.authCode;

export const selectOpenIdToken = (state: RootState) => state.auth.openIdToken;

export const selectPassCode = (state: RootState) => state.auth.passCode;

export const selectStatus = (state: RootState) => state.auth.status;

export const selectPassword = (state: RootState) => state.auth.values.password;
