import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Calendar } from '@vk-hr-tek/core/calendar';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import {
  FiltersResponse,
  Filter,
  FilterService,
  FilterType,
} from '@vk-hr-tek/core/filter';
import { SendBatchEventsDto } from '@vk-hr-tek/app/events/dto';

import {
  EventListResponse,
  CreateEventCompanyOptionsList as OptionsCompanyResponse,
  GetEventFileHashesResponse,
  BatchEventOperationResponse,
  BatchEventAcceptResponse,
  EventBatchListResponse,
} from '@app/gen/events';

import {
  GetEventsDto,
  UkepBatchSignDto,
  GetHashesDto,
  GetEventBatchOptionsDto,
  GetFilterOptionsByUrlDto,
  GetEventFiltersDto,
  GetCreateOptionsCompanyDto,
} from '../dto';
import { AbsencesFilterType } from '../types';

@injectable()
export class EventsListService {
  constructor(
    private validator: ValidationService,
    private calendar: Calendar,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private filterService: FilterService,
  ) {}

  async get(getEventsDto: GetEventsDto, availableFilters: Filter[]) {
    const eventsDto = plainToClass(
      this.filterService.buildDto(GetEventsDto, availableFilters),
      getEventsDto,
    );

    if (!eventsDto.filters?.preset_id) {
      await this.validator.validateOrReject(eventsDto);
    }

    const filters: AbsencesFilterType = {};

    for (const filter in eventsDto.filters) {
      const filterValue = eventsDto.filters[filter];
      this.filterService.sanitizeDates(filterValue);
      filters[filter] = filterValue;
    }

    if (eventsDto.tags) filters.tags = eventsDto.tags;
    if (eventsDto.companyId) {
      if (filters.company) {
        const filteredCompany = (filters.company as string[]).filter(
          (id) => id !== eventsDto.companyId,
        );
        filters.company = [...filteredCompany, eventsDto.companyId];
      } else {
        filters.company = [eventsDto.companyId];
      }
    }

    const {
      limit = 50,
      offset = 0,
      sortBy = 'created_at',
      sortOrder = 'desc',
      sortByIds,
    } = eventsDto;

    const checkForFilterByEventIds = !!sortByIds?.length
      ? { event_ids: sortByIds }
      : {};

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event/list',
        {
          pagination: {
            limit,
            offset,
            sort_by: sortBy,
            sort_order: sortOrder,
          },
          filters: filters
            ? { ...filters, event_ids: sortByIds }
            : checkForFilterByEventIds,
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      EventListResponse,
    );

    return {
      data: result.events,
      total: result.total_count,
      totalMy: result.total_count_my,
      totalMyTeam: result.total_count_my_group,
      finished: result.finished_count || 0,
    };
  }

  async getFilters(getFiltersDto?: GetEventFiltersDto) {
    if (getFiltersDto) {
      await this.validator.validateOrReject(getFiltersDto, GetEventFiltersDto);
    }

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/available_filters',
        {
          ...(getFiltersDto?.companyId
            ? { company_id: getFiltersDto.companyId }
            : {}),
          ...(getFiltersDto?.tags ? { tags: getFiltersDto.tags } : {}),
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      FiltersResponse,
    );

    return result.filters;
  }

  async getAbsenceFilters(getFiltersDto?: GetEventFiltersDto) {
    if (getFiltersDto) {
      await this.validator.validateOrReject(getFiltersDto, GetEventFiltersDto);
    }

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/absence_available_filters',
        {
          ...(getFiltersDto?.companyId
            ? { company_id: getFiltersDto.companyId }
            : {}),
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      FiltersResponse,
    );

    return result.filters;
  }

  async sendBatchAccept(sendBatchAcceptDto: SendBatchEventsDto) {
    await this.validator.validateOrReject(
      sendBatchAcceptDto,
      SendBatchEventsDto,
    );

    const result = await this.unmarshaller.unmarshall(
      this.http.post(
        '/event/batch/accept',
        {
          events: sendBatchAcceptDto.events,
        },
        {
          withSide: true,
          reloadIfOldVersion: true,
        },
      ),
      BatchEventAcceptResponse,
    );

    return {
      successes: result.successes || [],
      errors: result.errors || [],
    };
  }

  async getFilterOptionsByUrl(
    getFilterOptionsByUrlDto: GetFilterOptionsByUrlDto,
  ) {
    const { url, ...filter } = getFilterOptionsByUrlDto;

    const result = await this.http.get(url, filter, { withSide: true });

    return result;
  }

  async getExistingBatchEvents(getBatchEmployees: GetEventBatchOptionsDto) {
    await this.validator.validateOrReject(
      getBatchEmployees,
      GetEventBatchOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/batch',
        { company_id: getBatchEmployees.companyId },
        {
          withSide: true,
        },
      ),
      EventBatchListResponse,
    );
  }

  async getHashes(getHashesDto: GetHashesDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.post(`/event/batch/document/hash`, getHashesDto, {
        withSide: true,
      }),
      GetEventFileHashesResponse,
    );
    return result as GetEventFileHashesResponse;
  }

  async ukepBatchSign(ukepBatchSignDto: UkepBatchSignDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.post('/event/batch/ukep_sign', ukepBatchSignDto, {
        withSide: true,
      }),
      BatchEventOperationResponse,
    );
    return result;
  }

  async getDocumentCount(
    getEventsDto: GetEventsDto,
    availableFilters: Filter[],
  ) {
    const eventsDto = plainToClass(
      this.filterService.buildDto(GetEventsDto, availableFilters),
      getEventsDto,
    );

    await this.validator.validateOrReject(eventsDto);

    const filters: FilterType = {};

    for (const filter in eventsDto.filters) {
      const filterValue = eventsDto.filters[filter];
      this.filterService.sanitizeDates(filterValue);
      filters[filter] = filterValue;
    }

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event/document_count',
        {
          ...(filters ? { filters } : {}),
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      EventListResponse,
    );

    return {
      totalCount: result.total_count,
    };
  }

  async getCreateOptionsCompany(
    getCreateOptionsCompanyDto: GetCreateOptionsCompanyDto,
  ) {
    const { withEventTypes, eventTypeGroup } = getCreateOptionsCompanyDto;
    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/companies',
        {
          with_event_types: withEventTypes,
          ...(eventTypeGroup ? { event_type_group: eventTypeGroup } : {}),
        },
        {
          withSide: true,
        },
      ),
      OptionsCompanyResponse,
    );
  }
}
