import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core';

interface LinearFlowDotGroupProps {
  variant: 'finished' | 'canceled' | 'active' | 'inactive' | 'skipped';
}

export const LinearFlowDotGroup = ({ variant }: LinearFlowDotGroupProps) => {
  const theme = useTheme();

  const circleFillPallete = {
    finished: theme.tokens.colors.accent.text.success,
    canceled: theme.tokens.colors.accent.text.error,
    active: theme.tokens.colors.accent.text.blue,
    inactive: theme.tokens.colors.stroke.secondary,
    skipped: theme.tokens.colors.accent.text.warning,
  };

  const innerFillPallete = {
    finished: theme.tokens.colors.accent.bg.success,
    canceled: theme.tokens.colors.accent.bg.error,
    active: theme.tokens.colors.accent.bg.blue,
    inactive: theme.tokens.colors.bg.greyscale.primary,
    skipped: theme.tokens.colors.accent.bg.warning,
  };

  return (
    <SvgIcon width="32" height="16" viewBox="0 0 32 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 0C21.7909 0 19.7909 0.89543 18.3431 2.34315C16.8954 3.79086 16 5.79086 16 8C16 10.2091 16.8954 12.2091 18.3431 13.6569C19.7909 15.1046 21.7909 16 24 16C26.2091 16 28.2091 15.1046 29.6569 13.6569C31.1046 12.2091 32 10.2091 32 8C32 5.79086 31.1046 3.79086 29.6569 2.34315C28.2091 0.89543 26.2091 0 24 0Z"
        fill={circleFillPallete[variant]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0013 2.6665C22.5285 2.6665 21.1952 3.26346 20.2301 4.2286C19.2649 5.19374 18.668 6.52708 18.668 7.99984C18.668 9.4726 19.2649 10.8059 20.2301 11.7711C21.1952 12.7362 22.5285 13.3332 24.0013 13.3332C25.4741 13.3332 26.8074 12.7362 27.7725 11.7711C28.7377 10.8059 29.3346 9.4726 29.3346 7.99984C29.3346 6.52708 28.7377 5.19374 27.7725 4.2286C26.8074 3.26346 25.4741 2.6665 24.0013 2.6665Z"
        fill={innerFillPallete[variant]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C13.7909 0 11.7909 0.89543 10.3431 2.34315C8.89543 3.79086 8 5.79086 8 8C8 10.2091 8.89543 12.2091 10.3431 13.6569C11.7909 15.1046 13.7909 16 16 16C18.2091 16 20.2091 15.1046 21.6569 13.6569C23.1046 12.2091 24 10.2091 24 8C24 5.79086 23.1046 3.79086 21.6569 2.34315C20.2091 0.89543 18.2091 0 16 0Z"
        fill={circleFillPallete[variant]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0013 2.6665C14.5285 2.6665 13.1952 3.26346 12.2301 4.2286C11.2649 5.19374 10.668 6.52708 10.668 7.99984C10.668 9.4726 11.2649 10.8059 12.2301 11.7711C13.1952 12.7362 14.5285 13.3332 16.0013 13.3332C17.4741 13.3332 18.8074 12.7362 19.7725 11.7711C20.7377 10.8059 21.3346 9.4726 21.3346 7.99984C21.3346 6.52708 20.7377 5.19374 19.7725 4.2286C18.8074 3.26346 17.4741 2.6665 16.0013 2.6665Z"
        fill={innerFillPallete[variant]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C5.79086 0 3.79086 0.89543 2.34315 2.34315C0.89543 3.79086 0 5.79086 0 8C0 10.2091 0.89543 12.2091 2.34315 13.6569C3.79086 15.1046 5.79086 16 8 16C10.2091 16 12.2091 15.1046 13.6569 13.6569C15.1046 12.2091 16 10.2091 16 8C16 5.79086 15.1046 3.79086 13.6569 2.34315C12.2091 0.89543 10.2091 0 8 0Z"
        fill={circleFillPallete[variant]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0013 2.6665C6.52854 2.6665 5.19521 3.26346 4.23007 4.2286C3.26492 5.19374 2.66797 6.52708 2.66797 7.99984C2.66797 9.4726 3.26492 10.8059 4.23007 11.7711C5.19521 12.7362 6.52854 13.3332 8.0013 13.3332C9.47406 13.3332 10.8074 12.7362 11.7725 11.7711C12.7377 10.8059 13.3346 9.4726 13.3346 7.99984C13.3346 6.52708 12.7377 5.19374 11.7725 4.2286C10.8074 3.26346 9.47406 2.6665 8.0013 2.6665Z"
        fill={innerFillPallete[variant]}
      />
    </SvgIcon>
  );
};
