import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const StudyIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
      viewBox="0 0 16 14"
    >
      <path d="M8.48087 0.723272L14.6809 4.12308C15.0123 4.30477 15.1999 4.64672 15.2003 4.99969L15.2001 4.99991V9.19991C15.2001 9.53128 14.9314 9.79991 14.6001 9.79991C14.2687 9.79991 14.0001 9.53128 14.0001 9.19991L13.9998 6.2497L12.7998 6.9073L12.8001 9.39991C12.8001 11.6403 10.6264 13.3999 8.00006 13.3999C5.37371 13.3999 3.20006 11.6403 3.20006 9.39991L3.1998 6.9073L1.31918 5.87673C0.834926 5.61119 0.657623 5.00336 0.923166 4.5191C1.01476 4.35207 1.15215 4.21467 1.31918 4.12308L7.51925 0.723272C7.81875 0.55904 8.18136 0.55904 8.48087 0.723272ZM11.5998 7.5657L8.48087 9.27654C8.18136 9.44077 7.81875 9.44077 7.51925 9.27654L4.3998 7.5657L4.40006 9.39991C4.40006 10.9151 5.98722 12.1999 8.00006 12.1999C10.0129 12.1999 11.6001 10.9151 11.6001 9.39991L11.5998 7.5657ZM8.00006 1.82819L2.21596 4.99991L8.00006 8.17162L13.7842 4.99991L8.00006 1.82819Z" />
    </SvgIcon>
  );
};
