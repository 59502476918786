import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { PrintService } from '@vk-hr-tek/core/print';
import { showError } from '@vk-hr-tek/core/notifications';
import type { FlowTreeNode, LinearFlowNode } from '@vk-hr-tek/core/flow';

import { EventListItem as EventListEntity } from '@app/gen/events';

import { ThunkExtra } from '../../../app/store';
import { EventsFlowService } from '../../services';
import { PrintFlowDto } from '../../dto';
import { EventsState } from '../events.state';
import { EventsFlowMapper, EventsLinearFlowMapper } from '../../mappers';

export const setStartNodeId = createAction<string>('event/flow/setStartNode');

export const getEventFlow = createAsyncThunk<
  [FlowTreeNode | null, LinearFlowNode[]],
  string,
  ThunkExtra
>(
  'event/getEventFlow',
  async (eventId, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const service = inject(EventsFlowService);

      const res = await service.getEventFlow({
        id: eventId,
      });

      if (!res?.nodes?.length) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      const mapperFlow = inject(EventsFlowMapper);
      const mapperLinearFlow = inject(EventsLinearFlowMapper);

      mapperFlow.init(res.nodes, res.in_paper);
      mapperLinearFlow.init(res.nodes);

      dispatch(setStartNodeId(res.start_node_id));

      return [
        mapperFlow.getFlowTree(res.start_node_id),
        mapperLinearFlow.getLinearFlowTree(res.start_node_id),
      ];
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const printFlowFile = createAsyncThunk<void, PrintFlowDto, ThunkExtra>(
  'event/printEventFlowFile',
  async (printFlowDto, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const service = inject(EventsFlowService);
      const file = await service.getPdfFile(printFlowDto);

      await inject(PrintService).print([file]);
    } catch (err) {
      dispatch(showError('При печати документов произошла ошибка'));
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const flowReducers = (
  builder: ActionReducerMapBuilder<EntityState<EventListEntity> & EventsState>,
) => {
  builder.addCase(getEventFlow.pending, (state) => {
    state.flow.status = 'loading';
    state.flow.tree = null;
    state.flow.error = null;
  });
  builder.addCase(getEventFlow.fulfilled, (state, action) => {
    state.flow.status = 'complete';
    state.flow.tree = action.payload[0];
    state.flow.linearTree = action.payload[1];
  });
  builder.addCase(getEventFlow.rejected, (state, { payload, error }) => {
    state.flow.status = 'failed';
    state.flow.tree = null;
    state.flow.error =
      /* istanbul ignore next */
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
  builder.addCase(printFlowFile.pending, (state) => {
    state.printFlow.status = 'loading';
    state.printFlow.error = null;
  });
  builder.addCase(printFlowFile.fulfilled, (state) => {
    state.printFlow.status = 'complete';
    state.printFlow.error = null;
  });
  builder.addCase(printFlowFile.rejected, (state, { payload, error }) => {
    state.printFlow.status = 'failed';
    state.printFlow.error =
      /* istanbul ignore next */
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });

  builder.addCase(setStartNodeId, (state, action) => {
    state.flow.startNodeId = action.payload;
  });
};
