import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { List, ListItem } from '@vk-hr-tek/ui/List';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { FormatService } from '@vk-hr-tek/core/format';
import { useInject } from '@vk-hr-tek/core/ioc';

import { DateRange } from '@app/gen/users';

interface SubstitutesContentTooltipProps {
  name: string;
  dates: DateRange[];
}

export const SubstitutesContentTooltip = ({
  name,
  dates,
}: SubstitutesContentTooltipProps) => {
  const formatService = useInject(FormatService);

  return (
    <Box display="flex" flexDirection="column" gap="8">
      <Typography variant="body2" color="bg.light.primary">
        {name}
      </Typography>

      {!dates.length && (
        <Typography variant="caption" color="text.dark.tertirary">
          Постоянный заместитель
        </Typography>
      )}

      {dates.length && (
        <Box display="flex" flexDirection="column" gap="8">
          <Typography variant="caption" color="text.dark.tertirary">
            Периоды замещения:
          </Typography>
          <List listStyleType="disc" pl={1.6}>
            {dates.map(({ from_date, to_date }) => (
              <ListItem key={from_date}>
                <Typography variant="caption" color="bg.light.primary">
                  {formatService.toDateRangeWithDaysBetween(from_date, to_date)}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};
