import React, { ReactNode } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper } from '@material-ui/core';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

interface PageLoadingTextProps {
  text: ReactNode;
  hideLoader?: boolean;
}

export const PageLoadingText = ({
  text,
  hideLoader = false,
}: PageLoadingTextProps) => (
  <Box
    display="flex"
    flexGrow={1}
    justifyContent="center"
    alignItems="center"
    pt="144"
  >
    <Paper>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p="24"
        maxWidth={470}
        gap="16"
      >
        {!hideLoader && (
          <Box>
            <CircularProgress size={20} />
          </Box>
        )}
        {text && (
          <Typography
            variant="body3"
            color="text.light.tertirary"
            align="center"
          >
            {text}
          </Typography>
        )}
      </Box>
    </Paper>
  </Box>
);
