import React from 'react';

import {
  VacationIcon,
  MaternityLeaveIcon,
  StudyIcon,
  BusinessTripIcon,
  MedicalIcon,
  AbsenceIcon,
} from '.';

interface AbsenceTypeIconProps {
  variant?:
    | 'vacation'
    | 'maternity_leave'
    | 'student_leave'
    | 'business_trip'
    | 'sick_leave';
}

export const AbsenceTypeIcon = ({ variant }: AbsenceTypeIconProps) => {
  switch (variant) {
    case 'vacation':
      return <VacationIcon />;
    case 'maternity_leave':
      return <MaternityLeaveIcon />;
    case 'student_leave':
      return <StudyIcon />;
    case 'business_trip':
      return <BusinessTripIcon />;
    case 'sick_leave':
      return <MedicalIcon />;
    default:
      return <AbsenceIcon />;
  }
};
