import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const AbsenceIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
      viewBox="0 0 14 14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.142 4.18811C13.142 6.02976 11.6491 7.52271 9.80745 7.52271C7.9658 7.52271 6.47285 6.02976 6.47285 4.18811C6.47285 2.34646 7.9658 0.853516 9.80745 0.853516C11.6491 0.853516 13.142 2.34646 13.142 4.18811ZM9.80744 2.1853C10.1388 2.18529 10.4074 2.45392 10.4074 2.78529L10.4075 3.77979L11.4835 4.1843C11.7937 4.30091 11.9506 4.64688 11.834 4.95706C11.7174 5.26724 11.3714 5.42416 11.0613 5.30756L9.59633 4.75687C9.36239 4.66892 9.20746 4.44517 9.20746 4.19524L9.20745 2.7853C9.20745 2.45393 9.47607 2.1853 9.80744 2.1853Z"
      />
      <path d="M3.93418 1.60156C3.93418 1.27019 3.66555 1.00156 3.33418 1.00156C3.00281 1.00156 2.73418 1.27019 2.73418 1.60156V2.02988C2.19411 2.08341 1.60317 2.23759 1.10219 2.64074C0.393473 3.21106 0.000976562 4.16281 0.000976562 5.58104L0.00114673 10.7843C0.00114948 11.5231 0.153609 12.3346 0.666039 12.9706C1.19803 13.6308 2.03337 13.9991 3.17461 13.9991H8.72852C9.66827 13.9991 10.5026 13.7487 11.1051 13.1808C11.7131 12.6077 12.0008 11.7908 12.0008 10.8333V8.73692C12.0008 8.40555 11.7322 8.13692 11.4008 8.13692C11.0694 8.13692 10.8008 8.40555 10.8008 8.73692V10.8333C10.8008 11.5505 10.5908 12.0165 10.282 12.3076C9.96754 12.604 9.46576 12.7991 8.72852 12.7991H3.17461C2.30347 12.7991 1.85208 12.5299 1.60043 12.2176C1.32923 11.8811 1.20115 11.3851 1.20115 10.7843L1.20103 7.20312H5.28565C5.61702 7.20312 5.88565 6.9345 5.88565 6.60313C5.88565 6.27175 5.61702 6.00313 5.28565 6.00313H1.20099L1.20098 5.58104C1.20098 4.37399 1.52927 3.83735 1.85451 3.57562C2.08812 3.38764 2.38635 3.28544 2.73418 3.23808V3.72994C2.73418 4.06131 3.00281 4.32994 3.33418 4.32994C3.66555 4.32994 3.93418 4.06131 3.93418 3.72994V3.2043H5.18007C5.51144 3.2043 5.78007 2.93567 5.78007 2.6043C5.78007 2.27293 5.51144 2.0043 5.18007 2.0043H3.93418V1.60156Z" />
    </SvgIcon>
  );
};
