import {
  IsString,
  IsPhoneNumber,
  IsNotEmpty,
  Length,
  Matches,
} from 'class-validator';

import { IsSnils } from '@vk-hr-tek/core/validation';

import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../../auth.constants';

export class ChangePasswordSendCodeDto {
  @IsPhoneNumber('RU', {
    message: 'Неверный номер телефона',
  })
  @IsString({
    message: 'Вы забыли ввести номер телефона',
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести номер телефона',
  })
  phone: string;

  @Length(PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH, {
    message: 'Должен содержать не менее 8 символов',
    groups: ['form'],
  })
  @Matches(/^[A-Za-z0-9!#{}@()%^\[\]~$\-_]+$/, {
    message: 'Должен содержать только буквы, цифры или символы !#{}@$()%^[]~-_',
    groups: ['form'],
  })
  @Matches(/[A-Z]/, {
    message: 'Должен содержать не менее 1 заглавной буквы',
    groups: ['form'],
  })
  @Matches(/[a-z]/, {
    message: 'Должен содержать не менее 1 строчной буквы',
    groups: ['form'],
  })
  @Matches(/[0-9\^\[\]~!#{}\/@\$()`%]/, {
    message: 'Должен содержать не менее 1 цифры либо 1 символа',
    groups: ['form'],
  })
  @IsString({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  password: string;

  @IsSnils({
    message: 'Неверный СНИЛС',
  })
  @IsString({
    message: 'Вы забыли ввести СНИЛС',
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести СНИЛС',
  })
  snils: string;
}
