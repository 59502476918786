import React from 'react';

import { TooltipProps as MUITooltipProps } from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';

import { Tooltip } from '../Tooltip';
import { Box } from '../Box';
import { Typography } from '../Typography';

/**
 * @typedef {Object} InputTooltipProps
 * @property {string} [title] - Заголовок всплывающей подсказки.
 * @property {string} description - Основной текст описания всплывающей подсказки.
 * @property {string} [subText] - Дополнительный текст подсказки (необязательный).
 * @property {MUITooltipProps['placement']} [placement='right'] - Позиционирование всплывающей подсказки относительно целевого элемента.
 */

interface InputTooltipProps {
  title?: string;
  description: string;
  subText?: string;
  placement?: MUITooltipProps['placement'];
}

/**
 * Компонент для отображения всплывающей подсказки (tooltip) рядом с полем ввода, обычно используется для предоставления дополнительной информации или помощи.
 *
 * @param {Object} props - Свойства, которые можно передать в компонент InputTooltip.
 * @param {string} [props.title] - Заголовок всплывающей подсказки.
 * @param {string} props.description - Основной текст описания всплывающей подсказки.
 * @param {string} [props.subText] - Дополнительный текст подсказки (необязательный).
 * @param {MUITooltipProps['placement']} [props.placement='right'] - Позиционирование всплывающей подсказки относительно целевого элемента.
 * @returns {JSX.Element} JSX-элемент, представляющий всплывающую подсказку для поля ввода.
 */

export const InputTooltip: React.FC<InputTooltipProps> = ({
  title,
  description,
  subText,
  placement = 'right',
}) => (
  <Box
    height={20}
    ml="4"
    className="aqa_input_tooltip"
    testId="input-tooltip-test-id"
  >
    <Tooltip
      placement={placement}
      title={
        <Box>
          {title && (
            <Box mb="8">
              <Typography variant="subtitle1" color="text.dark.primary">
                {title}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant="caption" color="text.dark.primary">
              {description}
            </Typography>
          </Box>

          {subText && (
            <Box mt="8">
              <Typography variant="caption" color="text.light.tertirary">
                {subText}
              </Typography>
            </Box>
          )}
        </Box>
      }
    >
      <HelpIcon
        color="disabled"
        fontSize="small"
        data-testid="help-icon-test-id"
      />
    </Tooltip>
  </Box>
);
