import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  checkboxInput: {
    alignItems: 'center',
    cursor: 'default',
  },

  fullWidth: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
