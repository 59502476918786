import React from 'react';

import { ListAlt as ListAltIcon } from '@material-ui/icons';

import { Box } from '@vk-hr-tek/ui/Box';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Link } from '@vk-hr-tek/ui/Link';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { FormatService } from '@vk-hr-tek/core/format';
import { useInject } from '@vk-hr-tek/core/ioc';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';
import { CalendarIcon, CloseIcon } from '@vk-hr-tek/ui/icons';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

import { DateRange } from '@app/gen/users';

import { SubstitutesContentTooltip } from './SubstitutesContentTooltip';

interface SubstituteContentProps {
  name: string;
  personalNumber: string;
  managableUnitEmployeeId: string;
  managableUnitCompanyName: string;
  id: string;
  eventTypeId: string;
  dates?: DateRange[];
  handleOpen: (
    selectEmployeeId: string,
    selectEmployeeCompanyName: string,
    selectedUserId: string,
    selectedUserName: string,
    selectedEventTypeId: string,
  ) => () => void;
}

export const SubstituteContent = ({
  name,
  personalNumber,
  managableUnitEmployeeId,
  managableUnitCompanyName,
  id,
  eventTypeId,
  dates,
  handleOpen,
}: SubstituteContentProps) => {
  const isDesktop = useIsDesktop();
  const formatService = useInject(FormatService);

  const datesIsNotEmpty = !!dates?.length;

  return (
    <Tooltip
      title={<SubstitutesContentTooltip name={name} dates={dates || []} />}
      placement="bottom"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        width="100%"
      >
        <Box display="flex" flexDirection="column" gap="4">
          <Box display="flex" alignItems="center" gap="8">
            <EllipsisText rows={1}>
              <Typography variant="body3">{name}</Typography>
            </EllipsisText>

            {datesIsNotEmpty && (
              <ColoredChip label="Временный" color="orange" size="extraSmall" />
            )}
          </Box>
          <Box display="flex" alignItems="center" gap="8">
            <Box
              display="flex"
              alignItems="center"
              color="text.light.tertirary"
              fontSize={16}
            >
              <ListAltIcon fontSize="inherit" />
            </Box>
            <EllipsisText rows={1} wordBreak="break-all">
              <Typography variant="caption" color="text.light.tertirary">
                {personalNumber}
              </Typography>
            </EllipsisText>

            {datesIsNotEmpty && (
              <Box display="flex" gap="4">
                <Box
                  display="flex"
                  alignItems="center"
                  color="text.light.tertirary"
                  fontSize={16}
                >
                  <CalendarIcon color="inherit" size="extraSmall" />
                </Box>

                <EllipsisText rows={1} wordBreak="break-all">
                  <Typography color="text.light.tertirary" variant="body3">
                    {formatService.toDateRangeWithDaysBetween(
                      dates[0].from_date,
                      dates[0].to_date,
                    )}
                    {dates.length > 1 && ` +${dates.length - 1}`}
                  </Typography>
                </EllipsisText>
              </Box>
            )}
          </Box>
        </Box>
        {isDesktop && (
          <Link
            size="small"
            onClick={handleOpen(
              managableUnitEmployeeId,
              managableUnitCompanyName,
              id,
              name,
              eventTypeId,
            )}
            startIcon={<CloseIcon color="secondary" />}
            stroke={false}
          />
        )}
      </Box>
    </Tooltip>
  );
};
