import React from 'react';

import { useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { GosUslugiIcon } from '@vk-hr-tek/ui/icons';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Button } from '@vk-hr-tek/ui/Button';

import { useSelector, useIsWidget } from '@app/hooks';

import { DividerWithText } from '../../ui/DivivderWithText';
import { selectAuthInstancesWithoutOpenId } from '../../slice';
import { Button as AuthButton } from '../../ui/Button';
import { useGetAuthInstance } from '../../hooks';
import { AuthConfigService } from '../../services';

const COUNT_OF_AUTH_INSTANCE_TO_SHOW_LOGIN_BUTTON = 2;

export const AdditionalAuthButton = () => {
  const config = useInject(AuthConfigService);
  const authInstances = useSelector(selectAuthInstancesWithoutOpenId);
  const history = useHistory();
  const isWidget = useIsWidget();
  const authInstance = useGetAuthInstance();
  const theme = useTheme();

  if (
    authInstances.length < COUNT_OF_AUTH_INSTANCE_TO_SHOW_LOGIN_BUTTON ||
    isWidget
  ) {
    return null;
  }

  const handleGoAuth = (url: string) => {
    history.push(url);
  };

  const secondAuthInstance = authInstances.find(
    (item) => item.id !== authInstance.id,
  );

  const renderSecondAuthInstanceButton = () => {
    if (!secondAuthInstance) return null;

    if (
      secondAuthInstance.auth_type === 'oauth2' ||
      secondAuthInstance.auth_type === 'esia'
    ) {
      return (
        <form
          action={`${config.getApiUrl()}/oauth2/auth_form/init`}
          method="POST"
        >
          <input type="hidden" value="false" name="phone_change" />
          <input type="hidden" value="esia" name="source" />
          <input
            type="hidden"
            value={secondAuthInstance.id}
            name="auth_instance_id"
          />
          {secondAuthInstance.auth_type === 'esia' ? (
            <AuthButton
              border={`2px solid ${theme.tokens.colors.stroke.primary}`}
              color="#ffffff"
            >
              <Typography variant="h6">
                <Box
                  display="flex"
                  gap="8"
                  alignItems="center"
                  justifyContent="center"
                >
                  <GosUslugiIcon />
                  Войти через Госуслуги
                </Box>
              </Typography>
            </AuthButton>
          ) : (
            <AuthButton
              color={
                secondAuthInstance.button_color &&
                `#${secondAuthInstance.button_color}`
              }
              fullWidth
            >
              {secondAuthInstance.button_text || 'Войти'}
            </AuthButton>
          )}
        </form>
      );
    } else {
      return (
        <AuthButton
          fullWidth
          color={
            secondAuthInstance.button_color &&
            `#${secondAuthInstance.button_color}`
          }
          onClick={() => handleGoAuth(`/auth/${secondAuthInstance.id}/login`)}
        >
          {secondAuthInstance.button_text || 'Войти'}
        </AuthButton>
      );
    }
  };

  return (
    <Box mt="32" mb="16" display="flex" flexDirection="column" width="100%">
      <Box display="flex" flexDirection="column" gap="32">
        <DividerWithText label="или" />
        {authInstances.length === COUNT_OF_AUTH_INSTANCE_TO_SHOW_LOGIN_BUTTON &&
        secondAuthInstance ? (
          renderSecondAuthInstanceButton()
        ) : (
          <Button
            onClick={() => handleGoAuth('/auth/auth-list')}
            fullWidth
            variant="tertiaryLight"
          >
            <Box display="flex" justifyContent="center">
              <Box display="flex" gap="8">
                <Typography color="text.light.primary" variant="h6">
                  Войти другим способом
                </Typography>
              </Box>
            </Box>
          </Button>
        )}
      </Box>
    </Box>
  );
};
