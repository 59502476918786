import React from 'react';

import classNames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core';

import { useStyles } from './Icon.styles';

interface LinearFlowDotProps {
  variant: 'finished' | 'canceled' | 'active' | 'inactive' | 'skipped';
  size?: 'normal' | 'default' | 'small' | 'extraSmall' | 'micro';
}

export const LinearFlowDot = ({
  variant,
  size = 'extraSmall',
}: LinearFlowDotProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const circleFillPallete = {
    finished: theme.tokens.colors.accent.text.success,
    canceled: theme.tokens.colors.accent.text.error,
    active: theme.tokens.colors.accent.text.blue,
    inactive: theme.tokens.colors.stroke.secondary,
    skipped: theme.tokens.colors.accent.text.warning,
  };

  const innerFillPallete = {
    finished: theme.tokens.colors.accent.bg.success,
    canceled: theme.tokens.colors.accent.bg.error,
    active: theme.tokens.colors.accent.bg.blue,
    inactive: theme.tokens.colors.bg.greyscale.primary,
    skipped: theme.tokens.colors.accent.bg.warning,
  };

  return (
    <SvgIcon
      className={classNames(classes[`icon_size_${size}` as const])}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C5.79086 0 3.79086 0.89543 2.34315 2.34315C0.89543 3.79086 0 5.79086 0 8C0 10.2091 0.89543 12.2091 2.34315 13.6569C3.79086 15.1046 5.79086 16 8 16C10.2091 16 12.2091 15.1046 13.6569 13.6569C15.1046 12.2091 16 10.2091 16 8C16 5.79086 15.1046 3.79086 13.6569 2.34315C12.2091 0.89543 10.2091 0 8 0Z"
        fill={circleFillPallete[variant]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0013 2.6665C6.52854 2.6665 5.19521 3.26346 4.23007 4.2286C3.26492 5.19374 2.66797 6.52708 2.66797 7.99984C2.66797 9.4726 3.26492 10.8059 4.23007 11.7711C5.19521 12.7362 6.52854 13.3332 8.0013 13.3332C9.47406 13.3332 10.8074 12.7362 11.7725 11.7711C12.7377 10.8059 13.3346 9.4726 13.3346 7.99984C13.3346 6.52708 12.7377 5.19374 11.7725 4.2286C10.8074 3.26346 9.47406 2.6665 8.0013 2.6665Z"
        fill={innerFillPallete[variant]}
      />
    </SvgIcon>
  );
};
