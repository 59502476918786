import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const MedicalIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
      viewBox="0 0 15 15"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85085 0.450195C1.43655 0.450195 0.290039 1.59671 0.290039 3.01101V5.54614C0.290039 7.50418 1.6936 9.13439 3.5495 9.48557V9.71101C3.5495 12.2254 5.58781 14.2637 8.1022 14.2637C10.6166 14.2637 12.6549 12.2254 12.6549 9.71101V7.25154C13.5001 6.94502 14.1039 6.13503 14.1039 5.184C14.1039 3.96972 13.1195 2.98535 11.9053 2.98535C10.691 2.98535 9.7066 3.96972 9.7066 5.184C9.7066 6.13477 10.3101 6.94458 11.1549 7.25129V9.71101C11.1549 11.397 9.78816 12.7637 8.1022 12.7637C6.41624 12.7637 5.0495 11.397 5.0495 9.71101V9.48557C6.9054 9.13439 8.30896 7.50418 8.30896 5.54614V3.011C8.30896 1.59671 7.16244 0.450195 5.74815 0.450195C5.33393 0.450195 4.99815 0.785982 4.99815 1.2002C4.99815 1.61441 5.33393 1.9502 5.74815 1.9502C6.33402 1.9502 6.80896 2.42514 6.80896 3.011V5.54614C6.80896 6.93208 5.68544 8.0556 4.2995 8.0556C2.91356 8.0556 1.79004 6.93208 1.79004 5.54614V3.01101C1.79004 2.42514 2.26498 1.9502 2.85085 1.9502C3.26506 1.9502 3.60085 1.61441 3.60085 1.2002C3.60085 0.785982 3.26506 0.450195 2.85085 0.450195ZM11.2066 5.184C11.2066 4.79815 11.5194 4.48535 11.9053 4.48535C12.2911 4.48535 12.6039 4.79815 12.6039 5.184C12.6039 5.56985 12.2911 5.88265 11.9053 5.88265C11.5194 5.88265 11.2066 5.56985 11.2066 5.184Z"
      />
    </SvgIcon>
  );
};
