import React, { ReactNode } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { useIsDesktop } from '../../hooks';
import { Box } from '../../Box';
import { Typography } from '../../Typography';
import { Tooltip } from '../../Tooltip';
import { Label } from '../common';

import { useStyles } from './RadioInput.styles';

/**
 * Интерфейс, определяющий свойства компонента RadioInput.
 * @typedef {Object} RadioInputProps
 * @property {Array<{ label: string; value: string | number }>} items - Массив элементов, каждый из которых содержит метку и значение.
 * @property {string} [label] - Метка для группы радио-ввода.
 * @property {'start' | 'end'} [labelPlacement='end'] - Размещение метки относительно радиокнопки.
 * @property {string | number | undefined} value - Текущее выбранное значение.
 * @property {(value: string | number) => void} onChange - Функция обратного вызова, которая вызывается при изменении выбранного значения.
 * @property {boolean} [required=false] - Определяет, является ли группа радио-ввода обязательной.
 * @property {boolean} [row=false] - Определяет, должны ли радиокнопки отображаться в ряд.
 * @property {ReactNode | null} [tooltip=null] - Необязательная всплывающая подсказка для отображения с меткой.
 * @property {boolean} [disabled=false] - Определяет, отключена ли группа радио-ввода.
 * @property {string} [testId] - Необязательный идентификатор для автоматизированного тестирования.
 */

interface RadioInputProps {
  items: {
    label: string;
    value: string | number;
    disabled?: boolean;
    tooltip?: string;
  }[];
  label?: string;
  labelPlacement?: 'start' | 'end';
  value: string | number | undefined;
  onChange: (value: string | number) => void;
  required?: boolean;
  row?: boolean;
  tooltip?: ReactNode | null;
  disabled?: boolean;
  testId?: string;
}

/**
 * Компонент RadioInput представляет собой настраиваемый радио-ввод с необязательными метками и всплывающими подсказками.
 * Он поддерживает как десктопные, так и мобильные макеты и позволяет настраивать размещение меток.
 *
 * @component
 * @param {RadioInputProps} props - Свойства для компонента.
 * @param {Array<{ label: string; value: string | number; disabled?: boolean; tooltip?: string }>} props.items - Массив элементов, каждый из которых содержит метку и значение.
 * @param {string} [props.label] - Метка для группы радио-ввода.
 * @param {'start' | 'end'} [props.labelPlacement='end'] - Размещение метки относительно радиокнопки.
 * @param {string | number | undefined} props.value - Текущее выбранное значение.
 * @param {(value: string | number) => void} props.onChange - Функция обратного вызова, которая вызывается при изменении выбранного значения.
 * @param {boolean} [props.required=false] - Определяет, является ли группа радио-ввода обязательной.
 * @param {boolean} [props.row=false] - Определяет, должны ли радиокнопки отображаться в ряд.
 * @param {ReactNode | null} [props.tooltip=null] - Необязательная всплывающая подсказка для отображения с меткой.
 * @param {boolean} [props.disabled=false] - Определяет, отключена ли группа радио-ввода.
 * @param {string} [props.testId] - Необязательный идентификатор для автоматизированного тестирования.
 * @returns {React.ReactElement} Отрендеренный компонент RadioInput.
 */

export const RadioInput = ({
  items,
  label,
  labelPlacement = 'end',
  value,
  onChange,
  required = false,
  row = false,
  tooltip = null,
  disabled = false,
  testId,
  ...rest
}: RadioInputProps) => {
  const isDesktop = useIsDesktop();
  const classes = useStyles();

  return (
    <FormControl
      className="aqa_radion_input"
      component="fieldset"
      disabled={disabled}
    >
      {label && <Label label={label} required={required} tooltip={tooltip} />}

      <RadioGroup
        {...rest}
        data-testid={testId}
        row={isDesktop || row}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        classes={{ root: classes.root }}
        className="aqa_radioinput_block"
      >
        {items.map((item) => {
          if (item.tooltip && item.disabled) {
            return (
              <Tooltip title={item.tooltip} placement="bottom">
                <Box key={item.value}>
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    disabled={item.disabled}
                    classes={{
                      root: classes.itemRoot,
                      label: classes.label,
                      disabled: classes.disabled,
                    }}
                    control={
                      <Radio
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        classes={{
                          root: classes.inputRoot,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label={
                      <Box
                        mr={labelPlacement === 'start' ? '8' : '0'}
                        ml={labelPlacement === 'end' ? '8' : '0'}
                      >
                        <Typography
                          color="text.light.secondary"
                          variant="body3"
                        >
                          {item.label}
                        </Typography>
                      </Box>
                    }
                    labelPlacement={labelPlacement}
                  />
                </Box>
              </Tooltip>
            );
          } else {
            return (
              <Box key={item.value}>
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  disabled={item.disabled}
                  classes={{
                    root: classes.itemRoot,
                    label: classes.label,
                    disabled: classes.disabled,
                  }}
                  control={
                    <Radio
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      classes={{
                        root: classes.inputRoot,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={
                    <Box
                      mr={labelPlacement === 'start' ? '8' : '0'}
                      ml={labelPlacement === 'end' ? '8' : '0'}
                    >
                      <Typography color="text.light.secondary" variant="body3">
                        {item.label}
                      </Typography>
                    </Box>
                  }
                  labelPlacement={labelPlacement}
                />
              </Box>
            );
          }
        })}
      </RadioGroup>
    </FormControl>
  );
};
