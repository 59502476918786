/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { FieldInputProps, useField } from 'react-final-form';

import { compose } from '@vk-hr-tek/core/validation';

import { MultipleAutocompleteInput as Input } from '../../input';

import { MultipleAutocompleteInputProps } from './MultipleAutocompleteInput.types';

/**
 * Функция-обработчик изменения значения поля
 * @param {FieldInputProps<string | string[] | number | number[] | undefined, HTMLElement>} input - Пропсы поля из react-final-form
 * @param {((value: string[] | undefined) => void) | undefined} onChange - Дополнительная функция-обработчик изменения значения
 * @returns {Function} - Функция, которая вызывает обработчики изменения значения
 */
const handleChange =
  (
    input: FieldInputProps<
      string | string[] | number | number[] | undefined,
      HTMLElement
    >,
    onChange: ((value: string[] | undefined) => void) | undefined,
  ) =>
  (value: string[] | undefined) => {
    input.onChange(value);

    onChange?.(value);
  };

/**
 * Компонент для выбора нескольких элементов из автозаполняющегося списка
 * @param {MultipleAutocompleteInputProps} props - Пропсы компонента
 * @returns {JSX.Element} - Элемент JSX
 */
export const MultipleAutocompleteInput = (
  props: MultipleAutocompleteInputProps,
) => {
  const {
    label,
    onChange,
    tooltip,
    name,
    placeholder,
    required,
    loading,
    limit,
    disabledTooltip,
    disabled,
    clearable,
    validate = [],
    helperText,
    minInputValueLength,
    renderTags,
    renderOption,
    testId,
    withoutMargin = false,
  } = props;
  const { input, meta } = useField(name, {
    validate: compose(validate),
  });

  /**
   * Возвращаем компонент с загрузкой элементов, если тип поля 'load'
   * @returns {JSX.Element} - Элемент JSX с компонентом MultipleAutocompleteInput
   */
  if (props.type === 'load') {
    return (
      <Input
        {...input}
        type={props.type}
        label={label}
        onChange={handleChange(input, onChange)}
        loading={loading}
        tooltip={tooltip}
        placeholder={placeholder}
        required={required}
        limit={limit}
        disabledTooltip={disabledTooltip}
        disabled={disabled}
        clearable={clearable}
        error={meta.touched && !!meta.error}
        helperText={(meta.touched && meta.error) || helperText}
        loadItems={props.loadItems}
        minInputValueLength={minInputValueLength}
        renderTags={renderTags}
        renderOption={renderOption}
        testId={testId}
        withoutMargin={withoutMargin}
      />
    );
  }

  /**
   * Возвращаем компонент с внешними элементами, если тип поля не 'load'
   * @returns {JSX.Element} - Элемент JSX с компонентом MultipleAutocompleteInput
   */
  return (
    <Input
      {...input}
      type={props.type}
      label={label}
      onChange={handleChange(input, onChange)}
      loading={loading}
      tooltip={tooltip}
      placeholder={placeholder}
      required={required}
      limit={limit}
      disabledTooltip={disabledTooltip}
      disabled={disabled}
      clearable={clearable}
      error={meta.touched && !!meta.error}
      helperText={(meta.touched && meta.error) || helperText}
      externalItems={props.externalItems}
      selectAllLabel={props.selectAllLabel}
      allowSelectAll={props.allowSelectAll}
      minInputValueLength={minInputValueLength}
      renderTags={renderTags}
      renderOption={renderOption}
      testId={testId}
      withoutMargin={withoutMargin}
    />
  );
};
