import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Calendar } from '@vk-hr-tek/core/calendar';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { FilterService } from '@vk-hr-tek/core/filter';

import {
  CreateEventResponse as CreateResponse,
  CreateEventCompanyOptionsList as OptionsCompanyResponse,
  EmployeesList as OptionsCompanyEmployeesResponse,
  EmployeeCreatableEventTypes,
  CreateEventOptionsEventTypes,
  EventBatchEmployeesResponse,
  CreateEventTypeOptions,
  CreateEventTypeParticipantOptions,
  CreateEventCommonOptionsResponse,
  EventBatchListResponse,
  EventBatchEmployeeCountResponse,
} from '@app/gen/events';

import {
  CreateEventDto,
  GetOptionsEmployees,
  GetEventBatchOptionsDto,
  CreateEventBatchDto,
  GetCreateEventTypeParticipantOptionsDto,
  GetCreatableEventTypesForEmployeeDto,
  GetCreateOptionsCompanyDto,
  GetCreateEventTypeOptionsDto,
  GetCreateEventBatchEmployeesDto,
  GetCreateEventBatchEmployeesCountDto,
} from '../dto';

@injectable()
export class CreationService {
  constructor(
    private validator: ValidationService,
    private calendar: Calendar,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private filterService: FilterService,
  ) {}

  async getCreateOptionsCompany(
    getCreateOptionsCompanyDto: GetCreateOptionsCompanyDto,
  ) {
    const { withEventTypes, eventTypeGroup, tag } = getCreateOptionsCompanyDto;
    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/companies',
        {
          with_event_types: withEventTypes,
          ...(eventTypeGroup ? { event_type_group: eventTypeGroup } : {}),
          ...(tag ? { tag } : {}),
        },
        {
          withSide: true,
        },
      ),
      OptionsCompanyResponse,
    );
  }

  async getCreatableEventTypesForEmployee(
    getCreatableEventTypesForEmployeeDto: GetCreatableEventTypesForEmployeeDto,
  ) {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options/employees/${getCreatableEventTypesForEmployeeDto.id}/event_types`,
        {
          ...(getCreatableEventTypesForEmployeeDto?.parentEventId
            ? {
                parent_event_id:
                  getCreatableEventTypesForEmployeeDto.parentEventId,
              }
            : {}),
          ...(getCreatableEventTypesForEmployeeDto.tag
            ? { tag: getCreatableEventTypesForEmployeeDto.tag }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      EmployeeCreatableEventTypes,
    );
  }

  async getCreateEventTypeOptions(
    getCreateEventTypeOptionsDto: GetCreateEventTypeOptionsDto,
  ) {
    await this.validator.validateOrReject(
      getCreateEventTypeOptionsDto,
      GetCreateEventTypeOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options/event_type`,
        {
          event_type_id: getCreateEventTypeOptionsDto.event_type_id,
          parent_event_id: getCreateEventTypeOptionsDto.parent_event_id,
        },
        {
          withSide: true,
        },
      ),
      CreateEventTypeOptions,
    );
  }

  async getCreateEventTypeParticipantOptions(
    getCreateEventTypeParticipantOptionsDto: GetCreateEventTypeParticipantOptionsDto,
  ) {
    await this.validator.validateOrReject(
      getCreateEventTypeParticipantOptionsDto,
      GetCreateEventTypeParticipantOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options/event_type/participant_options`,
        {
          event_type_id: getCreateEventTypeParticipantOptionsDto.eventTypeId,
          role_id: getCreateEventTypeParticipantOptionsDto.roleId,
          ...(typeof getCreateEventTypeParticipantOptionsDto.limit === 'number'
            ? {
                limit: getCreateEventTypeParticipantOptionsDto.limit,
              }
            : {}),
          ...(getCreateEventTypeParticipantOptionsDto.offset
            ? {
                offset: getCreateEventTypeParticipantOptionsDto.offset,
              }
            : {}),
          ...(getCreateEventTypeParticipantOptionsDto.search
            ? {
                search: getCreateEventTypeParticipantOptionsDto.search,
              }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      CreateEventTypeParticipantOptions,
    );
  }

  async getCreateBatchEventTypes(getOptionsEmployees: GetOptionsEmployees) {
    await this.validator.validateOrReject(
      getOptionsEmployees,
      GetOptionsEmployees,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/event_types',
        {
          company_id: getOptionsEmployees.companyId,
          batch_enabled: getOptionsEmployees.batchEnabled,
          no_employee: getOptionsEmployees.noEmployee,
          parent_event_id: getOptionsEmployees.parentEventId,
          event_type_group: getOptionsEmployees.eventTypeGroup,
          ...(getOptionsEmployees.tag ? { tag: getOptionsEmployees.tag } : {}),
        },
        {
          withSide: true,
        },
      ),
      CreateEventOptionsEventTypes,
    );
  }

  async getCreateBatchEmployees(
    getBatchEmployees: GetCreateEventBatchEmployeesDto,
  ) {
    await this.validator.validateOrReject(
      getBatchEmployees,
      GetCreateEventBatchEmployeesDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/batch/employees',
        {
          event_type_id: getBatchEmployees.eventTypeId,
          unit_ids: getBatchEmployees.unitIds,
          ...(getBatchEmployees.parentEventId
            ? { parent_event_id: getBatchEmployees.parentEventId }
            : {}),
          limit: getBatchEmployees.limit,
          offset: getBatchEmployees.offset,
          query: getBatchEmployees.query,
        },
        {
          withSide: true,
        },
      ),
      EventBatchEmployeesResponse,
    );
  }

  async getCreateBatchEmployeesCount(
    getCreateEventBatchEmployeesCountDto: GetCreateEventBatchEmployeesCountDto,
  ) {
    await this.validator.validateOrReject(
      getCreateEventBatchEmployeesCountDto,
      GetCreateEventBatchEmployeesCountDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/batch/employee_count',
        {
          event_type_id: getCreateEventBatchEmployeesCountDto.eventTypeId,
          ...(getCreateEventBatchEmployeesCountDto.unitIds.length
            ? { unit_ids: getCreateEventBatchEmployeesCountDto.unitIds }
            : {}),
          ...(getCreateEventBatchEmployeesCountDto.parentEventId
            ? {
                parent_event_id:
                  getCreateEventBatchEmployeesCountDto.parentEventId,
              }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      EventBatchEmployeeCountResponse,
    );
  }

  async createBatchEvents(createEventBatchDto: CreateEventBatchDto) {
    await this.validator.validateOrReject(
      createEventBatchDto,
      CreateEventBatchDto,
      createEventBatchDto.parentEventId ? ['child'] : ['default'],
    );

    await this.http.post(
      '/event/batch',
      {
        event_type_id: createEventBatchDto.eventTypeId,

        event_batch_name: createEventBatchDto.eventBatchName,
        ...(createEventBatchDto.document
          ? { document: createEventBatchDto.document.value }
          : {}),
        ...(createEventBatchDto.documentDate && {
          document_date: this.calendar.formatISO(
            new Date(createEventBatchDto.documentDate),
            { representation: 'date' },
          ),
        }),
        ...(createEventBatchDto.documentNumber && {
          document_number: createEventBatchDto.documentNumber,
        }),
        ...(createEventBatchDto.assigned_roles && {
          assigned_roles: createEventBatchDto.assigned_roles,
        }),
        ...(createEventBatchDto.parentEventId && {
          parent_event_id: createEventBatchDto.parentEventId,
        }),
        ...(createEventBatchDto.allEmployees ? { all_employees: true } : {}),
        ...(createEventBatchDto.unitIds.length
          ? { unit_ids: createEventBatchDto.unitIds }
          : {}),
        ...(createEventBatchDto.employees
          ? { employee_ids: createEventBatchDto.employees }
          : {}),
      },
      {
        withSide: true,
        isJson: false,
      },
    );
  }

  async getEventEmployees(getOptionsEmployees: GetOptionsEmployees) {
    await this.validator.validateOrReject(
      getOptionsEmployees,
      GetOptionsEmployees,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/employees',
        {
          company_id: getOptionsEmployees.companyId,
          limit: getOptionsEmployees.limit,
          offset: getOptionsEmployees.offset,
          query: getOptionsEmployees.query,
          event_type_group: getOptionsEmployees.eventTypeGroup,
          ...(getOptionsEmployees.tag ? { tag: getOptionsEmployees.tag } : {}),
          ...(getOptionsEmployees.forAnother
            ? { for_another: getOptionsEmployees.forAnother }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      OptionsCompanyEmployeesResponse,
    );
  }

  async createEvent(createEventDto: CreateEventDto) {
    await this.validator.validateOrReject(createEventDto, CreateEventDto, [
      'submit',
    ]);

    const employeeId =
      createEventDto.employeeId === 'no_employee'
        ? undefined
        : createEventDto.employeeId;

    const response = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event',
        {
          event_type_id: createEventDto.eventTypeId,
          employee_id: employeeId,
          ...(createEventDto.assigned_roles && {
            assigned_roles: createEventDto.assigned_roles,
          }),
          ...(createEventDto.eventTypeId && {
            event_type_id: createEventDto.eventTypeId,
          }),
          ...(createEventDto.parentEventId && {
            parent_event_id: createEventDto.parentEventId,
          }),
        },
        { withSide: true },
      ),
      CreateResponse,
    );

    return {
      event_id: response.event_id,
    };
  }

  async getCanCreateBatchEventOrEventForAnotherEmployee(dto: {
    companyId: string;
  }) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/common',
        {
          ...(dto?.companyId
            ? {
                company_id: dto.companyId,
              }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      CreateEventCommonOptionsResponse,
    );

    return result;
  }

  async getExistingBatchEvents(getBatchEmployees: GetEventBatchOptionsDto) {
    await this.validator.validateOrReject(
      getBatchEmployees,
      GetEventBatchOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/batch',
        { company_id: getBatchEmployees.companyId },
        {
          withSide: true,
        },
      ),
      EventBatchListResponse,
    );
  }
}
