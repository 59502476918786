import { IsString, IsNotEmpty, IsUUID } from 'class-validator';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class RetryEventDto {
  @IsUUID()
  @IsNotEmpty()
  eventId: string;

  @IsUUID()
  @IsNotEmpty(notEmptyOptions)
  nodeId: string;

  @IsString()
  @IsNotEmpty(notEmptyOptions)
  actionType: string;
}
