import React, { ReactNode } from 'react';

import { Box } from '../Box';
import { Button } from '../Button';
import { DeleteIcon } from '../icons';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';

interface FormCardProps {
  title: string;
  button: {
    tooltip?: string;
    disabled?: boolean;
    onClick: () => void;
  };
  children: ReactNode | ReactNode[];
}

export const FormCard = ({
  title,
  button: { tooltip, disabled, onClick },
  children,
}: FormCardProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16"
      p="16"
      radius="l"
      bgcolor="bg.greyscale.primary"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="24"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1">{title}</Typography>

        <Tooltip
          title={tooltip || ''}
          placement="top-end"
          disabled={!tooltip || !disabled}
        >
          <div>
            <Button
              icon={<DeleteIcon color={disabled ? 'disabled' : 'blue'} />}
              onClick={onClick}
              variant="tertiaryDark"
              disabled={disabled}
            />
          </div>
        </Tooltip>
      </Box>

      {children}
    </Box>
  );
};
