import {
  SerializedError,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';

import { AppError } from '@vk-hr-tek/core/error';
import { Filter } from '@vk-hr-tek/core/filter';
import type { UnitNodeLabeled, UnitTypeOption } from '@vk-hr-tek/core/units';
import type { FlowTreeNode, LinearFlowNode } from '@vk-hr-tek/core/flow';

import {
  EventListItem,
  EventBatchEmployee,
  EmployeeItem,
  CancelReasonItem as CancelReason,
  CreateEventCompanyItem,
  CreateSimpleEventTypeOptionsResponse as EventTypeOptions,
  CreateSimpleEventTypeCompany as EventTypeCompany,
  EventBatchItem,
  AssignableRoles,
  CreateEventTypeOptionsCopyDocument,
  CreateEventTypeOptionsCopyAttribute,
  EventCompetencyOption,
} from '@app/gen/events';
import { RootState } from '@app/store';

import { EventWithValidators } from '../types';
import { EventTypeItemWithGroup } from '../types/EventTypeItemWithGroup';

export interface EventsState {
  status: 'idle' | 'loading' | 'complete' | 'failed';
  currentIds: string[];
  detail: {
    startEventId: string | null;
    currentId: string | null;
    status: 'idle' | 'loading' | 'loading-text' | 'complete' | 'failed';
    actionTypeForLoadingText: {
      eventId: string | null;
      nodeId: string | null;
      actionType: string | null;
      retryText?: string;
    };
    data: EventWithValidators | null;
    error: AppError | null;
  };
  certificates: {
    items: {
      id: string;
      name: string;
      user: string;
      company: string;
      activeFrom: string;
      activeTo: string;
    }[];
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: null | string;
  };
  total: number;
  totalDocuments: number | null;
  totalMy?: number;
  totalMyTeam?: number;
  finished: number;
  error: AppError | null;
  filters: {
    data: Filter[] | null;
    id: string;
  };
  filtersWithCompanyId: Record<string, Filter[]>;
  cancelReasons: CancelReason[] | null;
  canCreateEvent: boolean;
  canCreateEventType: boolean;
  creation: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    employees: {
      status: 'idle' | 'loading' | 'complete' | 'failed';
      list: EmployeeItem[];
    };
    items: CreateEventCompanyItem[] | null;
    error: AppError | null;
    canCreateBatch: boolean;
    canCreateForAnother: boolean;
    eventTypes: EventTypeItemWithGroup[] | null;
    employeeEventTypes: EventTypeItemWithGroup[] | null;
    selected: {
      company: { id: string; name: string };
      eventType: { id: string; name: string; documentInfoRequired: boolean };
      employees: EventBatchEmployee[];
      existingBatches: EventBatchItem[];
      unitTypeOptions?: UnitTypeOption[];
      rootUnit?: UnitNodeLabeled | null;
      employeesLoadingError?: boolean;
    } | null;
  };
  createEventTypeOptions: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    options: Record<
      string,
      {
        assignableRoles?: AssignableRoles;
        copyDocuments?: CreateEventTypeOptionsCopyDocument[];
        copyAttributes?: CreateEventTypeOptionsCopyAttribute[];
      }
    >;
    error: AppError | null;
  };
  eventTypes: {
    companies: (EventTypeCompany & EventTypeOptions)[];
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: AppError | null;
  };
  pepSign: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
  };
  pepSignBatch: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
  };
  ukepSign: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
  };
  ukepSignBatch: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
  };
  unepSign: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
  };
  unepSignBatch: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
  };
  companyBatchSign: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    successCounter: { success_count: number; error_count: number } | null;
    error: AppError | null;
  };
  batchDownload: {
    downloads: {
      id: string;
      status: 'waiting' | 'complete' | 'failed';
      counter: number;
    }[];
  };
  flow: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    tree?: FlowTreeNode | null;
    linearTree: LinearFlowNode[];
    startNodeId?: string;
    error: AppError | null;
  };
  printFlow: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: AppError | null;
  };
  resendedNodes: {
    eventId: string;
    nodeId: string;
    timestamp: number;
  }[];
  codeRequesting: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: AppError | null;
  };

  competencyOptions: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: EventCompetencyOption[];
    error: AppError | null;
  };
}

export interface EventsWithRootState extends RootState {
  events: EventsState & EntityState<EventListItem>;
}

export const initialState: EventsState = {
  status: 'idle',
  currentIds: [],
  detail: {
    startEventId: null,
    currentId: null,
    status: 'idle',
    data: null,
    actionTypeForLoadingText: {
      eventId: null,
      nodeId: null,
      actionType: null,
      retryText: undefined,
    },
    error: null,
  },
  total: 0,
  totalDocuments: null,
  totalMy: undefined,
  totalMyTeam: undefined,
  finished: 0,
  error: null,
  filters: {
    data: null,
    id: '',
  },
  filtersWithCompanyId: {},
  certificates: {
    items: [],
    status: 'idle',
    error: null,
  },
  cancelReasons: null,
  canCreateEvent: false,
  canCreateEventType: false,
  creation: {
    status: 'idle',
    items: null,
    eventTypes: null,
    employees: {
      status: 'idle',
      list: [],
    },
    employeeEventTypes: null,
    error: null,
    canCreateBatch: false,
    canCreateForAnother: false,
    selected: null,
  },
  createEventTypeOptions: {
    status: 'idle',
    options: {},
    error: null,
  },
  eventTypes: {
    companies: [],
    status: 'idle',
    error: null,
  },
  pepSign: {
    status: 'idle',
  },
  pepSignBatch: {
    status: 'idle',
  },
  ukepSign: {
    status: 'idle',
  },
  ukepSignBatch: {
    status: 'idle',
  },
  unepSign: {
    status: 'idle',
  },
  unepSignBatch: {
    status: 'idle',
  },
  companyBatchSign: {
    status: 'idle',
    error: null,
    successCounter: null,
  },
  batchDownload: {
    downloads: [],
  },
  flow: { status: 'idle', error: null, linearTree: [] },
  printFlow: { status: 'idle', error: null },
  resendedNodes: [],
  codeRequesting: {
    status: 'idle',
    error: null,
  },
  competencyOptions: {
    status: 'idle',
    items: [],
    error: null,
  },
};

export const startLoading = (state: EventsState) => {
  state.status = 'loading';
  state.error = null;
  state.total = 0;
  state.finished = 0;
  state.currentIds = [];
  state.detail.data = null;
};

export const completeLoading = (state: EventsState) => {
  state.status = 'complete';
  state.error = null;
};

export const setError = (
  state: EventsState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.status = 'failed';
  state.error =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};

export const eventsAdapter = createEntityAdapter<EventListItem>();

export const setCompanyBatchSignError = (
  state: EventsState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.companyBatchSign.status = 'failed';
  state.companyBatchSign.error =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};

export const setCompanyBatchSignSuccess = (
  state: EventsState,
  payload: { success_count: number; error_count: number } | null,
) => {
  state.companyBatchSign.status = 'complete';
  state.companyBatchSign.successCounter = payload;
};

export const setCodeRequestingLoading = (state: EventsState) => {
  state.codeRequesting.status = 'loading';
  state.codeRequesting.error = null;
};

export const setCodeRequestingSuccess = (state: EventsState) => {
  state.codeRequesting.status = 'complete';
  state.codeRequesting.error = null;
};

export const setCodeRequestingError = (
  state: EventsState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.codeRequesting.status = 'failed';
  state.codeRequesting.error =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};
