import React, { useEffect } from 'react';

import { useDispatch, useSelector } from '@vk-hr-tek/app/app/hooks';
import {
  getTranslations,
  selectTranslationsError,
  selectTranslationsLoading,
} from '@vk-hr-tek/core/translations/slice';
import { useAuth } from '@vk-hr-tek/app/auth';
import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import {
  HTTP_INTERNAL_SERVER_ERROR_STATUS,
  HTTP_NOT_FOUND_STATUS,
} from '@vk-hr-tek/core/http';

import { PageError } from '..';

interface WithTranslationsProps {
  children: React.ReactNode | React.ReactNode[];
}

export const WithTranslations = ({ children }: WithTranslationsProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuth();
  const translationsLoadingStatus = useSelector(selectTranslationsLoading);
  const translationsError = useSelector(selectTranslationsError);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getTranslations());
    }
  }, [dispatch, isAuthenticated]);

  if (translationsLoadingStatus === 'failed') {
    return (
      <PageError
        status={translationsError?.status || HTTP_INTERNAL_SERVER_ERROR_STATUS}
        traceId={translationsError?.traceId}
        errorMessage={`Не удалось получить переводы.${
          translationsError?.status === HTTP_NOT_FOUND_STATUS &&
          ` Проверьте пути до файлов переводов или проверьте url ресурса для получения переводов`
        }`}
      />
    );
  }

  return translationsLoadingStatus === 'loading' ? (
    <Box display="flex" justifyContent="center" p="32">
      <CircularProgress size={50} />
    </Box>
  ) : (
    //eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
