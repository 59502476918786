import { injectable } from 'inversify';
import { format, parseISO, differenceInCalendarDays } from 'date-fns';

/* eslint-disable no-magic-numbers */

@injectable()
export class FormatService {
  /** Преобразовать дату к формату "ДД.ММ.ГГГГ в ЧЧ:ММ"
   * Если строковая дата не валидна/не корректна, то возвращается пустая строка
   * @param {string} dateString Дата
   * @returns {string}
   *  */
  toDateTime(dateString: string): string {
    if (Number.isNaN(Date.parse(dateString))) {
      return '';
    }

    const date = new Date(dateString);

    return `${date.getDate().toString().padStart(2, '0')}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}.${date.getFullYear()} в ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

  /** Преобразовать дату к формату "ДД.ММ.ГГГГ"
   * Если строковая дата не валидна/не корректна, то возвращается пустая строка
   * @param {string} dateString Дата
   * @returns {string}
   *  */
  toDate(dateString: string): string {
    if (Number.isNaN(Date.parse(dateString))) {
      return '';
    }

    const date = new Date(dateString);

    return `${date.getDate().toString().padStart(2, '0')}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}.${date.getFullYear()}`;
  }

  /** Преобразовать дату к формату времени "ЧЧ:ММ"
   * Если строковая дата не валидна/не корректна, то возвращается пустая строка
   * @param {string} dateString Дата
   * @returns {string}
   *  */
  toTime(dateString: string): string {
    if (Number.isNaN(Date.parse(dateString))) {
      return '';
    }

    const date = new Date(dateString);

    return `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  }

  /** Преобразовать число к строке
   * Знак сохраняется
   * Если число меньше 10, то после знака добавляется 0
   * @param {number} count Преобразуемое число
   * @returns {string}
   *  */
  zeroPadding(count: number): string {
    const absoluteCount = Math.abs(count);

    const minus = count < 0 ? '-' : '';

    const zero = absoluteCount < 10 ? '0' : '';

    return `${minus}${zero}${absoluteCount}`;
  }

  /** Получить выражение, описывающее множественное число, состоящее из числа и слова в правильной форме
   * @param {number} count Числовая часть
   * @param {string[]} words Словесная часть
   * @param {Object} options
   * @param {boolean} [options.withZeroPad] Опции
   * @returns {string}
   *  */
  pluralize = (
    count: number,
    words: string[],
    options: { withZeroPad?: boolean } = {},
  ) => {
    const { withZeroPad } = options;
    const cases = [2, 0, 1, 1, 1, 2];
    const formattedCount = Math.abs(count);

    const resultNumber = withZeroPad ? this.zeroPadding(count) : count;

    return `${resultNumber} ${
      words[
        formattedCount % 100 > 4 && formattedCount % 100 < 20
          ? 2
          : cases[Math.min(formattedCount % 10, 5)]
      ]
    }`;
  };

  /** Преобразовать количество байтов к ближайшему целому значению единицы более высокого порядка
   * @param {number} bytes Количество байтов
   * @returns {string}
   *  */
  bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
    return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
  };

  /** Преобразовать номер телефона к формату "+7 999 999 99 99"
   * @param {string} phoneString Номер телефона
   * @returns {string}
   *  */
  toPhone(phoneString: string): string {
    const phoneRegExp = new RegExp(
      '(\\+?[7|8])(\\d{3})(\\d{3})(\\d{2})(\\d{2})',
    );

    const phoneMatch = phoneRegExp.exec(phoneString);

    if (!phoneMatch) {
      return phoneString;
    }

    return `+7 ${phoneMatch[2]} ${phoneMatch[3]} ${phoneMatch[4]} ${phoneMatch[5]}`;
  }

  /** Преобразовать номер СНИЛС к формату "999-999-999 99"
   * @param {string} snilsString Номер СНИЛС
   * @returns {string}
   *  */
  toSnils(snilsString: string): string {
    return snilsString.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/i, '$1-$2-$3 $4');
  }

  /** Преобразовать число к сокращенной записе с указанием тыс/млн/млрд
   * @param {number} rawNumber Число
   * @returns {string}
   *  */
  convertNumber(rawNumber: number): string {
    let num = Math.abs(rawNumber);
    let rank = 0;

    while (num >= 1000) {
      rank++;
      num = +(num / 1000).toFixed(1);
    }

    const result = num.toString().replace('.', ',');

    switch (rank) {
      case 1:
        return `${result} тыс.`;
      case 2:
        return `${result} млн.`;
      case 3:
        return `${result} млрд.`;
      default:
        return result;
    }
  }

  /** Преобразовать дату к формату с GMT "dd.MM.yyyy HH:mm (zzzz)"
   * @param {string} date Дата
   * @returns {string}
   *  */
  toTimeStringWithGMT(date: string) {
    return format(parseISO(date), 'dd.MM.yyyy HH:mm (zzzz)');
  }

  /** Преобразовать дату к формату с GMT "dd.MM.yyyy"
   * @param {string} date Дата
   * @returns {string}
   *  */
  toTimeString(date: string) {
    return format(parseISO(date), 'dd.MM.yyyy');
  }

  toDateRangeWithDaysBetween(dateFrom: string, dateTo: string) {
    const dateStart = this.toDate(dateFrom);
    const dateEnd = this.toDate(dateTo);

    return `${dateStart} - ${dateEnd} (${differenceInCalendarDays(
      new Date(dateTo),
      new Date(dateFrom),
    )} дн.)`;
  }

  toDateRange(dateFrom: string, dateTo: string) {
    const dateStart = this.toDate(dateFrom);
    const dateEnd = this.toDate(dateTo);

    return `${dateStart} - ${dateEnd}`;
  }
}
