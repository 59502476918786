/* eslint-disable @typescript-eslint/no-explicit-any */
import { injectable } from 'inversify';
import { at } from 'lodash';

import { Calendar } from '@vk-hr-tek/core/calendar';

import {
  AbsenceOverlapValidator,
  DivisibleByValidator,
  ForbiddenPeriodsValidator,
  HolydaysExclusionCheckValidator,
  MaximumDurationValidator,
  MinHiringDayValidator,
  MinimumDurationValidator,
  NoOverlapValidator,
  SameMonthValidator,
  VacationAvailableValidator,
  VacationMin5daysBetweenNotWorkdaysValidator,
  WeekendExclusionCheckValidator,
  WorkingDayCheckValidator,
} from '@app/gen/events';

import {
  SingleHolydaysExclusionCheckValidator,
  SingleWorkingDayCheckValidator,
  Validator,
} from '../responses';

@injectable()
export class EventsValidationService {
  constructor(private calendar: Calendar) {}

  private validateMinHiringDay(
    operation: 'eq' | 'ge' | 'gt' | 'le' | 'lt',
    date: Date,
    hiredAt: string,
  ) {
    switch (operation) {
      case 'ge':
      case 'gt':
        return this.calendar.isLessThan(
          date,
          this.calendar.startOfDay(
            operation === 'ge'
              ? new Date(hiredAt)
              : this.calendar.add(new Date(hiredAt), 1),
          ),
        );

      case 'le':
      case 'lt':
        return this.calendar.isMoreThan(
          date,
          this.calendar.startOfDay(
            operation === 'le'
              ? new Date(hiredAt)
              : this.calendar.sub(new Date(hiredAt), 1),
          ),
        );

      case 'eq':
        return !this.calendar.isSameDay(date, new Date(hiredAt));
    }
  }

  private isMinHiringDay(
    validator: Validator,
  ): validator is MinHiringDayValidator {
    return validator.type === 'min_hiring_day' && !!validator.hired_at;
  }

  private isMinDuration(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is MinimumDurationValidator {
    return (
      validator.type === 'min_duration' &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isMaxDuration(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is MaximumDurationValidator {
    return (
      validator.type === 'max_duration' &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isVacationAvailable(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is VacationAvailableValidator {
    return (
      validator.type === 'vacation_available' &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isSameMonth(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is SameMonthValidator {
    return (
      validator.type === 'same_month' &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isDivisibleBy(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is DivisibleByValidator {
    return (
      validator.type === 'divisible_by' &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isWorkingDay(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is WorkingDayCheckValidator {
    return (
      validator.type === 'working_day' &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isHolydaysExclusion(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is HolydaysExclusionCheckValidator {
    return (
      validator.type === 'holydays_exclusion' &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isSingleWorkingDay(
    validator: Validator,
    id: string,
  ): validator is SingleWorkingDayCheckValidator {
    return (
      validator.type === 'single_working_day' && validator.attribute_id === id
    );
  }

  private isSingleHolydaysExclusion(
    validator: Validator,
    id: string,
  ): validator is SingleHolydaysExclusionCheckValidator {
    return (
      validator.type === 'single_holydays_exclusion' &&
      validator.attribute_id === id
    );
  }

  private isWeekendExclusion(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is WeekendExclusionCheckValidator {
    return (
      validator.type === 'weekend_exclusion' &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isNoOverlapOrForbiddenPeriods(
    validator: Validator,
  ): validator is NoOverlapValidator | ForbiddenPeriodsValidator {
    return (
      validator.type === 'no_overlap' || validator.type === 'forbidden_periods'
    );
  }

  private isNoOverlapOrForbiddenPeriodsFrom(
    validator: Validator,
    id: string,
  ): validator is NoOverlapValidator | ForbiddenPeriodsValidator {
    return (
      this.isNoOverlapOrForbiddenPeriods(validator) &&
      validator.from_attribute_id === id
    );
  }

  private isNoOverlapOrForbiddenPeriodsTo(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is NoOverlapValidator | ForbiddenPeriodsValidator {
    return (
      this.isNoOverlapOrForbiddenPeriods(validator) &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isVacationMin5DaysBetweenNotWorkdays(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is VacationMin5daysBetweenNotWorkdaysValidator {
    return (
      validator.type === 'vacation_min_5_days_between_not_workdays' &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  private isNoAbsencesOverlap(
    validator: Validator,
  ): validator is AbsenceOverlapValidator {
    return validator.type === 'has_absence';
  }

  private isNoAbsencesOverlapFrom(
    validator: Validator,
    id: string,
  ): validator is AbsenceOverlapValidator {
    return (
      this.isNoAbsencesOverlap(validator) && validator.from_attribute_id === id
    );
  }

  private isNoAbsencesOverlapTo(
    validator: Validator,
    id: string,
    formValues: Record<string, string>,
    prefix: string,
  ): validator is AbsenceOverlapValidator {
    return (
      this.isNoAbsencesOverlap(validator) &&
      validator.to_attribute_id === id &&
      !!at(formValues, `${prefix}_attribute_${validator.from_attribute_id}`)[0]
    );
  }

  hasAvailableDatesInRange({
    fromDate,
    toDate,
    toAtributeId: id,
    validators,
  }: {
    fromDate: Date;
    toDate: Date;
    toAtributeId: string;
    validators: Validator[];
  }) {
    return validators
      .filter(({ type }) => type !== 'no_overlap' && type !== 'same_month')
      .some((dateValidator) => {
        if (dateValidator.type === 'min_date') {
          return this.calendar.isLessThan(
            toDate,
            this.calendar.startOfDay(new Date(dateValidator.min_date)),
          );
        }

        if (dateValidator.type === 'max_date') {
          return this.calendar.isMoreThan(
            toDate,
            this.calendar.endOfDay(new Date(dateValidator.max_date)),
          );
        }

        if (dateValidator.type === 'min_hiring_day' && dateValidator.hired_at) {
          return this.validateMinHiringDay(
            dateValidator.operation,
            toDate,
            dateValidator.hired_at,
          );
        }

        if (
          dateValidator.type === 'divisible_by' &&
          dateValidator.to_attribute_id === id
        ) {
          return this.calendar.isMoreThan(
            this.calendar.endOfDay(
              this.calendar.addWithoutHolidayDays(
                fromDate,
                dateValidator.divisible_by - 1,
              ),
            ),
            toDate,
          );
        }

        if (
          dateValidator.type === 'min_duration' &&
          dateValidator.to_attribute_id === id
        ) {
          if (dateValidator.days_count < 0) {
            return this.calendar.isWithinDays(toDate, {
              days_count: dateValidator.days_count
                ? dateValidator.days_count - 1
                : 0,
              from_date: fromDate,
              working_days_only: dateValidator.working_days_only,
            });
          }

          return this.calendar.isNotWithinDays(toDate, {
            days_count: dateValidator.days_count
              ? dateValidator.days_count - 1
              : 0,
            from_date: fromDate,
            working_days_only: dateValidator.working_days_only,
          });
        }

        if (
          dateValidator.type === 'max_duration' &&
          dateValidator.to_attribute_id === id
        ) {
          if (dateValidator.days_count < 0) {
            return this.calendar.isNotWithinDays(toDate, {
              days_count: dateValidator.days_count
                ? dateValidator.days_count - 1
                : 0,
              from_date: fromDate,
              working_days_only: dateValidator.working_days_only,
            });
          }

          return this.calendar.isWithinDays(toDate, {
            days_count: dateValidator.days_count
              ? dateValidator.days_count - 1
              : 0,
            from_date: fromDate,
            working_days_only: dateValidator.working_days_only,
          });
        }

        if (
          dateValidator.type === 'working_day' &&
          dateValidator.to_attribute_id === id
        ) {
          return (
            this.calendar.isHolidayOrWeekend(fromDate) &&
            this.calendar.areAllHolidaysInInterval(toDate, {
              from_date: fromDate,
            })
          );
        }

        if (
          dateValidator.type === 'holydays_exclusion' &&
          dateValidator.to_attribute_id === id
        ) {
          return (
            this.calendar.isHoliday(fromDate) &&
            this.calendar.areAllHolidaysInInterval(toDate, {
              from_date: fromDate,
              exclude_weekends: true,
            })
          );
        }

        if (
          dateValidator.type === 'weekend_exclusion' &&
          dateValidator.to_attribute_id === id
        ) {
          return (
            this.calendar.isSameDay(fromDate, toDate) &&
            ((this.calendar.isHolidayOrWeekend(this.calendar.add(toDate, 1)) &&
              dateValidator.existing_vacations.find((vacation) => {
                return this.calendar.isSameDay(
                  new Date(vacation),
                  this.calendar.addBusinessDays(toDate, 1),
                );
              })) ||
              (this.calendar.isHolidayOrWeekend(this.calendar.sub(toDate, 1)) &&
                dateValidator.existing_vacations.find((vacation) =>
                  this.calendar.isSameDay(
                    new Date(vacation),
                    this.calendar.subBusinessDays(toDate, 1),
                  ),
                )))
          );
        }
      });
  }

  isDateInvalid({
    currentDate,
    validators,
    toAtributeId: id,
    values: formValues,
    prefix,
  }: {
    currentDate: Date;
    validators: Validator[];
    toAtributeId: string;
    values: Record<string, string>;
    prefix: string;
  }) {
    const day = this.calendar.startOfDay(currentDate);
    return validators.some((dateValidator) => {
      if (dateValidator.type === 'min_date') {
        return this.calendar.isLessThan(
          day,
          this.calendar.startOfDay(new Date(dateValidator.min_date)),
        );
      }

      if (dateValidator.type === 'max_date') {
        return this.calendar.isMoreThan(
          day,
          this.calendar.endOfDay(new Date(dateValidator.max_date)),
        );
      }

      if (this.isMinHiringDay(dateValidator)) {
        return this.validateMinHiringDay(
          dateValidator.operation,
          day,
          dateValidator.hired_at as string,
        );
      }

      if (this.isMinDuration(dateValidator, id, formValues, prefix)) {
        return this.calendar.isNotWithinDays(day, {
          days_count: dateValidator.days_count
            ? dateValidator.days_count - 1
            : 0,
          from_date: new Date(
            at(
              formValues,
              `${prefix}_attribute_${dateValidator.from_attribute_id}`,
            )[0],
          ),
          working_days_only: dateValidator.working_days_only,
        });
      }

      if (this.isMaxDuration(dateValidator, id, formValues, prefix)) {
        return this.calendar.isWithinDays(day, {
          days_count: dateValidator.days_count || 0,
          from_date: new Date(
            at(
              formValues,
              `${prefix}_attribute_${dateValidator.from_attribute_id}`,
            )[0],
          ),
          working_days_only: dateValidator.working_days_only,
        });
      }

      if (this.isVacationAvailable(dateValidator, id, formValues, prefix)) {
        return this.calendar.isWithinDays(day, {
          days_count: dateValidator.vacation_available_days || 0,
          from_date: new Date(
            at(
              formValues,
              `${prefix}_attribute_${dateValidator.from_attribute_id}`,
            )[0],
          ),
          working_days_only: false,
        });
      }

      if (this.isSameMonth(dateValidator, id, formValues, prefix)) {
        return !this.calendar.isSameMonth(
          day,
          new Date(
            at(
              formValues,
              `${prefix}_attribute_${dateValidator.from_attribute_id}`,
            )[0],
          ),
        );
      }

      if (this.isDivisibleBy(dateValidator, id, formValues, prefix)) {
        const selectedDay = new Date(
          at(
            formValues,
            `${prefix}_attribute_${dateValidator.from_attribute_id}`,
          )[0],
        );

        const prevSelectedDay = this.calendar.sub(selectedDay, 1);

        const isPrevDayIsHoliday = this.calendar.isHoliday(prevSelectedDay);

        const nextDay = this.calendar.add(day, 1);

        const difference = this.calendar.differenceInDays(
          isPrevDayIsHoliday ? nextDay : day,
          this.calendar.startOfDay(
            isPrevDayIsHoliday ? selectedDay : prevSelectedDay,
          ),
          { excludeHolidays: true },
        );

        return difference % dateValidator.divisible_by !== 0;
      }

      if (this.isWorkingDay(dateValidator, id, formValues, prefix)) {
        const fromDate = new Date(
          at(
            formValues,
            `${prefix}_attribute_${dateValidator.from_attribute_id}`,
          )[0],
        );

        return (
          this.calendar.isHolidayOrWeekend(fromDate) &&
          this.calendar.areAllHolidaysInInterval(day, {
            from_date: fromDate,
          })
        );
      }

      if (this.isHolydaysExclusion(dateValidator, id, formValues, prefix)) {
        const fromDate = new Date(
          at(
            formValues,
            `${prefix}_attribute_${dateValidator.from_attribute_id}`,
          )[0],
        );

        return (
          this.calendar.isHoliday(fromDate) &&
          this.calendar.areAllHolidaysInInterval(day, {
            from_date: fromDate,
            exclude_weekends: true,
          })
        );
      }

      if (this.isSingleWorkingDay(dateValidator, id)) {
        return this.calendar.isHolidayOrWeekend(day);
      }

      if (this.isSingleHolydaysExclusion(dateValidator, id)) {
        return this.calendar.isHoliday(day);
      }

      if (this.isWeekendExclusion(dateValidator, id, formValues, prefix)) {
        const fromDate = new Date(
          at(
            formValues,
            `${prefix}_attribute_${dateValidator.from_attribute_id}`,
          )[0],
        );

        return (
          this.calendar.isSameDay(fromDate, day) &&
          ((this.calendar.isHolidayOrWeekend(this.calendar.add(day, 1)) &&
            dateValidator.existing_vacations.find((vacation) =>
              this.calendar.isSameDay(
                new Date(vacation),
                this.calendar.addBusinessDays(day, 1),
              ),
            )) ||
            (this.calendar.isHolidayOrWeekend(this.calendar.sub(day, 1)) &&
              dateValidator.existing_vacations.find((vacation) =>
                this.calendar.isSameDay(
                  new Date(vacation),
                  this.calendar.subBusinessDays(day, 1),
                ),
              )))
        );
      }

      if (this.isNoOverlapOrForbiddenPeriodsFrom(dateValidator, id)) {
        return this.calendar.isInNotWithinIntervals(day, {
          existing_dates: dateValidator.existing_dates,
        });
      }

      if (
        this.isNoOverlapOrForbiddenPeriodsTo(
          dateValidator,
          id,
          formValues,
          prefix,
        )
      ) {
        return this.calendar.isInNotWithinIntervalsAfterDate(day, {
          from_date: new Date(
            at(
              formValues,
              `${prefix}_attribute_${
                (
                  dateValidator as
                    | NoOverlapValidator
                    | ForbiddenPeriodsValidator
                ).from_attribute_id
              }`,
            )[0],
          ),
          existing_dates: (
            dateValidator as NoOverlapValidator | ForbiddenPeriodsValidator
          ).existing_dates,
        });
      }

      if (
        this.isVacationMin5DaysBetweenNotWorkdays(
          dateValidator,
          id,
          formValues,
          prefix,
        )
      ) {
        const minDaysBetweenNotWorkDays = 5;

        const startDate = at(
          formValues,
          `${prefix}_attribute_${dateValidator.from_attribute_id}`,
        )[0];

        return (
          this.calendar.getPreviousDayIsWeekendOrHoliday(
            new Date(startDate),
            1,
          ) &&
          this.calendar.getNextDayIsWeekendOrHoliday(day, 1) &&
          this.calendar.countOfVacationDays(day, new Date(startDate)) <
            minDaysBetweenNotWorkDays
        );
      }

      if (this.isNoAbsencesOverlapFrom(dateValidator, id)) {
        return this.calendar.isInNotWithinIntervals(day, {
          existing_dates: dateValidator.existing_dates.filter(
            (item) => item.action === 'error',
          ),
        });
      }

      if (this.isNoAbsencesOverlapTo(dateValidator, id, formValues, prefix)) {
        return this.calendar.isInNotWithinIntervalsAfterDate(day, {
          from_date: new Date(
            at(
              formValues,
              `${prefix}_attribute_${
                (dateValidator as AbsenceOverlapValidator).from_attribute_id
              }`,
            )[0],
          ),
          existing_dates: (
            dateValidator as AbsenceOverlapValidator
          ).existing_dates.filter((item) => item.action === 'error'),
        });
      }

      return false;
    });
  }

  /** Определить учитывается ли указанное количество выходных дней
   * при планировании указанного количества рабочих дней
   * @template T
   * @param {string} [message] Сообщение об ошибке
   * @param [options]
   * @param {string} options.rangeStart Дата начала отпуска
   * @param {number} options.workingDays Количество рабочих дней
   * @param {number} options.minWeekendDays Минимальное количество выходных дней
   * @returns {(value: any) => string | undefined}
   * */
  minWeekendDaysValidator = (
    message: string,
    options?: {
      rangeStart: string;
      workingDays: number;
      minWeekendDays: number;
    },
  ) => {
    return (value: any, allValues?: any) => {
      if (!options || !value) {
        return;
      }
      const startDate = at(allValues, options.rangeStart)[0];
      const duration =
        this.calendar.differenceInCalendarDays(
          new Date(value),
          new Date(startDate),
        ) + 1;
      const countOfWeekendDaysInInterval =
        startDate &&
        this.calendar.countOfWeekendsInInterval(
          new Date(startDate),
          new Date(value),
        );

      if (
        duration === options.workingDays &&
        countOfWeekendDaysInInterval < options.minWeekendDays
      ) {
        return message;
      }
    };
  };

  minSpecificWeekendDaysValidator(message: string, rangeStart: string) {
    const isMinWeekendDaysCountInvalid = (days: number, weekends: number) => {
      const numberOfDaysInWeek = 7;
      const numberOfWeekendsInWeek = 2;
      const numberOfWorkingDaysInWeek =
        numberOfDaysInWeek - numberOfWeekendsInWeek;
      const fullWeeksCount = Math.floor(days / numberOfDaysInWeek);
      const remainingDays = days % numberOfDaysInWeek;
      const weekendsCountInWeeks = fullWeeksCount * numberOfWeekendsInWeek;

      if (
        remainingDays <= numberOfWorkingDaysInWeek &&
        weekends >= weekendsCountInWeeks
      ) {
        return false;
      } else {
        return !(
          remainingDays === numberOfDaysInWeek - 1 &&
          weekends >= weekendsCountInWeeks + 1
        );
      }
    };

    return (value: any, allValues?: any) => {
      if (!value || !rangeStart) return;

      const startDate = at(allValues, rangeStart)[0];

      const daysCount = this.calendar.countOfVacationDays(
        new Date(value),
        new Date(startDate),
      );
      const weekendsCount = this.calendar.countOfWeekendsInInterval(
        new Date(value),
        new Date(startDate),
      );

      if (isMinWeekendDaysCountInvalid(daysCount, weekendsCount)) {
        return message;
      }
    };
  }

  vacationMin5DaysBetweenWeekendsValidator(
    message: string,
    rangeStart: string,
  ) {
    return (value: any, allValues?: any) => {
      if (!value || !rangeStart) return;
      const minDaysBetweenNotWorkDays = 5;
      const startDate = at(allValues, rangeStart)[0];

      if (
        this.calendar.getPreviousDayIsWeekendOrHoliday(
          new Date(startDate),
          1,
        ) &&
        this.calendar.getNextDayIsWeekendOrHoliday(new Date(value), 1) &&
        this.calendar.countOfVacationDays(
          new Date(value),
          new Date(startDate),
        ) < minDaysBetweenNotWorkDays
      ) {
        return message;
      }
    };
  }

  nonWorkingDaysIncludedCheckValidator(
    message: string,
    options: {
      rangeStart: string;
    },
  ) {
    return (value: any, allValues?: any) => {
      if (!value) {
        return;
      }

      const startDate = at(allValues, options.rangeStart)[0];

      const start =
        new Date(startDate) > new Date(value)
          ? new Date(value)
          : new Date(startDate);
      const end =
        new Date(startDate) > new Date(value)
          ? new Date(startDate)
          : new Date(value);

      const range = this.calendar.getAllDaysOfInterval(start, end);

      const intervalsText = this.calendar.getHolidaysOrWeekendsIntervals(range);

      if (intervalsText) {
        return `${message}: ${intervalsText}`;
      }
    };
  }

  absencesOverlapValidator(options: {
    rangeStart: string;
    existingDates: {
      from_date: string;
      to_date: string;
      action: 'error' | 'warning';
      message: string;
    }[];
  }) {
    return (value: any, allValues?: any) => {
      if (!value) {
        return;
      }

      const startDate = at(allValues, options.rangeStart)[0];

      const start = new Date(startDate);
      const end = new Date(value);

      const range = this.calendar.getAllDaysOfInterval(start, end);

      const filteredExistingDates = options.existingDates.filter(
        (existingDate) => existingDate.action === 'warning',
      );

      const overlapInterval = range.reduce(
        (
          interval:
            | {
                from_date: string;
                to_date: string;
                action: 'error' | 'warning';
                message: string;
              }
            | undefined,
          date,
        ) => {
          if (interval) return interval;

          return this.calendar.getOverlapInterval(date, {
            existing_dates: filteredExistingDates,
          });
        },
        undefined,
      );

      return overlapInterval?.message;
    };
  }
}
