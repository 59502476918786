import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';
import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import {
  FiltersResponse,
  Filter,
  FilterService,
  FilterType,
} from '@vk-hr-tek/core/filter';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { QueryService } from '@vk-hr-tek/core/query';

export class VacationAvailableDaysParams {
  @IsUUID()
  @IsNotEmpty()
  employee_id: string;
}

export class VacationScheduleMyDeadlineParams {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;
}

export class VacationScheduleFavoritesParams {
  @IsUUID()
  @IsNotEmpty()
  schedule_id: string;
}

export class VacationScheduleStartParams {
  @IsUUID()
  @IsNotEmpty()
  schedule_id: string;
}

export class VacationScheduleParams {
  @IsUUID()
  @IsNotEmpty()
  schedule_id: string;
}

export type AbsenceGroupNick = string;

export class AbsenceGroup {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn([
    'vacation',
    'maternity_leave',
    'student_leave',
    'business_trip',
    'sick_leave',
  ])
  @IsString()
  @IsNotEmpty()
  nick:
    | 'vacation'
    | 'maternity_leave'
    | 'student_leave'
    | 'business_trip'
    | 'sick_leave';
}

export class EmployeeAbsence {
  @IsDateString()
  @IsNotEmpty()
  from_date: string;

  @IsDateString()
  @IsNotEmpty()
  to_date: string;

  @IsInt()
  @IsNotEmpty()
  days_count: number;

  @ValidateNested()
  @Type(() => AbsenceGroup)
  @IsNotEmpty()
  absence_group: AbsenceGroup;
}

export type SignType = string;

export class CompanyItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  tsp_url?: string;

  @IsIn(['api', 'browser_plugin'])
  @IsString()
  @IsOptional()
  hash_source?: 'api' | 'browser_plugin';
}

export class CompanyEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  email?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsOptional()
  position?: string;

  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsOptional()
  sign_type?:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';

  @IsUUID()
  @IsOptional()
  unit_id?: string;

  @IsString()
  @IsOptional()
  unit_breadcrumbs?: string;

  @IsUUID()
  @IsNotEmpty()
  user_id: string;

  @ValidateNested({ each: true })
  @Type(() => EmployeeAbsence)
  @IsArray()
  @IsOptional()
  absences?: EmployeeAbsence[];
}

export type UnitManagerSettingApprove = string;

export class CompanyEventTypeItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class UnitManagerSetting {
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  @IsNotEmpty()
  event_type: CompanyEventTypeItem;

  @IsIn(['always', 'direct_only', 'never'])
  @IsString()
  @IsNotEmpty()
  approve: 'always' | 'direct_only' | 'never';
}

export class Unit {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsUUID()
  @IsOptional()
  parent_id?: string;

  @IsBoolean()
  @IsNotEmpty()
  limited_access: boolean;

  @ValidateNested()
  @Type(() => CompanyEmployee)
  @IsOptional()
  manager?: CompanyEmployee;

  @ValidateNested({ each: true })
  @Type(() => UnitManagerSetting)
  @IsArray()
  @IsOptional()
  manager_settings?: UnitManagerSetting[];

  @ValidateNested({ each: true })
  @Type(() => CompanyEmployee)
  @IsArray()
  @IsOptional()
  assistants?: CompanyEmployee[];

  @IsBoolean()
  @IsNotEmpty()
  is_separate: boolean;

  @IsBoolean()
  @IsOptional()
  has_separate_above?: boolean;
}

export class VacationAvailableDays {
  @IsUUID()
  @IsNotEmpty()
  vacation_type: string;

  @IsString()
  @IsNotEmpty()
  type_name: string;

  @IsInt()
  @IsNotEmpty()
  available_days: number;
}

export class VacationAvailableDaysResponse {
  @ValidateNested({ each: true })
  @Type(() => VacationAvailableDays)
  @IsArray()
  @IsNotEmpty()
  available_days_by_types: VacationAvailableDays[];

  @ValidateNested({ each: true })
  @Type(() => Unit)
  @IsArray()
  @IsNotEmpty()
  units: Unit[];
}

export class VacationScheduleMyDeadlineResponse {
  @IsUUID()
  @IsOptional()
  schedule_id?: string;

  @IsDateString()
  @IsOptional()
  deadline?: string;
}

export class VacationFavoritesResponse {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  event_ids: string[];

  @IsInt()
  @IsNotEmpty()
  total: number;

  @IsInt()
  @IsNotEmpty()
  max_allowed: number;
}

export class VacationExcludedEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsNotEmpty()
  first_name: string;

  @IsString()
  @IsOptional()
  middle_name?: string;

  @IsString()
  @IsNotEmpty()
  last_name: string;

  @IsString()
  @IsOptional()
  position?: string;

  @IsDateString()
  @IsOptional()
  dismissed_at?: string;
}

export class VacationScheduleStart_options {
  @IsDateString()
  @IsOptional()
  include_employee_hired_before?: string;

  @IsDateString()
  @IsOptional()
  exclude_disabled_contract_until?: string;

  @IsDateString()
  @IsOptional()
  exclude_unpayed_vacation_until?: string;

  @IsDateString()
  @IsOptional()
  exclude_maternity_leave_until?: string;

  @ValidateNested({ each: true })
  @Type(() => VacationExcludedEmployee)
  @IsArray()
  @IsNotEmpty()
  exclude_employees: VacationExcludedEmployee[];
}

export class VacationGroupApprover {
  @IsString()
  @IsNotEmpty()
  group_name: string;
}

export class VacationManagerApprover {
  @IsIn(['legal', 'operational', 'functional', 'direct'])
  @IsString()
  @IsNotEmpty()
  type: 'legal' | 'operational' | 'functional' | 'direct';

  @IsInt()
  @IsOptional()
  approve_levels?: number;
}

export class VacationEntityApprover {
  @IsDateString()
  @IsNotEmpty()
  deadline: string;

  @IsBoolean()
  @IsNotEmpty()
  can_edit: boolean;

  @ValidateNested()
  @Type(() => VacationManagerApprover)
  @IsOptional()
  manager_approve?: VacationManagerApprover;

  @ValidateNested()
  @Type(() => VacationGroupApprover)
  @IsOptional()
  group_approve?: VacationGroupApprover;
}

export class MinWeekendDaysValidator {
  @IsInt()
  @IsNotEmpty()
  working_days: number;

  @IsInt()
  @IsNotEmpty()
  min_weekend_days: number;
}

export class DurationValidator {
  @IsInt()
  @IsNotEmpty()
  days: number;

  @IsIn(['calendar', 'work'])
  @IsString()
  @IsNotEmpty()
  unit: 'calendar' | 'work';
}

export class VacationValidators {
  @IsBoolean()
  @IsNotEmpty()
  must_schedule_all_days: boolean;

  @IsBoolean()
  @IsNotEmpty()
  must_schedule_14days: boolean;

  @IsInt()
  @IsOptional()
  days_must_be_divisible_by?: number;

  @ValidateNested()
  @Type(() => DurationValidator)
  @IsOptional()
  min_duration?: DurationValidator;

  @ValidateNested()
  @Type(() => DurationValidator)
  @IsOptional()
  max_duration?: DurationValidator;

  @IsBoolean()
  @IsNotEmpty()
  limit_to_calculated_days: boolean;

  @IsBoolean()
  @IsNotEmpty()
  min_5_days_between_not_workdays: boolean;

  @IsBoolean()
  @IsOptional()
  weekend_exclusion?: boolean;

  @IsBoolean()
  @IsOptional()
  holydays_exclusion?: boolean;

  @IsBoolean()
  @IsOptional()
  min_specific_weekend_days?: boolean;

  @IsBoolean()
  @IsOptional()
  working_day?: boolean;

  @ValidateNested()
  @Type(() => MinWeekendDaysValidator)
  @IsOptional()
  min_weekend_days?: MinWeekendDaysValidator;
}

export class VacationScheduleEvent_stats {
  @IsInt()
  @IsNotEmpty()
  total: number;

  @IsInt()
  @IsNotEmpty()
  new: number;

  @IsInt()
  @IsNotEmpty()
  on_approve: number;

  @IsInt()
  @IsNotEmpty()
  send_to_1c: number;

  @IsInt()
  @IsNotEmpty()
  canceled: number;

  @IsInt()
  @IsNotEmpty()
  completed: number;
}

export class VacationSchedule {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn(['new', 'starting', 'active', 'completed'])
  @IsString()
  @IsNotEmpty()
  status: 'new' | 'starting' | 'active' | 'completed';

  @ValidateNested()
  @Type(() => VacationScheduleEvent_stats)
  @IsNotEmpty()
  event_stats: VacationScheduleEvent_stats;

  @IsInt()
  @IsNotEmpty()
  year: number;

  @IsDateString()
  @IsNotEmpty()
  active_to: string;

  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsBoolean()
  @IsNotEmpty()
  send_to_1c: boolean;

  @IsString()
  @IsOptional()
  company_comment?: string;

  @IsBoolean()
  @IsNotEmpty()
  allow_employee_comment: boolean;

  @IsBoolean()
  @IsNotEmpty()
  add_previous_vacation: boolean;

  @ValidateNested()
  @Type(() => VacationValidators)
  @IsNotEmpty()
  validators: VacationValidators;

  @IsNotEmpty()
  validator_vacation_types: object;

  @IsDateString()
  @IsNotEmpty()
  employee_deadline: string;

  @ValidateNested({ each: true })
  @Type(() => VacationEntityApprover)
  @IsArray()
  @IsNotEmpty()
  approvers: VacationEntityApprover[];

  @IsIn(['unit', 'all'])
  @IsString()
  @IsOptional()
  teammate_access?: 'unit' | 'all';

  @ValidateNested()
  @Type(() => VacationScheduleStart_options)
  @IsNotEmpty()
  start_options: VacationScheduleStart_options;

  @IsDateString()
  @IsNotEmpty()
  created_at: string;
}

export class CreateVacationScheduleResponse {
  @IsInt()
  @IsNotEmpty()
  success: number;

  @IsInt()
  @IsNotEmpty()
  errors: number;

  @ValidateNested({ each: true })
  @Type(() => VacationSchedule)
  @IsArray()
  @IsNotEmpty()
  items: VacationSchedule[];
}

export class CompanyVacationSchedule {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsInt()
  @IsNotEmpty()
  year: number;

  @IsIn(['new', 'starting', 'active', 'completed'])
  @IsString()
  @IsNotEmpty()
  status: 'new' | 'starting' | 'active' | 'completed';

  @IsBoolean()
  @IsNotEmpty()
  favorites_allowed: boolean;
}

export class CompanyVacationSchedulesListItem {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsNotEmpty()
  company_name: string;

  @IsBoolean()
  @IsOptional()
  has_actions?: boolean;

  @ValidateNested({ each: true })
  @Type(() => CompanyVacationSchedule)
  @IsArray()
  @IsNotEmpty()
  vacation_schedules: CompanyVacationSchedule[];
}

export class VacationScheduleListResponse {
  @ValidateNested({ each: true })
  @Type(() => CompanyVacationSchedulesListItem)
  @IsArray()
  @IsNotEmpty()
  companies: CompanyVacationSchedulesListItem[];
}

export class AdminVacationSchedulesListItem {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsNotEmpty()
  company_name: string;

  @IsBoolean()
  @IsOptional()
  has_actions?: boolean;

  @ValidateNested({ each: true })
  @Type(() => VacationSchedule)
  @IsArray()
  @IsNotEmpty()
  vacation_schedules: VacationSchedule[];
}

export class VacationScheduleAdminListResponse {
  @ValidateNested({ each: true })
  @Type(() => AdminVacationSchedulesListItem)
  @IsArray()
  @IsNotEmpty()
  companies: AdminVacationSchedulesListItem[];
}

export class VacationAdminCompany {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsBoolean()
  @IsNotEmpty()
  has_schedule_for_next_year: boolean;
}

export class VacationAdminCompaniesListResponse {
  @ValidateNested({ each: true })
  @Type(() => VacationAdminCompany)
  @IsArray()
  @IsNotEmpty()
  companies: VacationAdminCompany[];
}

export class VacationFavoritesRequest {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  event_ids: string[];
}

export class StartVacationScheduleRequest {
  @IsDateString()
  @IsOptional()
  include_employee_hired_before?: string;

  @IsDateString()
  @IsOptional()
  exclude_disabled_contract_until?: string;

  @IsDateString()
  @IsOptional()
  exclude_unpayed_vacation_until?: string;

  @IsDateString()
  @IsOptional()
  exclude_maternity_leave_until?: string;

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsOptional()
  exclude_employees?: string[];
}

export class CreateVacationScheduleRequest {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  company_ids: string[];

  @IsInt()
  @IsNotEmpty()
  year: number;

  @IsDateString()
  @IsNotEmpty()
  active_to: string;

  @IsBoolean()
  @IsOptional()
  send_to_1c?: boolean;

  @IsString()
  @IsOptional()
  company_comment?: string;

  @IsBoolean()
  @IsNotEmpty()
  allow_employee_comment: boolean;

  @IsBoolean()
  @IsNotEmpty()
  add_previous_vacation: boolean;

  @ValidateNested()
  @Type(() => VacationValidators)
  @IsNotEmpty()
  validators: VacationValidators;

  @IsOptional()
  validator_vacation_types?: object;

  @IsDateString()
  @IsNotEmpty()
  employee_deadline: string;

  @ValidateNested({ each: true })
  @Type(() => VacationEntityApprover)
  @IsArray()
  @IsOptional()
  approvers?: VacationEntityApprover[];

  @IsIn(['unit', 'all'])
  @IsString()
  @IsOptional()
  teammate_access?: 'unit' | 'all';
}

@injectable()
export class VacationsService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private query: QueryService,
  ) {}

  async vacationAvailableDays({
    params,
  }: {
    params: VacationAvailableDaysParams;
  }) {
    await Promise.all([
      this.validator.validateOrReject(params, VacationAvailableDaysParams),
    ]);

    let requestPath = '/vacation/available_days/{employee_id}';

    const paramsKeys = Object.keys(
      params,
    ) as (keyof VacationAvailableDaysParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.get(requestPath, {}, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      VacationAvailableDaysResponse,
    );

    return response;
  }

  async vacationScheduleMyDeadline({
    params,
  }: {
    params: VacationScheduleMyDeadlineParams;
  }) {
    await Promise.all([
      this.validator.validateOrReject(params, VacationScheduleMyDeadlineParams),
    ]);

    let requestPath = '/vacation/schedule/{company_id}/my_deadline';

    const paramsKeys = Object.keys(
      params,
    ) as (keyof VacationScheduleMyDeadlineParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.get(requestPath, {}, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      VacationScheduleMyDeadlineResponse,
    );

    return response;
  }

  async vacationScheduleFavorites({
    params,
    body,
  }: {
    params: VacationScheduleFavoritesParams;
    body: VacationFavoritesRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(params, VacationScheduleFavoritesParams),
      this.validator.validateOrReject(body, VacationFavoritesRequest),
    ]);

    let requestPath = '/vacation/schedule/{schedule_id}/favorites';

    const paramsKeys = Object.keys(
      params,
    ) as (keyof VacationScheduleFavoritesParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.delete(requestPath, body, {});

    return result;
  }

  async vacationScheduleStart({
    params,
    body = {},
  }: {
    params: VacationScheduleStartParams;
    body?: StartVacationScheduleRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(params, VacationScheduleStartParams),
      this.validator.validateOrReject(body, StartVacationScheduleRequest),
    ]);

    let requestPath = '/vacation/schedule/{schedule_id}/start';

    const paramsKeys = Object.keys(
      params,
    ) as (keyof VacationScheduleStartParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.post(requestPath, body, { withSide: true });

    return result;
  }

  async vacationSchedule({ params }: { params: VacationScheduleParams }) {
    await Promise.all([
      this.validator.validateOrReject(params, VacationScheduleParams),
    ]);

    let requestPath = '/vacation/schedule/{schedule_id}';

    const paramsKeys = Object.keys(params) as (keyof VacationScheduleParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.delete(requestPath, {}, { withSide: true });

    return result;
  }

  async getVacationScheduleList() {
    let requestPath = '/vacation/schedules';

    const result = await this.http.get(requestPath, {}, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      VacationScheduleListResponse,
    );

    return response;
  }

  async getVacationScheduleAdminList() {
    let requestPath = '/vacation/admin_schedules';

    const result = await this.http.get(requestPath, {}, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      VacationScheduleAdminListResponse,
    );

    return response;
  }

  async getVacationAdminCompaniesList() {
    let requestPath = '/vacation/admin_companies';

    const result = await this.http.get(requestPath, {}, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      VacationAdminCompaniesListResponse,
    );

    return response;
  }
}
