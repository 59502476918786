import { injectable } from 'inversify';

import type { LinearFlowNode } from '@vk-hr-tek/core/flow';

import type { EventNodeFlow, NodeAction } from '@app/gen/events';

import { EventsFlowCommonMapper } from './events.flow-common.mapper';

const REJECT_ACTION_TYPE = ['return'];

const CUSTOM_ACTION_TYPE = [
  'system_booking_hook',
  'system_booking_approve',
  'system_booking_trip_create',
  'system_booking_trip_change',
  'system_booking_trip_ordering',
  'system_booking_limits_exceeded_approve',
  'system_booking_trip_limit_approved',
  'system_booking_trip_cancel',
];

@injectable()
export class EventsLinearFlowMapper {
  constructor(private eventsFlowHelpersMapper: EventsFlowCommonMapper) {}

  private nodes: EventNodeFlow[];

  private constrictions: Set<string>;

  private action: NodeAction;

  private setNodeAction(actions: NodeAction[]): void {
    const action = actions.find(({ is_primary }) => is_primary);

    if (action) {
      this.action = action;
    }
  }

  public init(nodes: EventNodeFlow[]) {
    this.nodes = nodes.map(({ actions, transitions, ...node }) => ({
      ...node,
      actions: actions.filter(({ type }) => !REJECT_ACTION_TYPE.includes(type)),
      transitions: transitions.filter(
        ({ action_type: type }) => !REJECT_ACTION_TYPE.includes(type),
      ),
    }));

    const found = new Set<string>();
    this.constrictions = new Set<string>();

    for (const { transitions } of nodes) {
      for (const { to_node_id: toNodeId } of transitions) {
        if (found.has(toNodeId)) {
          this.constrictions.add(toNodeId);
        } else {
          found.add(toNodeId);
        }
      }
    }
  }

  private createRegularNode(
    node: EventNodeFlow,
    stage: number,
  ): LinearFlowNode {
    const state = this.eventsFlowHelpersMapper.mapStatus(node.status);
    const allExecutors =
      state === 'active' ||
      state === 'skipped' ||
      state === 'rejected' ||
      !node.activity_date;

    const executor = allExecutors
      ? this.eventsFlowHelpersMapper.getExecutorsData(this.action)
      : { position: node.actor?.role, name: node.actor?.fio ?? '' };

    const nodeTitle = node.custom_state?.name
      ? node.custom_state.name
      : this.eventsFlowHelpersMapper.getNodeTitle(this.action);

    const customActionTitles = CUSTOM_ACTION_TYPE.includes(this.action.type);

    const dataTitle = customActionTitles ? node.name : nodeTitle;

    return {
      nodeId: node.node_id,
      tooltip: this.eventsFlowHelpersMapper.getTooltip(node, this.action),
      status: node.status,
      title: dataTitle,
      nodeType: 'single',
      executor,
      isOptional: node.is_optional,
      stage,
    };
  }

  private createStartNode(node: EventNodeFlow, stage: number): LinearFlowNode {
    return {
      nodeId: node.node_id,
      title: 'Старт',
      isOptional: false,
      executor: { position: node.actor?.role ?? '' },
      tooltip: {
        title: 'Старт',
        date: this.eventsFlowHelpersMapper.dateFormat(node.activity_date),
      },
      nodeType: 'single',
      status: node.status,
      stage,
    };
  }

  private createEndNode(node: EventNodeFlow, stage: number): LinearFlowNode {
    const processStatus =
      node.name === 'Отказ от подписания' ? 'canceled' : node.status;

    const processName =
      node.name === 'Отказ от подписания' ? node.name : 'Завершено';

    return {
      nodeId: node.node_id,
      title: processName,
      isOptional: false,
      nodeType: 'single',
      tooltip: {
        title: processName,
        date: this.eventsFlowHelpersMapper.dateFormat(node.activity_date),
      },
      status: processStatus,
      executor: { position: node.actor?.role ?? '' },
      stage,
    };
  }

  private getLinearNodeData(
    node: EventNodeFlow | LinearFlowNode,
    start: boolean,
    stage: number,
  ) {
    if ('nodeId' in node) {
      return {
        ...node,
        stage,
      };
    }

    this.setNodeAction(node.actions);

    if (start) {
      return this.createStartNode(node, stage);
    }

    if (this.action.type === 'system' && !node.transitions?.length) {
      return this.createEndNode(node, stage);
    }

    return this.createRegularNode(node, stage);
  }

  private handleSingleTransition(
    node: EventNodeFlow,
    visited: Set<string>,
    sortedNodes: (EventNodeFlow | LinearFlowNode)[],
  ) {
    node.transitions.forEach((transition) => {
      const nextNode = this.nodes.find(
        ({ node_id }) => node_id === transition.to_node_id,
      );

      if (!nextNode || visited.has(nextNode.node_id)) {
        return;
      }

      this.depthSearch(nextNode, visited, sortedNodes);
    });
  }

  private handleParallelTransitions(
    node: EventNodeFlow,
    visited: Set<string>,
    sortedNodes: (EventNodeFlow | LinearFlowNode)[],
  ) {
    const oneOfTransitionsNode = this.nodes.find(
      ({ node_id }) => node_id === node.transitions[0].to_node_id,
    );

    if (!oneOfTransitionsNode) return;

    const {
      node_id: nodeId,
      status,
      is_optional: isOptional,
      transitions,
    } = oneOfTransitionsNode;

    if (!visited.has(nodeId)) {
      sortedNodes.push({
        nodeId,
        status,
        isOptional,
        title: 'Параллельные этапы',
        stage: 0,
        nodeType: 'parallel',
      });
      visited.add(nodeId);
    }

    const nextNode = this.nodes.find(
      ({ node_id }) => node_id === transitions[0].to_node_id,
    );

    if (!nextNode || visited.has(nextNode.node_id)) {
      return; // Пропускаем, если узел уже посещен
    }

    this.depthSearch(nextNode, visited, sortedNodes);
  }

  private handleForkTransitions(
    node: EventNodeFlow,
    visited: Set<string>,
    sortedNodes: (EventNodeFlow | LinearFlowNode)[],
    transitionNodes: EventNodeFlow[],
  ) {
    const isForkUnactive = transitionNodes.every(
      ({ status }) => status === 'inactive',
    );

    if (isForkUnactive) {
      if (!visited.has(`fork-node-${node.node_id}`)) {
        sortedNodes.push({
          nodeId: `fork-node-${node.node_id}`,
          status: 'inactive',
          isOptional: node.is_optional,
          title: 'Этап с развилкой',
          stage: 0,
          nodeType: 'fork',
        });
      }
    } else {
      const neededStatuses = ['active', 'skipped', 'finished'];

      const forkActiveNode = transitionNodes.find(({ status }) =>
        neededStatuses.includes(status),
      );

      if (!forkActiveNode) {
        return;
      }

      const nextNodeAfterActiveFork = this.nodes.find(
        ({ node_id }) => node_id === forkActiveNode.transitions[0]?.to_node_id,
      );

      if (!visited.has(forkActiveNode.node_id)) {
        visited.add(forkActiveNode.node_id);
        sortedNodes.push(forkActiveNode);
      }

      if (
        nextNodeAfterActiveFork &&
        !visited.has(nextNodeAfterActiveFork.node_id)
      ) {
        this.depthSearch(nextNodeAfterActiveFork, visited, sortedNodes);
      }
    }
  }

  // Рекурсивный обход узлов (depthSearch)
  private depthSearch(
    node: EventNodeFlow,
    visited: Set<string>,
    sortedNodes: (EventNodeFlow | LinearFlowNode)[],
  ) {
    if (!node || visited.has(node.node_id)) return;

    visited.add(node.node_id);
    sortedNodes.push(node);

    if (node.transitions.length > 1) {
      const idsForTransition = node.transitions.map(
        ({ to_node_id }) => to_node_id,
      );

      const transitionsNodes = this.nodes.filter(({ node_id }) =>
        idsForTransition.includes(node_id),
      );

      const idForCheckParallelNode =
        transitionsNodes[0].transitions[0]?.to_node_id;

      const isParallelNode = transitionsNodes.every(({ transitions }) =>
        transitions.some(
          ({ to_node_id }) => to_node_id === idForCheckParallelNode,
        ),
      );

      if (isParallelNode) {
        this.handleParallelTransitions(node, visited, sortedNodes);
      } else {
        this.handleForkTransitions(
          node,
          visited,
          sortedNodes,
          transitionsNodes,
        );
      }
    } else {
      this.handleSingleTransition(node, visited, sortedNodes);
    }
  }

  private sortNodesByTransitions(startNodeId: string) {
    const sortedNodes: EventNodeFlow[] = [];
    const visited: Set<string> = new Set();

    const startNode = this.nodes.find(({ node_id }) => node_id === startNodeId);

    if (!startNode) return;

    this.depthSearch(startNode, visited, sortedNodes);

    return sortedNodes;
  }

  public getLinearFlowTree(startId: string): LinearFlowNode[] {
    const sortedNodes = this.sortNodesByTransitions(startId);

    if (!sortedNodes) return [];

    const isCanceledNode = sortedNodes.some(
      ({ status }) => status === 'canceled',
    );

    if (isCanceledNode) {
      const cancelNodeIndex = sortedNodes.findIndex(
        ({ status }) => status === 'canceled',
      );

      const sliceSortedNodes = sortedNodes.slice(0, cancelNodeIndex + 1);

      return sliceSortedNodes.map((node, index) =>
        this.getLinearNodeData(node, !index, index + 1),
      );
    }

    return sortedNodes.map((node, index) =>
      this.getLinearNodeData(node, !index, index + 1),
    );
  }
}
