import { IsArray, IsNotEmpty, IsUUID, IsOptional } from 'class-validator';

import { DateRange } from '@vk-hr-tek/app/app/gen/users';

export class CreateSubstituteDto {
  @IsUUID()
  @IsNotEmpty()
  substituteId: string;

  @IsUUID()
  @IsNotEmpty()
  employeeId: string;

  @IsArray()
  @IsNotEmpty()
  eventTypes: string[];

  @IsArray()
  @IsOptional()
  dates?: DateRange[];
}
