import { FORM_ERROR } from 'final-form';
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createAction,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { QueryService } from '@vk-hr-tek/core/query';
import {
  UnitProcessor,
  UnitService,
  UnitType,
  UnitTypeOption,
  UnitNodeLabeled,
} from '@vk-hr-tek/core/units';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { showNotification } from '@vk-hr-tek/core/notifications';
import { History } from '@vk-hr-tek/core/history';

import {
  CreateEventCompanyItem,
  CreateEventResponse as CreateResponse,
  EventTypeItem,
  EventBatchItem,
  CreateEventTypeOptions,
  CreateEventCommonOptionsResponse,
  EmployeeItem,
  CreateEventResponse,
  EventTypeGroup,
  EventBatchEmployeeCountResponse,
} from '@app/gen/events';
import { ThunkExtra, ThunkValuesAndActions } from '@app/store';

import { CreationService } from '../../services';
import {
  CreateEventDto,
  GetEventBatchOptionsDto,
  CreateEventBatchDto,
  GetCreateEventTypeOptionsDto,
  GetCreatableEventTypesForEmployeeDto,
  GetCreateOptionsCompanyDto,
  GetOptionsEmployees,
  CreateEventFromButtonDto,
  GetCanCreateBatchEventOrEventForAnotherEmployeeDto,
  GetCreateEventBatchEmployeesCountDto,
} from '../../dto';
import { CreationState } from '../creation.state';
import { EventTypeItemWithGroup } from '../../types';

const filterItems = (eventTypes: (EventTypeGroup | EventTypeItem)[]) => {
  const eventTypeItems = eventTypes.filter(
    (event): event is EventTypeItemWithGroup => {
      return event.type === 'item';
    },
  );

  const eventTypeGroups = eventTypes.filter(
    (event): event is EventTypeGroup => {
      return event.type === 'group';
    },
  );

  eventTypeGroups.forEach((event) => {
    if (event.items) {
      event.items.forEach((item) =>
        eventTypeItems.unshift({
          ...item,
          group: event.label,
        }),
      );
    }
  });

  return eventTypeItems;
};

export const setCreateItems = createAction<CreateEventCompanyItem[]>(
  'events/setCreateItems',
);

export const setCreateEventTypes = createAction<EventTypeItem[]>(
  'events/setCreateEventTypes',
);

export const createEvent = createAsyncThunk<
  CreateResponse,
  ThunkValuesAndActions<CreateEventDto>,
  ThunkExtra
>(
  'createEvent',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const creationService = inject(CreationService);
      const result = await creationService.createEvent(values);

      actions.resolve(result.event_id);

      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventCompany = createAsyncThunk<
  CreateResponse,
  ThunkValuesAndActions<CreateEventDto>,
  ThunkExtra
>(
  'createEventCompany',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const creationService = inject(CreationService);
      const result = await creationService.createEvent(values);

      actions.resolve(result.event_id);

      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventFromButton = createAsyncThunk<
  CreateEventResponse,
  ThunkValuesAndActions<CreateEventFromButtonDto>,
  ThunkExtra
>(
  'createEventFromButton',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const creationService = inject(CreationService);
      const result = await creationService.createEvent(values);

      actions.resolve(result.event_id);

      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreateEventTypeOptions = createAsyncThunk<
  CreateEventTypeOptions,
  GetCreateEventTypeOptionsDto,
  ThunkExtra
>(
  'createEvent/getCreateEventTypeOptions',
  async (
    getCreateEventTypeOptionsDto,
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const {
        creationEvent: { createEventTypeOptions },
      } = getState();
      const { event_type_id: eventTypeId } = getCreateEventTypeOptionsDto;
      const optionById = createEventTypeOptions.options[eventTypeId];

      if (optionById) {
        return {
          assignable_roles: optionById.assignableRoles,
          copy_documents: optionById.copyDocuments,
          copy_attributes: optionById.copyAttributes,
        };
      }

      const service = inject(CreationService);
      const result = await service.getCreateEventTypeOptions(
        getCreateEventTypeOptionsDto,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getBatchOptionsCompany = createAsyncThunk<
  {
    company: { id: string; name: string };
    eventType: { id: string; name: string; documentInfoRequired: boolean };
    existingBatches: EventBatchItem[];
    unitTypeOptions?: UnitTypeOption[];
    unitType: UnitType | null;
    rootUnit: UnitNodeLabeled | null;
    employeesLoadingError: boolean;
  },
  undefined,
  ThunkExtra
>(
  'createEvent/getBatchOptionsCompany',
  async (_, { rejectWithValue, getState, dispatch, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const batchOptions = inject(QueryService).parse(
        history.location.search,
      ) as GetEventBatchOptionsDto;

      const validator = inject(ValidationService);

      await validator.validateOrReject(batchOptions, GetEventBatchOptionsDto);

      const service = inject(CreationService);
      const unitService = inject(UnitService);
      const unitProcessor = inject(UnitProcessor);
      const state = getState().creationEvent.creation;

      let items = state.items;

      dispatch(
        getCreateEventTypeOptions({
          event_type_id: batchOptions.eventTypeId,
          parent_event_id: batchOptions.parentEventId,
        }),
      );

      if (!items || !items.length) {
        const res = await service.getCreateOptionsCompany({
          withEventTypes: true,
        });

        items = res.items;

        dispatch(setCreateItems(items));
      }

      const selectedCompany = items.find(
        ({ company_id }) => company_id === batchOptions.companyId,
      );

      if (!selectedCompany) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      let eventTypes = state.eventTypes;

      if (!eventTypes || !eventTypes.length) {
        const res = await service.getCreateBatchEventTypes({
          companyId: batchOptions.companyId,
        });

        eventTypes = filterItems(res.event_types);

        dispatch(setCreateEventTypes(eventTypes));
      }

      const selectedEventType = eventTypes.find(
        (eventType) => eventType.id === batchOptions.eventTypeId,
      );

      if (!selectedEventType) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      const { event_batches: existingBatches } =
        await service.getExistingBatchEvents(batchOptions);

      const { types } = await unitService.getUnitTypes(batchOptions);

      let rootUnit = null;
      let unitType = null;

      if (Array.isArray(types) && types.length) {
        unitType = types[0].type as UnitType;
        const unitTree = await unitService.getUnitTree({
          companyId: batchOptions.companyId,
          unitType,
        });

        rootUnit = unitTree.root_unit ?? null;
      }

      return {
        company: {
          id: selectedCompany.company_id,
          name: selectedCompany.company_name,
        },
        eventType: {
          id: selectedEventType.id,
          name: selectedEventType.name,
          documentInfoRequired: !!selectedEventType.document_info_required,
        },
        existingBatches: existingBatches || [],
        unitTypeOptions: types.map(
          ({ type: value, name: label }) =>
            ({
              value,
              label,
            } as UnitTypeOption),
        ),
        unitType,
        rootUnit: rootUnit ? unitProcessor.processUnitsTree(rootUnit) : null,
        employeesLoadingError: false,
      };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getUpdateOfBatchOptionsCompanyForUnitType = createAsyncThunk<
  {
    rootUnit: UnitNodeLabeled | null;
  },
  { unitType: UnitType; companyId: string },
  ThunkExtra
>(
  'createEvent/updateUnitType',
  async ({ unitType, companyId }, { rejectWithValue, extra: { inject } }) => {
    try {
      const unitService = inject(UnitService);
      const unitProcessor = inject(UnitProcessor);

      const { root_unit: rootUnit = null } = await unitService.getUnitTree({
        companyId,
        unitType,
      });

      return {
        rootUnit: rootUnit ? unitProcessor.processUnitsTree(rootUnit) : null,
      };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createBatchEvents = createAsyncThunk<
  void,
  {
    values: CreateEventBatchDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'createEvent/createBatchEvents',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(CreationService).createBatchEvents(values);

      const notificationsText = values.allEmployees
        ? 'Заявки на всех сотрудников успешно созданы'
        : `Заявки (${values.employees.length}) успешно созданы!`;

      dispatch(showNotification(notificationsText));

      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCanCreateBatchEventOrEventForAnotherEmployee = createAsyncThunk<
  CreateEventCommonOptionsResponse,
  GetCanCreateBatchEventOrEventForAnotherEmployeeDto,
  ThunkExtra
>(
  'createEvent/getCanCreateBatchEventOrEventForAnotherEmployee',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(CreationService);

      const result =
        await service.getCanCreateBatchEventOrEventForAnotherEmployee(dto);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getEventCreateCompaniesOptions = createAsyncThunk<
  CreateEventCompanyItem[],
  GetCreateOptionsCompanyDto,
  ThunkExtra
>(
  'createEvent/getEventCreateCompaniesOptions',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(CreationService);
      const res = await service.getCreateOptionsCompany(dto);

      return res.items;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreateEventTypesOptions = createAsyncThunk<
  EventTypeItem[],
  GetOptionsEmployees,
  ThunkExtra
>(
  'createEvent/getCreateEventTypesOptions',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(CreationService);
      const res = await service.getCreateBatchEventTypes(dto);

      const eventTypeItems = res.event_types.filter(
        (event): event is EventTypeItemWithGroup => event.type === 'item',
      );

      const eventTypeGroups = res.event_types.filter(
        (event): event is EventTypeGroup => event.type === 'group',
      );

      eventTypeGroups.forEach((event) => {
        event.items.forEach((item) =>
          eventTypeItems.unshift({
            ...item,
            group: event.label,
          }),
        );
      });

      return eventTypeItems;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreatableEventTypesForEmployee = createAsyncThunk<
  EventTypeItem[],
  GetCreatableEventTypesForEmployeeDto,
  ThunkExtra
>(
  'createEvent/getCreatableEventTypesForEmployee',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(CreationService);
      const res = await service.getCreatableEventTypesForEmployee(dto);

      const eventTypeItems = res.event_types.filter(
        (event): event is EventTypeItemWithGroup => event.type === 'item',
      );

      const eventTypeGroups = res.event_types.filter(
        (event): event is EventTypeGroup => event.type === 'group',
      );

      eventTypeGroups.forEach((event) => {
        event.items.forEach((item) =>
          eventTypeItems.unshift({
            ...item,
            group: event.label,
          }),
        );
      });

      return eventTypeItems;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreationUserEmployees = createAsyncThunk<
  EmployeeItem[],
  { companyId: string },
  ThunkExtra
>(
  'createEvent/getCreationUserEmployees',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(CreationService);
      const res = await service.getEventEmployees({
        companyId: dto.companyId,
        forAnother: false,
        limit: 2,
        offset: 0,
      });

      return res.employees;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const checkForEmployeesCount = createAsyncThunk<
  EmployeeItem[],
  { companyId: string; forAnother: boolean },
  ThunkExtra
>(
  'createEvent/checkForEmployeesCount',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(CreationService);

      const res = await service.getEventEmployees({
        companyId: dto.companyId,
        forAnother: dto.forAnother,
        limit: 2,
        offset: 0,
      });

      return res.employees;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreateBatchEmployeesCount = createAsyncThunk<
  EventBatchEmployeeCountResponse,
  GetCreateEventBatchEmployeesCountDto,
  ThunkExtra
>(
  'createEvent/getCreateBatchEmployeesCount',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(CreationService);

      const res = await service.getCreateBatchEmployeesCount({
        eventTypeId: dto.eventTypeId,
        unitIds: dto.unitIds,
        parentEventId: dto.parentEventId,
      });

      return res;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventWithEventTypeGroup = createAsyncThunk<
  CreateEventCompanyItem[] | void,
  undefined,
  ThunkExtra
>(
  'createEvent/createEventWithEventTypeGroup',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const { event_type_group: eventTypeGroup } = inject(QueryService).parse(
        history.location.search,
      );
      const service = inject(CreationService);

      const companies = await service.getCreateOptionsCompany({
        withEventTypes: true,
      });

      if (companies.items.length === 1) {
        const companyId = companies.items[0].company_id;
        const employees = await service.getEventEmployees({
          companyId: companyId,
          forAnother: false,
          limit: 2,
          offset: 0,
        });

        if (employees.employees.length === 1) {
          const employeeId = employees.employees[0].id;
          const eventTypes = await service.getCreatableEventTypesForEmployee({
            eventTypeGroup,
            id: employeeId,
          });

          if (eventTypes.event_types.length === 1) {
            const eventTypeId = eventTypes.event_types[0].id;

            dispatch(
              createEvent({
                values: { employeeId, eventTypeId } as CreateEventDto,
                actions: { resolve: () => {}, reject: () => {} },
              }),
            );
          }
        }
      } else {
        return companies.items;
      }
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const creationActionReducers = (
  builder: ActionReducerMapBuilder<CreationState>,
) => {
  builder.addCase(setCreateItems, (state, action) => {
    state.creation.items = action.payload;
  });

  builder.addCase(setCreateEventTypes, (state, action) => {
    state.creation.eventTypes = action.payload;
  });

  builder.addCase(getCreateEventTypeOptions.pending, (state) => {
    state.createEventTypeOptions.status = 'loading';
    state.createEventTypeOptions.error = null;
  });
  builder.addCase(
    getCreateEventTypeOptions.fulfilled,
    (state, { payload, meta }) => {
      state.createEventTypeOptions.status = 'complete';
      state.createEventTypeOptions.options[meta.arg.event_type_id] = {
        ...(state.createEventTypeOptions.options[meta.arg.event_type_id] || {}),
        assignableRoles: payload.assignable_roles,
        copyDocuments: payload.copy_documents,
        copyAttributes: payload.copy_attributes,
      };
      state.createEventTypeOptions.error = null;
    },
  );
  builder.addCase(
    getCreateEventTypeOptions.rejected,
    (state, { payload, error, meta }) => {
      if (!meta.aborted) {
        state.createEventTypeOptions.status = 'failed';
        state.createEventTypeOptions.error =
          payload ||
          ({
            info: (error && error.message) || 'Что-то пошло не так',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError);
      }
    },
  );

  builder.addCase(getBatchOptionsCompany.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.selected = null;
    state.creation.error = null;
  });
  builder.addCase(getBatchOptionsCompany.fulfilled, (state, { payload }) => {
    state.creation.status = 'complete';
    state.creation.selected = payload;
    state.creation.error = null;
  });
  builder.addCase(
    getBatchOptionsCompany.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.selected = null;
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.pending,
    (state) => {
      state.creation.error = null;
      if (state.creation.selected) {
        state.creation.selected.rootUnit = null;
      }
    },
  );
  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.fulfilled,
    (state, { payload }) => {
      if (state.creation.selected) {
        const { rootUnit } = payload;
        state.creation.selected.rootUnit = rootUnit;
      }
      state.creation.error = null;
    },
  );

  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(createEvent.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(createEvent.fulfilled, (state) => {
    state.creation.status = 'complete';
    state.creation.error = null;
  });
  builder.addCase(createEvent.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });

  builder.addCase(createEventCompany.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(createEventCompany.fulfilled, (state) => {
    state.creation.status = 'complete';
    state.creation.error = null;
  });
  builder.addCase(createEventCompany.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });

  builder.addCase(getCreateBatchEmployeesCount.pending, (state) => {
    state.creation.allEmployees = 0;
    state.creation.availableEmployees = 0;
  });
  builder.addCase(
    getCreateBatchEmployeesCount.fulfilled,
    (state, { payload }) => {
      state.creation.allEmployees = payload.total;
      state.creation.availableEmployees = payload.allowed;
    },
  );
  builder.addCase(
    getCreateBatchEmployeesCount.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(getEventCreateCompaniesOptions.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(
    getEventCreateCompaniesOptions.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';
      state.creation.items = payload;
    },
  );

  builder.addCase(
    getEventCreateCompaniesOptions.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(getCreateEventTypesOptions.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(
    getCreateEventTypesOptions.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';
      state.creation.eventTypes = payload;
    },
  );
  builder.addCase(
    getCreateEventTypesOptions.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(getCreatableEventTypesForEmployee.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(
    getCreatableEventTypesForEmployee.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';
      state.creation.employeeEventTypes = payload;
    },
  );
  builder.addCase(
    getCreatableEventTypesForEmployee.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(getCreationUserEmployees.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(getCreationUserEmployees.fulfilled, (state, { payload }) => {
    state.creation.status = 'complete';
    state.creation.employees.list = payload;
  });
  builder.addCase(
    getCreationUserEmployees.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(
    getCanCreateBatchEventOrEventForAnotherEmployee.pending,
    (state) => {
      state.creation.status = 'loading';
      state.creation.error = null;
    },
  );
  builder.addCase(
    getCanCreateBatchEventOrEventForAnotherEmployee.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';
      state.creation.canCreateBatch = payload.has_batch;
      state.creation.canCreateForAnother = payload.creator_for_others;
    },
  );
  builder.addCase(
    getCanCreateBatchEventOrEventForAnotherEmployee.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(checkForEmployeesCount.pending, (state) => {
    state.creation.employees.status = 'loading';
  });
  builder.addCase(checkForEmployeesCount.fulfilled, (state, { payload }) => {
    state.creation.employees.status = 'complete';
    state.creation.employees.list = payload;
  });
  builder.addCase(
    checkForEmployeesCount.rejected,
    (state, { payload, error }) => {
      state.creation.employees.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(createEventWithEventTypeGroup.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(
    createEventWithEventTypeGroup.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';

      if (payload) {
        state.creation.items = payload;
      }
    },
  );
  builder.addCase(
    createEventWithEventTypeGroup.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
};
