import { IsNotEmpty, IsUUID, IsArray, IsOptional } from 'class-validator';

export class GetCreateEventBatchEmployeesCountDto {
  @IsUUID()
  @IsNotEmpty()
  eventTypeId: string;

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  unitIds: string[];

  @IsUUID()
  @IsOptional()
  parentEventId?: string;
}
