import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { inverted?: boolean; maxWidth?: number }>(
  (theme) => ({
    tooltip: ({ inverted, maxWidth }) => ({
      backgroundColor: inverted
        ? theme.tokens.colors.bg.light.primary
        : theme.tokens.colors.bg.dark.primary,
      color: inverted
        ? theme.tokens.colors.text.light.primary
        : theme.palette.common.white,
      fontSize: theme.tokens.typography.variant.caption.fontSize,
      lineHeight: theme.tokens.typography.variant.caption.lineHeight,
      padding: theme.tokens.spacing['12'],
      borderRadius: theme.tokens.radius.l,
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.px(maxWidth || 235),
        '&.MuiTooltip-tooltipPlacementBottom': {
          marginTop: theme.px(12),
        },
        '&.MuiTooltip-tooltipPlacementTop': {
          marginBottom: theme.px(12),
        },
        '&.MuiTooltip-tooltipPlacementLeft': {
          marginRight: theme.px(12),
        },
        '&.MuiTooltip-tooltipPlacementRight': {
          marginLeft: theme.px(12),
        },
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.px(maxWidth || 310),
      },
    }),
    arrow: ({ inverted }) => ({
      color: inverted
        ? theme.tokens.colors.bg.light.primary
        : theme.tokens.colors.bg.dark.primary,
    }),
  }),
);

export default useStyles;
