import React from 'react';

import { useTranslations } from '@vk-hr-tek/core/hooks';
import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Descriptions, DescriptionsItem } from '@vk-hr-tek/ui/Descriptions';
import { useInject } from '@vk-hr-tek/core/ioc';
import { FormatService } from '@vk-hr-tek/core/format';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';

import { DateRange } from '@app/gen/users';

interface DeleteSubstituteFormContentProps {
  employeeCompanyName: string;
  substituteName: string;
  eventType: string;
  dates: DateRange[];
}

export const DeleteSubstituteFormContent = ({
  employeeCompanyName,
  substituteName,
  eventType,
  dates,
}: DeleteSubstituteFormContentProps) => {
  const { t } = useTranslations();
  const formatService = useInject(FormatService);

  const formattedDates = dates.map(({ from_date, to_date }) =>
    formatService.toDateRangeWithDaysBetween(from_date, to_date),
  );

  return (
    <Box display="flex" flexDirection="column" height="100%" gap="24">
      <Box>
        <Typography variant="h5">Удаление заместителя</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="16">
        <Typography color="text.light.tertirary">
          {t('user.profile.substitutes.employeeDeleteConfirmationText')}
        </Typography>
        <Descriptions>
          <DescriptionsItem label="Юрлицо" value={employeeCompanyName} />
          <DescriptionsItem label="Заместитель" value={substituteName} />

          <DescriptionsItem label={eventType} value="Периоды">
            <Box display="flex" gap="8" flexWrap="wrap" alignItems="center">
              {formattedDates.map((date) => (
                <ColoredChip
                  key={date}
                  label={date}
                  color="darkgray"
                  size="extraSmall"
                />
              ))}
            </Box>
          </DescriptionsItem>
        </Descriptions>
      </Box>
    </Box>
  );
};
