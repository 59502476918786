import { createSelector } from '@reduxjs/toolkit';

import { Inject } from '@vk-hr-tek/core/ioc';

import { CreationMapper } from '../mappers';

import { CreationWithRootState as RootState } from './creation.state';

export const selectCreationItems = createSelector(
  [
    (state: RootState) => state.creationEvent.creation.items,
    (_: RootState, inject: Inject) => inject(CreationMapper),
  ],
  (items, format) => (items ? format.processCreateEventItem(items) : null),
);

export const selectCreationEventEmployeesStatus = (state: RootState) =>
  state.creationEvent.creation.employees.status;

export const selectCreationEventEmployees = (state: RootState) =>
  state.creationEvent.creation.employees.list;

export const selectCanCreateBatchEvent = (state: RootState) =>
  state.creationEvent.creation.canCreateBatch;

export const selectCreationEmployees = (state: RootState) =>
  state.creationEvent.creation.employees;

export const selectCreationPageStatus = (state: RootState) =>
  state.creationEvent.status;

export const selectCreationStatus = (state: RootState) =>
  state.creationEvent.creation.status;

export const selectCreationError = (state: RootState) =>
  state.creationEvent.creation.error;

export const selectCanCreateEventForAnotherEmployee = (state: RootState) =>
  state.creationEvent.creation.canCreateForAnother;

export const selectCreationEventTypes = (state: RootState) =>
  state.creationEvent.creation.eventTypes;

export const selectAssignableRoles = createSelector(
  [
    (state: RootState) => state.creationEvent.createEventTypeOptions.options,
    (_: RootState, inject: Inject) => inject(CreationMapper),
    (_: RootState, inject: Inject, { id }: { id: string; required: boolean }) =>
      id,
    (
      _: RootState,
      inject: Inject,
      { required }: { id: string; required: boolean },
    ) => required,
  ],
  (options, mapper, id, required) =>
    mapper.processAssignableRoles(
      required
        ? options[id]?.assignableRoles?.required
        : options[id]?.assignableRoles?.optional,
      id,
      required,
    ),
);

export const selectCreateEventTypeOptionsStatus = (state: RootState) =>
  state.creationEvent.createEventTypeOptions.status;

export const selectCopyDocuments = createSelector(
  [
    (state: RootState) => state.creationEvent.createEventTypeOptions.options,
    (_: RootState, inject: Inject, id: string) => id,
  ],
  (options, id) => options[id]?.copyDocuments || [],
);

export const selectCopyAttributes = createSelector(
  [
    (state: RootState) => state.creationEvent.createEventTypeOptions.options,
    (_: RootState, inject: Inject, id: string) => id,
  ],
  (options, id) => options[id]?.copyAttributes || [],
);

export const selectEventCreateSelectableOptions = (state: RootState) =>
  state.creationEvent.creation.selected;

export const selectEmployeeCreationEventTypes = (state: RootState) =>
  state.creationEvent.creation.employeeEventTypes;

export const selectEventBatchCreationAllEmployees = (state: RootState) =>
  state.creationEvent.creation.allEmployees;

export const selectEventBatchCreationAvailableEmployees = (state: RootState) =>
  state.creationEvent.creation.availableEmployees;
