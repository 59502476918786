import {
  IsNotEmpty,
  IsUUID,
  IsArray,
  IsOptional,
  IsInt,
  Min,
  IsString,
} from 'class-validator';
import { Type } from 'class-transformer';

export class GetCreateEventBatchEmployeesDto {
  @IsUUID()
  @IsNotEmpty()
  eventTypeId: string;

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  unitIds: string[];

  @IsUUID()
  @IsOptional()
  parentEventId?: string;

  @IsString()
  @IsOptional()
  query?: string;

  @Min(0)
  @Type(() => Number)
  @IsInt()
  @IsOptional()
  offset = 0;

  @Min(1)
  @Type(() => Number)
  @IsInt()
  @IsOptional()
  // eslint-disable-next-line no-magic-numbers
  limit = 50;
}
