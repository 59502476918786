import { IsString, IsNotEmpty, IsArray } from 'class-validator';

type SubstitutePeriodType = 'employeeAbsence' | 'personal';

export class Substitute {
  @IsString()
  @IsNotEmpty()
  typeOfPeriod: SubstitutePeriodType;

  @IsArray()
  @IsNotEmpty()
  employeeAbsence: string[];

  @IsString()
  @IsNotEmpty()
  dateFrom: string;

  @IsString()
  @IsNotEmpty()
  dateTo: string;
}
