import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { withPaddingBottom: boolean }>(
  ({ tokens }) => ({
    root: {
      overflow: 'hidden',
      paddingBottom: ({ withPaddingBottom }) =>
        withPaddingBottom ? tokens.spacing['56'] : 0,
      '& $endIcon': {
        fontSize: tokens.typography.variant.overline.fontSize,
      },
    },
    endIcon: {},
  }),
);
