/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { FieldProps, FieldRenderProps, useField } from 'react-final-form';
import { Collapse } from '@material-ui/core';

import { TextInput as Input } from '../../input';

import { Autorecognition } from './Autorecognition';

/**
 * @typedef {Function} ValidateType
 * @param {*} value - Значение поля для валидации
 * @param {Object} [allValues] - Все значения формы
 * @returns {(string|undefined)|Promise<string|undefined>} - Ошибка валидации или undefined
 */

type ValidateType = (
  value: any,
  allValues?: any,
) => (string | undefined) | Promise<string | undefined>;

/**
 * Компонент TextInput
 * @param {Object} props - Свойства компонента
 * @param {string} [props.label] - Метка поля
 * @param {string} props.name - Имя поля
 * @param {Function} [props.onChange] - Колбэк на изменение значения
 * @param {string} [props.type] - Тип поля ввода
 * @param {string} [props.mask] - Маска ввода
 * @param {string} [props.placeholder] - Подсказка для поля ввода
 * @param {boolean} [props.multiline=false] - Флаг для многострочного поля
 * @param {number} [props.rows=1] - Количество строк для многострочного поля
 * @param {boolean} [props.showError=true] - Флаг для отображения ошибки
 * @param {boolean} [props.required=false] - Флаг для обязательного поля
 * @param {React.ReactNode} [props.tooltip] - Всплывающая подсказка
 * @param {Function} [props.after] - Функция для отображения дополнительного контента после поля
 * @param {boolean} [props.disabled=false] - Флаг для отключения поля
 * @param {boolean} [props.loading=false] - Флаг для отображения состояния загрузки
 * @param {boolean} [props.clearable=false] - Флаг для очистки поля
 * @param {string} [props.recognizedValue] - Распознанное значение
 * @param {boolean} [props.isRecognitionUsedBefore=false] - Флаг использования распознавания ранее
 * @param {boolean} [props.validationWithoutTouch=false] - Флаг валидации без взаимодействия с полем
 * @param {string} [props.inputMode] - Режим ввода
 * @param {string} [props.autocomplete] - Атрибут автозаполнения
 * @param {string} [props.testId] - Идентификатор для тестирования
 * @param {ValidateType[]} [props.validateArray] - Массив валидаторов
 * @param {Object} [props.rest] - Дополнительные свойства поля
 * @returns {React.ReactNode} - Рендеринг поля ввода с автозаполнением
 */
const TextInput = ({
  label,
  name,
  onChange,
  type,
  mask,
  placeholder,
  multiline = false,
  rows = 1,
  maxRows,
  showError = true,
  required = false,
  tooltip,
  after,
  disabled = false,
  loading = false,
  clearable = false,
  recognizedValue,
  isRecognitionUsedBefore = false,
  validationWithoutTouch = false,
  inputMode,
  autocomplete,
  testId,
  validateArray,
  ...rest
}: { after?: (value: string | undefined) => React.ReactNode } & FieldProps<
  string | undefined,
  FieldRenderProps<string | undefined> & {
    validateArray?: ValidateType[] | undefined;
  }
>) => {
  /**
   * Композитный валидатор
   * @param {ValidateType[]} validators - Массив валидаторов
   * @returns {Function} - Валидатор, объединяющий результаты всех переданных валидаторов
   */
  const composeValidators =
    (validators: ValidateType[]) =>
    (value: Record<string, any> | any, allValues: Record<string, any>) => {
      const result = validators.reduce(
        (error: any, validator?: ValidateType) =>
          error || validator?.(value, allValues),
        undefined,
      );
      return result;
    };

  const { input, meta } = useField(name, {
    validate: composeValidators(validateArray || []),
    ...rest,
  });

  const { onBlur, onChange: onInputChange } = input;

  /**
   * Обработчик изменения значения поля
   * @param {string|undefined} value - Новое значение поля
   */
  const handleChange = useCallback(
    (value: string | undefined) => {
      onInputChange(value);

      if (onChange) {
        onChange(value);
      }
    },
    [onInputChange, onChange],
  );

  /**
   * Флаг для отображения ошибки
   * @type {boolean}
   */
  const renderError = validationWithoutTouch
    ? !!input.value && !!meta.error
    : meta.touched && !!meta.error;

  return (
    <div>
      <Autorecognition
        recognizedValue={recognizedValue}
        isRecognitionUsedBefore={isRecognitionUsedBefore}
        onBlur={onBlur}
        disabled={disabled}
        setValue={input.onChange}
        value={input.value}
        mask={mask}
      >
        <Input
          {...input}
          testId={testId}
          label={label}
          required={required}
          disabled={disabled}
          value={input.value}
          onChange={handleChange}
          type={type}
          error={!disabled && renderError}
          helperText={!disabled && showError && renderError && meta.error}
          placeholder={placeholder}
          tooltip={tooltip}
          multiline={multiline}
          rows={rows}
          maxRows={maxRows}
          mask={mask}
          loading={loading}
          clearable={clearable}
          inputMode={inputMode}
          autocomplete={autocomplete}
        />
        {after && typeof after === 'function' && (
          <Collapse in={meta.visited}>{after(input.value)}</Collapse>
        )}
      </Autorecognition>
    </div>
  );
};

export default TextInput;
