import React, { ReactElement } from 'react';

import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
} from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import classNames from 'classnames';

import useStyles from './Accordion.styles';

type AccordionPadding = 'extraSmall' | 'small' | 'medium';

/**
 * Интерфейс свойств для компонента Accordion.
 *
 * @interface AccordionProps
 * @property {React.ReactNode | React.ReactNode[]} children - Содержимое, отображаемое при раскрытии аккордеона.
 * @property {React.ReactNode | React.ReactNode[]} title - Заголовок или шапка аккордеона.
 * @property {boolean} [defaultExpanded=false] - Определяет, должен ли аккордеон быть раскрыт по умолчанию.
 * @property {() => void} [onClick] - Функция обратного вызова, вызываемая при клике на заголовок аккордеона.
 * @property {() => void} [onChangeIfExpanded] - Функция обратного вызова, вызываемая при раскрытии аккордеона.
 * @property {'small' | 'medium'} [padding='medium'] - Количество отступов вокруг заголовка и содержимого аккордеона.
 * @property {boolean} [expanded] - Определяет, должен ли аккордеон быть раскрыт.
 * @property {number} [iconVerticalMargin=0] - Количество вертикального отступа вокруг иконки раскрытия.
 * @property {ReactElement} [control] - Элемент управления, отображаемый рядом с заголовком аккордеона.
 * @property {string} [controlLabel=''] - Метка для элемента управления.
 * @property {boolean} [detailsWithoutPaddings=false] - Определяет, должны ли детали аккордеона не иметь отступов.
 */
export interface AccordionProps {
  children: React.ReactNode | React.ReactNode[];
  title: React.ReactNode | React.ReactNode[];
  defaultExpanded?: boolean;
  onClick?: () => void;
  onChangeIfExpanded?: () => void;
  padding?: AccordionPadding;
  expanded?: boolean;
  iconVerticalMargin?: number;
  control?: ReactElement;
  controlLabel?: string;
  detailsWithoutPaddings?: boolean;
}

/**
 * Кастомизируемый компонент аккордеона, построенный с использованием Material-UI.
 *
 * @component
 * @param {Object} props - Свойства для компонента Accordion.
 * @param {React.ReactNode | React.ReactNode[]} props.children - Содержимое, отображаемое при раскрытии аккордеона.
 * @param {React.ReactNode | React.ReactNode[]} props.title - Заголовок или шапка аккордеона.
 * @param {boolean} [props.defaultExpanded=false] - Определяет, должен ли аккордеон быть раскрыт по умолчанию.
 * @param {() => void} [props.onClick] - Функция обратного вызова, вызываемая при клике на заголовок аккордеона.
 * @param {() => void} [props.onChangeIfExpanded] - Функция обратного вызова, вызываемая при раскрытии аккордеона.
 * @param {'small' | 'medium'} [props.padding='medium'] - Количество отступов вокруг заголовка и содержимого аккордеона.
 * @param {boolean} [props.expanded] - Определяет, должен ли аккордеон быть раскрыт.
 * @param {number} [props.iconVerticalMargin=0] - Количество вертикального отступа вокруг иконки раскрытия.
 * @param {ReactElement} [props.control] - Элемент управления, отображаемый рядом с заголовком аккордеона.
 * @param {string} [props.controlLabel=''] - Метка для элемента управления.
 * @param {boolean} [props.detailsWithoutPaddings=false] - Определяет, должны ли детали аккордеона не иметь отступов.
 * @returns {JSX.Element} Компонент Accordion.
 */

export const Accordion = ({
  children,
  title,
  defaultExpanded = false,
  onClick,
  onChangeIfExpanded,
  padding = 'medium',
  expanded: expandedProps,
  iconVerticalMargin = 0,
  control,
  controlLabel = '',
  detailsWithoutPaddings = false,
}: AccordionProps) => {
  const classes = useStyles({ iconVerticalMargin });

  const accordionSummaryClasses = (() => {
    if (padding === 'small') {
      return {
        root: classes.AccordionSummaryRootSmall,
        content: classes.AccordionSummaryContentSmall,
      };
    }

    if (padding === 'extraSmall') {
      return {
        root: classes.AccordionSummaryRootExtraSmall,
        content: classes.AccordionSummaryContentExtraSmall,
      };
    }

    return {
      root: classes.AccordionSummaryRootMedium,
      content: classes.AccordionSummaryContentMedium,
    };
  })();

  const accordionDetailsRootPadding = (() => {
    if (padding === 'extraSmall') {
      return classes.AccordionDetailsRootExtraSmallPadding;
    }

    return classes.AccordionDetailsRootPadding;
  })();

  return (
    <MUIAccordion
      defaultExpanded={defaultExpanded}
      className={`aqa_accordion ${classes.accordion}`}
      expanded={expandedProps}
      onChange={(e, expanded) => {
        if (onClick) {
          onClick();
        }
        if (expanded && onChangeIfExpanded) {
          onChangeIfExpanded();
        }
      }}
    >
      <AccordionSummary
        classes={{
          root: classNames(
            classes.AccordionSummaryRoot,
            accordionSummaryClasses.root,
          ),
          content: accordionSummaryClasses.content,
          expandIcon: classes.AccordionSummaryExpandIcon,
        }}
        className="aqa_accorrdion_summary"
        IconButtonProps={{ size: 'small' }}
        expandIcon={<ArrowDropDownIcon color="primary" />}
      >
        {control && (
          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={control}
            label={controlLabel}
          />
        )}
        {title}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classNames(
            classes.AccordionDetailsRoot,
            accordionDetailsRootPadding,
          ),
        }}
        className={classNames(
          detailsWithoutPaddings && classes.WithoutPaddings,
          'aqa_accorrdion_details',
        )}
      >
        {children}
      </AccordionDetails>
    </MUIAccordion>
  );
};
