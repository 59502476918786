import React, { ReactNode } from 'react';

import { Box, SpacingValues } from '../Box';

interface DescriptionsProps {
  children: ReactNode;
  gapBetweenItems?: SpacingValues;
}

/**
 * Компонент Descriptions представляет собой контейнер для описаний,
 * который отображает дочерние элементы в виде вертикального списка с заданным отступом между ними.
 *
 * @param {Object} props - Свойства компонента.
 * @param {ReactNode} props.children - Дочерние элементы, которые будут отображены внутри компонента.
 * @param {SpacingValues} [props.gapBetweenItems='8'] - Отступ между дочерними элементами.
 * Допустимые значения определены в типе SpacingValues.
 *
 * @returns {ReactElement} - Элемент React, представляющий компонент Descriptions.
 */
export const Descriptions = ({
  children,
  gapBetweenItems = '8',
}: DescriptionsProps) => (
  <Box
    display="flex"
    flexDirection="column"
    gap={gapBetweenItems}
    p="16"
    bgcolor="bg.greyscale.primary"
    radius="l"
    testId="descriptions-test-id"
  >
    {children}
  </Box>
);
