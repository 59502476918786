import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';

import {
  ChangePasswordInitDto,
  ChangePasswordDto,
  ChangePasswordSendCodeDto,
} from '../../dto';

@injectable()
export class AuthPasswordService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
  ) {}

  async changePasswordInit(changePasswordInitDto: ChangePasswordInitDto) {
    await this.validator.validateOrReject(
      changePasswordInitDto,
      ChangePasswordInitDto,
    );

    await this.http.post(
      '/user/password/validate',
      {
        phone: changePasswordInitDto.phone,
        snils: changePasswordInitDto.snils
          ? changePasswordInitDto.snils.replace(/(-| )/g, '')
          : /* istanbul ignore next */
            '',
      },
      { withAuth: false },
    );
  }

  async changePasswordSendCode(
    changePasswordSendCodeDto: ChangePasswordSendCodeDto,
  ) {
    await this.validator.validateOrReject(
      changePasswordSendCodeDto,
      ChangePasswordSendCodeDto,
    );

    const { phone, snils, password } = changePasswordSendCodeDto;

    await this.http.post(
      '/user/password/send_sms',
      {
        phone,
        snils,
        password,
      },
      { withAuth: false },
    );
  }

  async changePassword(changePasswordDto: ChangePasswordDto) {
    await this.validator.validateOrReject(changePasswordDto, ChangePasswordDto);

    await this.http.put(
      '/user/password/complete',
      {
        password: changePasswordDto.password,
        phone: changePasswordDto.phone,
        code: changePasswordDto.code,
        snils: changePasswordDto.snils
          ? changePasswordDto.snils.replace(/(-| )/g, '')
          : /* istanbul ignore next */
            '',
      },
      { withAuth: false },
    );
  }
}
