import React, { useCallback } from 'react';

import arrayMutators from 'final-form-arrays';
import { Field } from 'react-final-form';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { Content } from '@vk-hr-tek/ui/Content';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { Button } from '@vk-hr-tek/ui/Button';
import { CheckboxList } from '@vk-hr-tek/ui/CheckboxList';
import { renderTwoLineOptionEllipsis } from '@vk-hr-tek/ui/input';
import {
  AutocompleteInput,
  SubmitError,
  Form,
  RadioInput,
} from '@vk-hr-tek/ui/form';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { UserApiService } from '@vk-hr-tek/app/user/services';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Descriptions, DescriptionsItem } from '@vk-hr-tek/ui/Descriptions';
import { Callout } from '@vk-hr-tek/ui/Callout';

import { useDispatch, useSelector } from '@app/hooks';

import { Page } from '../../../../layout';
import {
  addSubstitute,
  selectCompanyEventTypes,
  selectCompanyEventTypesError,
  selectCompanyEventTypesStatus,
  selectSubstitutes,
} from '../../../slice';
import { UserRouter } from '../../../types';
import { CreateSubstituteFormDto, CreateSubstituteDto } from '../../../dto';

import { SubstitutesHeader } from './SubstitutesHeader';
import { TemporarySubstitutes } from './TemporarySubstitutes';

interface SubstitutesProps {
  companyId: string;
  employeeId: string;
}

const TYPES_OF_SUBSTITUTE = [
  { value: 'permanent', label: 'Постоянный' },
  { value: 'temporary', label: 'Временный' },
];

export const Substitutes = ({ companyId, employeeId }: SubstitutesProps) => {
  const router = useInject<UserRouter>(UserRouter);
  const userApiService = useInject(UserApiService);
  const validator = useInject(ValidationService);

  const companyEventTypes = useSelector(selectCompanyEventTypes);
  const companyEventTypesStatus = useSelector(selectCompanyEventTypesStatus);
  const companyEventTypesError = useSelector(selectCompanyEventTypesError);

  const substitutes = useSelector(selectSubstitutes);
  const dispatch = useDispatch();

  const loadAccountSubstitutes = useCallback(
    async (filter: { limit: number; offset: number; query: string }) => {
      const res = await userApiService.getCompanyEmployeeSubstitutes({
        companyId,
        employeeId,
        ...filter,
      });

      const loadedOptions = res.employees.map((employee) => ({
        label: `${employee.name}, ${employee.company.name} (${employee.personnel_number})`,
        value: employee.id,
      }));

      return loadedOptions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const companyEventTypesOption = companyEventTypes.map((eventType) => ({
    label: eventType.name,
    value: eventType.id,
  }));

  const companyEmployeeSubstitutes = substitutes.find(
    ({ company, employee: companyEmployee }) =>
      company.id === companyId && companyEmployee.id === employeeId,
  );

  const companyName = companyEmployeeSubstitutes?.company.name || '';
  const employee = companyEmployeeSubstitutes?.employee;

  if (!employee) {
    router.goToProfile();
    return null;
  }

  const onSubmit = (values: CreateSubstituteFormDto) =>
    new Promise((resolve) => {
      const goBack = (value: unknown) => {
        resolve(value);
        router.goToProfile();
      };

      const transformedSubstituteDates = values.substitutions
        .map(({ employeeAbsence, dateFrom, dateTo }) => {
          if (employeeAbsence?.length) {
            const absences = employeeAbsence.map((absence) => {
              const absenceDates = absence.split(' - ');

              return {
                from_date: absenceDates[0],
                to_date: absenceDates[1],
              };
            });

            return absences;
          } else {
            return { from_date: dateFrom, to_date: dateTo };
          }
        })
        .flat();

      const formValues: CreateSubstituteDto = {
        employeeId,
        eventTypes: values.eventTypes,
        substituteId: values.substituteId,
      };

      if (values.typeOfSubstitute === 'temporary') {
        formValues.dates = transformedSubstituteDates;
      }

      dispatch(
        addSubstitute({
          values: formValues,
          actions: {
            resolve: goBack,
            reject: goBack,
          },
        }),
      );
    });

  return (
    <Page
      error={companyEventTypesError}
      title=""
      showTitleMobile
      status={companyEventTypesStatus}
    >
      <Box mb="16">
        <SubstitutesHeader />
      </Box>
      <Content>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          p="32"
          gap="32"
        >
          <Form
            initialValues={{
              typeOfSubstitute: 'permanent',
              substitutions: [{ typeOfPeriod: 'employeeAbsence' }],
              eventTypes: companyEventTypes.map((eventType) => eventType.id),
            }}
            validate={(values) =>
              validator.validate(values, CreateSubstituteFormDto, ['form'])
            }
            mutators={{
              ...arrayMutators,
            }}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              dirtySinceLastSubmit,
              submitting,
              submitError,
              hasValidationErrors,
              values,
              form,
            }) => {
              const handleSelectEventTypes = (id: string[]) =>
                form.change('eventTypes', id);

              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing="20">
                    <Grid item xs={6}>
                      <Box mt="8">
                        <Typography variant="h6">Общая информация:</Typography>
                      </Box>
                      <Descriptions>
                        <DescriptionsItem label="Юрлицо" value={companyName} />
                        {employee?.showPosition && employee.position && (
                          <>
                            <DescriptionsItem
                              label="Должность"
                              value={employee.position}
                            />

                            <DescriptionsItem
                              label="Табельный"
                              value={employee.personalNumber}
                            />
                          </>
                        )}
                      </Descriptions>

                      <Box mt="32">
                        <AutocompleteInput
                          name="substituteId"
                          autocomplete
                          required
                          label="Выберите заместителя"
                          loadItems={loadAccountSubstitutes}
                          validate={validator.required('Обязательное поле')}
                          renderOption={renderTwoLineOptionEllipsis}
                          minInputValueLength={3}
                        />
                      </Box>

                      <Box mt="32">
                        <RadioInput
                          label="Тип заместителя"
                          name="typeOfSubstitute"
                          options={TYPES_OF_SUBSTITUTE}
                          required
                        />
                      </Box>
                    </Grid>

                    {values.typeOfSubstitute === 'temporary' && (
                      <Grid item xs={12}>
                        <Grid container spacing="20">
                          <Grid item xs={6}>
                            <TemporarySubstitutes employeeId={employeeId} />
                          </Grid>
                          <Grid item xs={6}>
                            <Box mt="48">
                              <Callout expanded size="medium">
                                <Typography
                                  color="text.light.secondary"
                                  variant="inherit"
                                >
                                  Заместитель будет удален из настроек после
                                  завершения всех добавленных периодов
                                  автоматически
                                </Typography>
                              </Callout>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing="20">
                    <Grid item xs={6}>
                      <Box mt="8">
                        <Field
                          name="eventTypes"
                          validate={validator.required('Обязательное поле')}
                        >
                          {() => (
                            <CheckboxList
                              title="Типы заявок"
                              items={companyEventTypesOption}
                              selected={values.eventTypes}
                              onSelect={handleSelectEventTypes}
                            />
                          )}
                        </Field>
                      </Box>
                      {!dirtySinceLastSubmit && submitError && (
                        <Box pt="24">
                          <SubmitError submitError={submitError} />
                        </Box>
                      )}
                      <Box mt="32" width={250}>
                        <Button
                          disabled={hasValidationErrors}
                          fullWidth
                          loading={submitting}
                          size="large"
                          type="submit"
                        >
                          Сохранить
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box mt="48">
                        <Callout expanded size="medium">
                          <Typography
                            color="text.light.secondary"
                            variant="inherit"
                          >
                            Заместителю доступны все действия руководителя,
                            которого он замещает, в выбранных типах заявок
                          </Typography>
                        </Callout>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Box>
      </Content>
    </Page>
  );
};
