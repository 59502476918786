import React, { useEffect } from 'react';

import { FieldArray } from 'react-final-form-arrays';
import { useForm, useFormState } from 'react-final-form';

import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import {
  DateInput,
  MultipleAutocompleteInput,
  RadioInput,
} from '@vk-hr-tek/ui/form';
import { FormCard } from '@vk-hr-tek/ui/FormCard';
import { AddIcon } from '@vk-hr-tek/ui/icons';
import { InputTooltip } from '@vk-hr-tek/ui/InputTooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useInject } from '@vk-hr-tek/core/ioc';
import { useTranslations } from '@vk-hr-tek/core/hooks';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { renderTwoLineOptionWithCheckbox } from '@vk-hr-tek/ui/input';

import { useDispatch, useSelector } from '@app/hooks';

import { Substitute } from '../../../dto';
import {
  getSubstituteAbsenceOptions,
  selectSubstitutesOptions,
  selectSubstitutesOptionsStatus,
} from '../../../slice';

interface TemporarySubstitutesProps {
  employeeId: string;
}

export const TemporarySubstitutes = ({
  employeeId,
}: TemporarySubstitutesProps) => {
  const dispatch = useDispatch();
  const form = useForm();
  const formState = useFormState();
  const { t } = useTranslations();
  const validator = useInject(ValidationService);

  const substitutesOptions = useSelector(selectSubstitutesOptions);
  const substitutesOptionsStatus = useSelector(selectSubstitutesOptionsStatus);

  const values = formState.values;

  const hasEmployeeAbsencePeriod = values.substitutions.some(
    ({ typeOfPeriod }: Substitute) => typeOfPeriod === 'employeeAbsence',
  );

  const addMoreFormCard = () => {
    if (hasEmployeeAbsencePeriod) {
      return form.mutators.push('substitutions', {
        typeOfPeriod: 'personal',
      });
    } else {
      return form.mutators.push('substitutions', {
        typeOfPeriod: 'employeeAbsence',
      });
    }
  };

  const removeFormCard = (index: number) => () =>
    form.mutators.remove('substitutions', index);

  const TYPES_OF_PERIOD = [
    {
      value: 'employeeAbsence',
      label: t('settings.substitutes.new.employeeAbsenceTypesOfPeriod'),
      disabled: hasEmployeeAbsencePeriod && values.substitutions.length > 1,
      tooltip:
        'Уже добавлено замещение из отсутствий сотрудника. Вы можете его отредактировать ',
    },
    {
      value: 'personal',
      label: 'Свой период',
    },
  ];

  useEffect(() => {
    dispatch(getSubstituteAbsenceOptions({ employeeId }));
  }, [dispatch, employeeId]);

  return (
    <Box display="flex" flexDirection="column" gap="20">
      <Typography variant="h6">
        {t('settings.substitutes.new.employeeSubstitutions')}
      </Typography>

      <Box display="flex" gap="20" flexDirection="column">
        <FieldArray name="substitutions">
          {({ fields }) =>
            fields.map((name, index) => {
              const isButtonDisabled = values.substitutions.length === 1;

              const currentFieldValue = values.substitutions[index];

              const isEmployeeAbsencePeriodType =
                currentFieldValue.typeOfPeriod === 'employeeAbsence';

              return (
                <FormCard
                  title="Замещение"
                  button={{
                    onClick: removeFormCard(index),
                    disabled: isButtonDisabled,
                    tooltip:
                      'У временного заместителя должно быть добавлено хотя бы 1 замещение ',
                  }}
                  key={name}
                >
                  <Box>
                    <RadioInput
                      label="Выбор периода замещения"
                      name={`${name}.typeOfPeriod`}
                      options={TYPES_OF_PERIOD}
                      required
                      tooltip={
                        <InputTooltip
                          title=""
                          description={t(
                            'settings.substitutes.new.employeeAbsencesInputTooltip',
                          )}
                        />
                      }
                    />

                    {isEmployeeAbsencePeriodType && (
                      <MultipleAutocompleteInput
                        name={`${name}.employeeAbsence`}
                        type="local"
                        required
                        allowSelectAll
                        loading={substitutesOptionsStatus === 'loading'}
                        selectAllLabel={
                          <Typography variant="body3">
                            Все отсутствия
                          </Typography>
                        }
                        label={t('settings.substitutes.new.employeeAbsences')}
                        placeholder="Выберите отсуствия"
                        externalItems={substitutesOptions}
                        renderOption={renderTwoLineOptionWithCheckbox}
                        validate={
                          isEmployeeAbsencePeriodType
                            ? [validator.required('Обязательное поле')]
                            : undefined
                        }
                      />
                    )}

                    {currentFieldValue.typeOfPeriod === 'personal' && (
                      <Box display="flex" gap="16">
                        <DateInput
                          name={`${name}.dateFrom`}
                          required
                          label="Дата начала"
                          placeholder="ДД.ММ.ГГГГ"
                          minDate={new Date()}
                          maxDate={
                            currentFieldValue.dateTo
                              ? new Date(currentFieldValue.dateTo)
                              : undefined
                          }
                          validate={
                            !isEmployeeAbsencePeriodType
                              ? validator.required('Обязательное поле')
                              : undefined
                          }
                          testId={`${name}-from-date-input-test-id`}
                        />

                        <DateInput
                          name={`${name}.dateTo`}
                          required
                          label="Дата окончания"
                          placeholder="ДД.ММ.ГГГГ"
                          minDate={
                            currentFieldValue.dateFrom
                              ? new Date(currentFieldValue.dateFrom)
                              : new Date()
                          }
                          validate={
                            !isEmployeeAbsencePeriodType
                              ? validator.required('Обязательное поле')
                              : undefined
                          }
                          testId={`${name}-to-date-input-test-id`}
                        />
                      </Box>
                    )}
                  </Box>
                </FormCard>
              );
            })
          }
        </FieldArray>

        <Box
          display="flex"
          alignItems="center"
          gap="16"
          p="16"
          radius="l"
          bgcolor="bg.greyscale.primary"
          cursor="pointer"
          onClick={addMoreFormCard}
        >
          <Button
            variant="quaternaryDark"
            size="large"
            icon={<AddIcon color="primary" />}
          />

          <Typography variant="subtitle2">Добавить период замещения</Typography>
        </Box>
      </Box>
    </Box>
  );
};
