import React from 'react';

import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export const MoreHorizontalIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.45442 4.81226C1.06285 5.11843 0.497225 5.04921 0.19105 4.65765C-0.115124 4.26608 -0.0459015 3.70045 0.345663 3.39428L4.4423 0.191011C4.76791 -0.0635888 5.22507 -0.0636789 5.55078 0.190793L9.65074 3.39406C10.0424 3.70008 10.1119 4.26568 9.80585 4.65737C9.49983 5.04905 8.93423 5.1185 8.54255 4.81248L4.99691 2.0423L1.45442 4.81226Z"
        fill={theme.tokens.colors.stroke.secondary}
      />
    </SvgIcon>
  );
};
