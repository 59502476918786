import React from 'react';

import classNames from 'classnames';
import { useTheme, SvgIcon } from '@material-ui/core';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const DashIcon = ({ color = 'primary', size = 'normal' }: IconProps) => {
  const { tokens } = useTheme();
  const classes = useStyles();

  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        color && classes[`icon_color_${color}` as const],
        size && classes[`icon_size_${size}` as const],
      )}
    >
      <rect
        x="6"
        y="11"
        width="12"
        height="2"
        rx="1"
        fill={tokens.colors.stroke.primary}
      />
    </SvgIcon>
  );
};
