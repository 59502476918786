import React from 'react';

import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@material-ui/core';

import useStyles from './Tooltip.styles';

/**
 * Интерфейс, определяющий свойства для компонента Tooltip.
 */
interface TooltipProps {
  /**
   * Дочерний элемент, к которому привязан тултип.
   */
  children: React.ReactElement;

  /**
   * Текст заголовка или React-элемент, отображаемый в тултипе.
   */
  title: string | React.ReactElement;

  /**
   * Необязательное положение тултипа.
   * @default 'right'
   */
  placement?: MUITooltipProps['placement'];

  /**
   * Логическое значение, указывающее, отключен ли тултип.
   * @default false
   */
  disabled?: boolean;

  /**
   * Логическое значение, указывающее, является ли тултип интерактивным.
   * @default false
   */
  interactive?: boolean;

  /**
   * Необязательная функция обратного вызова, вызываемая при открытии тултипа.
   */
  onOpen?: () => void;

  /**
   * Необязательная функция обратного вызова, вызываемая при закрытии тултипа.
   */
  onClose?: () => void;

  /**
   * Логическое значение, указывающее, следует ли использовать инвертированные стили для тултипа.
   * @default false
   */
  inverted?: boolean;

  /**
   * Максимальная ширина.
   * Необязательный параметр для юнит-тестов.
   */
  maxWidth?: number;

  /**
   * Необязательная задержка (в миллисекундах) перед появлением тултипа.
   */
  enterDelay?: number;

  /**
   * Необязательный параметр для юнит-тестов.
   */
  testId?: string;
}

/**
 * Компонент Tooltip, который отображает тултип для указанного дочернего элемента.
 * Позволяет настраивать заголовок, положение и поведение тултипа.
 *
 * @param {TooltipProps} props - Свойства для компонента Tooltip.
 * @returns {React.ReactElement} - React-элемент, представляющий тултип.
 */

export const Tooltip = ({
  children,
  title,
  placement = 'right',
  disabled = false,
  interactive = false,
  maxWidth,
  onOpen,
  onClose,
  inverted = false,
  enterDelay,
  testId = 'tooltip-test-id',
}: TooltipProps) => {
  const classes = useStyles({ inverted, maxWidth });

  if (disabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <MUITooltip
      classes={classes}
      arrow
      title={title}
      placement={placement}
      enterTouchDelay={0}
      interactive={interactive}
      onOpen={onOpen}
      onClose={onClose}
      enterDelay={enterDelay}
      className="aqa_tooltip"
      data-testid={testId}
    >
      {children}
    </MUITooltip>
  );
};
