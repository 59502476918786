import React, { ReactNode, useCallback, useState } from 'react';

import { Box, SpacingValues } from '../../Box';
import { Typography } from '../../Typography';
import { IconButton } from '../../IconButton';
import { List, ListItem } from '../../List';
import {
  ArrowDownIcon as ArrowDown,
  ArrowUpIcon as ArrowUp,
} from '../../icons';

interface DescriptionsItemProps {
  label: string;
  value: string[] | string;
  listItems?: boolean;
  children?: ReactNode;
  gapBetweenItems?: SpacingValues;
  flexDirection?: 'column' | 'row';
  alignItems?: 'flex-start' | 'flex-end' | 'center';
  valueTextVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
}

export const DescriptionsItem = ({
  label,
  value,
  listItems = false,
  children,
  flexDirection = 'column',
  alignItems = 'flex-start',
  valueTextVariant = 'body2',
}: DescriptionsItemProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection={flexDirection}
      gap="8"
      alignItems={alignItems}
    >
      <Box>
        <Typography variant="body3" color="text.light.tertirary">
          {`${label}:`}
        </Typography>
      </Box>

      {children ? (
        <Box display="flex" gap="8" flexWrap="wrap" alignItems="center">
          <Typography variant="body2" color="text.light.tertirary">
            {`${value}:`}
          </Typography>

          {children}
        </Box>
      ) : (
        <Box>
          <Box>
            {Array.isArray(value) && !listItems && (
              <Box display="flex" flexDirection="column" gap="16">
                {(expanded ? value : value.slice(0, 4)).map(
                  (itemValue: string) => (
                    <Box
                      key={itemValue}
                      display="flex"
                      flexDirection="column"
                      gap="4"
                    >
                      <Typography variant={valueTextVariant}>
                        {itemValue}
                      </Typography>
                    </Box>
                  ),
                )}
              </Box>
            )}

            {Array.isArray(value) && listItems && (
              <List listStyleType="disc" pl={1.6}>
                {(expanded ? value : value.slice(0, 4)).map(
                  (itemValue, index) => (
                    <ListItem key={itemValue}>
                      <Box pt={index === 0 ? 'unset' : '8'}>
                        <Typography variant={valueTextVariant}>
                          {itemValue}
                        </Typography>
                      </Box>
                    </ListItem>
                  ),
                )}
              </List>
            )}
          </Box>

          {!Array.isArray(value) && (
            <Box>
              <Typography variant="body2">{value}</Typography>
            </Box>
          )}

          <Box>
            {Array.isArray(value) && value.length > 4 && (
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="8"
                >
                  <IconButton
                    type="button"
                    size="small"
                    onClick={handleExpandClick}
                  >
                    {expanded ? <ArrowUp /> : <ArrowDown />}
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
