import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const VacationIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
      viewBox="0 0 14 14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9795 7.91469C14.2273 4.73332 12.203 1.95093 9.08704 0.854418L9.1147 0.74144C9.19331 0.42029 8.99699 0.0960026 8.67621 0.0173367C8.35545 -0.0613293 8.0317 0.135126 7.95309 0.456485L7.93321 0.537662C4.6875 -0.11196 1.5236 1.2534 0.119637 4.12387C-0.235592 4.85027 0.242747 5.64802 0.994625 5.85368L6.11027 7.25293L5.6066 9.21916L4.75399 12.7231H2.34163C1.98951 12.7231 1.70406 13.0089 1.70406 13.3615C1.70406 13.7142 1.98951 14 2.34163 14H9.84357C10.1957 14 10.4811 13.7142 10.4811 13.3615C10.4811 13.0089 10.1957 12.7231 9.84357 12.7231H6.06639L6.84285 9.53215L7.34046 7.58935L12.348 8.95889C13.0998 9.16455 13.9168 8.72101 13.9795 7.91469ZM4.89099 5.67794L9.07837 6.8232C9.90387 4.65695 8.95019 2.76018 8.18171 1.8618C7.06384 2.24425 5.27975 3.39223 4.89099 5.67794ZM6.1725 1.57685C4.0094 1.63187 2.12949 2.75684 1.20363 4.63059C1.21909 4.65172 1.25317 4.6829 1.30974 4.69838L3.73239 5.36097C4.07181 3.55354 5.11084 2.3361 6.1725 1.57685ZM12.6631 7.80359L10.237 7.13995C10.8625 5.40993 10.5852 3.83201 10.0559 2.63737C11.8933 3.78577 12.9428 5.71288 12.7889 7.7992C12.7648 7.80966 12.7196 7.81907 12.6631 7.80359Z"
      />
    </SvgIcon>
  );
};
