import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, { verticalClickArea: number }>((theme) => ({
  input: {
    marginRight: theme.spacing(0.9), // TODO - fix нет в токенах
    marginLeft: theme.spacing(0.9), // TODO - fix нет в токенах
    padding: 0,
    width: theme.px(18),
    height: theme.px(18),
    backgroundColor: theme.tokens.colors.bg.light.primary,

    '&.Mui-disabled:not(.Mui-checked) .MuiSvgIcon-root path': {
      fill: theme.tokens.colors.bg.greyscale.primary,
    },

    '&:hover, &.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },

    '&:hover': {
      '& .MuiSvgIcon-root path': {
        fill: theme.tokens.colors.original.brand.primary,
      },
    },

    '&.Mui-checked:hover': {
      '& .MuiSvgIcon-root path': {
        fill: theme.tokens.colors.bg.light.primary,
      },
    },
    '&.Mui-checked.Mui-disabled:hover': {
      '& .MuiSvgIcon-root path': {
        fill: theme.tokens.colors.stroke.secondary,
      },
    },
  },
  verticalClickArea: ({ verticalClickArea }) => ({
    marginTop: verticalClickArea,
    marginBottom: verticalClickArea,
  }),
  iconChecked: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.1), // TODO - fix нет в токенах
    fontSize: theme.tokens.typography.variant.body2.fontSize,
    backgroundColor: theme.tokens.colors.original.brand.primary,
    borderRadius: 2, // TODO - fix нет в токенах

    '& > .MuiSvgIcon-root path': {
      fill: theme.tokens.colors.bg.light.primary,
    },

    '&:hover': {
      backgroundColor: theme.tokens.colors.original.brand.tertirary,
    },
  },

  iconDisabled: {
    backgroundColor: theme.tokens.colors.bg.greyscale.primary,

    '& > .MuiSvgIcon-root path': {
      fill: theme.tokens.colors.stroke.secondary,
    },
    '&:hover': {
      backgroundColor: theme.tokens.colors.bg.greyscale.primary,
    },
  },

  withoutMargin: {
    marginRight: 0,
    marginLeft: 0,
  },

  controlLabel: {
    margin: 0,
  },
}));

export default useStyles;
