import React, { useEffect } from 'react';

import classNames from 'classnames';
import { TreeView as MUITreeView } from '@material-ui/lab';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  FiberManualRecord as BulletIcon,
} from '@material-ui/icons';

import { TreeItem } from './TreeItem';
import { Item } from './Item.interface';
import { useStyles } from './TreeView.styles';

interface BaseTreeViewProps {
  onSelect: (value: string, label?: string, item?: Item) => void;
  onChildrenLoad?: (unitId: string) => Promise<Item[]>;
  items: Item[];
  selectOnShrink?: boolean;
  showRulers?: boolean;
  showCounters?: boolean;
  withPaddingBottom?: boolean;
}

interface SingleSelectTreeViewProps extends BaseTreeViewProps {
  multiple?: false;
  selected: string;
}
interface MultiSelectTreeViewProps extends BaseTreeViewProps {
  multiple: true;
  selected: string[];
}

export type TreeViewProps =
  | SingleSelectTreeViewProps
  | MultiSelectTreeViewProps;

/**
 * Компонент древовидного представления, который поддерживает как одиночный, так и множественный выбор.
 * Он может разворачивать и сворачивать элементы дерева, а также обрабатывать асинхронную загрузку дочерних элементов.
 * Компонент также предоставляет визуальные индикаторы для состояний развернутости и выбора.
 *
 * @param {TreeViewProps} props - Свойства для компонента TreeView.
 * @param {Item[]} props.items - Массив объектов данных, представляющих элементы дерева.
 * @param {boolean} [props.selectOnShrink=true] - Определяет, следует ли выбирать родительский узел при сворачивании дочернего узла.
 * @param {boolean} [props.showRulers=true] - Определяет, следует ли отображать линии для элементов дерева.
 * @param {boolean} [props.showCounters=true] - Определяет, следует ли отображать счетчики, связанные с элементами дерева.
 * @param {function} props.onSelect - Функция обратного вызова, вызываемая при выборе элемента дерева.
 *                                    Принимает два аргумента: идентификатор узла и метку узла.
 * @param {function} [props.onChildrenLoad] - Асинхронная функция для загрузки дочерних элементов дерева.
 *                                            Принимает один аргумент: идентификатор узла и возвращает промис,
 *                                            который разрешается в массив элементов.
 * @param {boolean} [props.withPaddingBottom] - Добавляет padding-bottom 56px
 * @param {boolean} [props.multiple=false] - Указывает, поддерживает ли дерево множественный выбор.
 * @param {string | string[]} props.selected - Идентификатор текущего выбранного узла для одиночного выбора
 *                                            или массив идентификаторов выбранных узлов для множественного выбора.
 * @returns {JSX.Element} - Отрендеренный компонент TreeView.
 */

const parseParents: (
  items: Item[],
  parents?: string[],
) => Record<string, string[]> = (items, parents = []) => {
  let result: Record<string, string[]> = {};

  items.forEach((item) => {
    result[item.id] = parents;

    if (item.children && item.children.length) {
      result = {
        ...result,
        ...parseParents(item.children, [...parents, item.id]),
      };
    }
  });

  return result;
};

export const TreeView = (props: TreeViewProps) => {
  const {
    onSelect,
    onChildrenLoad,
    items,
    selectOnShrink = true,
    showRulers = true,
    showCounters = true,
    withPaddingBottom = false,
  } = props;

  const classes = useStyles({ withPaddingBottom });

  const [expanded, expand] = React.useState<string[]>([]);
  const parentsOfSelected =
    (!props.multiple &&
      props.selected &&
      parseParents(items)[props.selected]) ||
    [];

  useEffect(() => {
    if (!props.multiple) {
      expand((previous) => [
        ...previous,
        ...(parseParents(items)[props.selected] || []),
      ]);
    }
  }, [props.selected, items, props.multiple]);

  return (
    <MUITreeView
      expanded={expanded}
      className={classNames(classes.root, 'aqa_tree_view')}
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
      defaultEndIcon={<BulletIcon className={classes.endIcon} />}
      data-testid="tree-view-root-test-id"
      {...(props.multiple
        ? {
            selected: props.selected,
            multiSelect: props.multiple,
          }
        : { selected: props.selected })}
    >
      {items.map((item) => (
        <TreeItem
          key={item.id}
          item={item}
          showRulers={showRulers}
          showCounters={showCounters}
          onSelect={onSelect}
          expanded={expanded}
          onExpand={(value) =>
            expand((previous) => {
              if (previous.includes(value)) {
                if (selectOnShrink && parentsOfSelected.includes(value)) {
                  onSelect(value);
                }
                return previous.filter(
                  (previousExpanded) => previousExpanded !== value,
                );
              }

              return [...previous, value];
            })
          }
          onChildrenLoad={onChildrenLoad}
          {...(props.multiple
            ? {
                selected: props.selected,
                multiple: props.multiple,
              }
            : { selected: props.selected })}
        />
      ))}
    </MUITreeView>
  );
};
