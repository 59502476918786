import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { List, ListItem } from '@vk-hr-tek/ui/List';

export const SubstitutesTitleTooltip = () => (
  <Box display="flex" flexDirection="column" gap="8">
    <Box>
      <Typography variant="caption" color="text.dark.primary">
        Заместитель получает возможность работать с заявками подчиненных
        руководителя, которого он замещает. Доступ выдается на каждый тип заявки
        отдельно
      </Typography>
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      gap="4"
      p="12"
      radius="s"
      bgcolor="bg.dark.secondary"
    >
      <Box>
        <Typography variant="caption" color="text.dark.primary">
          После назначения заместитель может:
        </Typography>
      </Box>
      <Box ml="12">
        <List listStyleType="disc">
          <ListItem>
            выполнять действия в заявках, которые созданы после его назначения
            заместителем;
          </ListItem>
          <ListItem>
            создавать заявки на подчиненных: только тех типов, для которых он
            выбран заместителем.
          </ListItem>
        </List>
      </Box>
    </Box>
  </Box>
);
