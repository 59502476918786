import { IsString, IsNotEmpty, IsOptional, IsArray } from 'class-validator';
import { Type } from 'class-transformer';

import { Substitute } from '..';

const notEmptyOptions = {
  message: 'Обязательное поле',
  groups: ['form'],
};

type SubstituteType = 'permanent' | 'temporary';

export class CreateSubstituteFormDto {
  @IsString({
    ...notEmptyOptions,
  })
  @IsNotEmpty({
    ...notEmptyOptions,
  })
  typeOfSubstitute: SubstituteType;

  @Type(() => Substitute)
  @IsArray({
    groups: ['form'],
  })
  @IsOptional({
    groups: ['form'],
  })
  substitutions: Substitute[];

  @IsString({
    ...notEmptyOptions,
  })
  @IsNotEmpty({
    ...notEmptyOptions,
  })
  substituteId: string;

  @Type(() => String)
  @IsArray({
    ...notEmptyOptions,
  })
  @IsNotEmpty({
    ...notEmptyOptions,
  })
  eventTypes: string[];
}
