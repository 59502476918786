import { Type } from 'class-transformer';
import { IsNotEmpty, IsUUID, IsOptional, IsArray } from 'class-validator';

import { DateRange } from '@app/gen/users';

export class RemoveSubstituteDto {
  @IsUUID()
  @IsNotEmpty()
  employeeId: string;

  @IsUUID()
  @IsNotEmpty()
  eventTypeId: string;

  @IsUUID()
  @IsNotEmpty()
  substituteId: string;

  @Type(() => DateRange)
  @IsArray()
  @IsOptional()
  dates?: DateRange[];
}
