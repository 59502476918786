import { injectable } from 'inversify';
import {
  isSameDay,
  isSameMonth,
  startOfDay,
  endOfDay,
  subDays,
  isWeekend,
  getYear,
  eachDayOfInterval,
  formatISO,
  add,
  sub,
  closestTo,
  differenceInCalendarDays,
  differenceInBusinessDays,
  differenceInDays,
  endOfMonth,
  isValid,
  addDays,
  isWithinInterval,
} from 'date-fns';

import { UnmarshallerService } from '../unmarshaller';
import { HttpService } from '../http';
import { FormatService } from '../format';

import { WorkCalendar } from './gen/calendar';

const DECEMBER = 11;
const NEW_YEAR_ICON_START_DAY = 10;
const NEW_YEAR_ICON_END_DAY = 14;

@injectable()
export class Calendar {
  static minDate = new Date('1900-01-01');

  static maxDate = new Date('2100-01-01');

  private holidaysAndWeekends: Record<number, Record<string, boolean>> = {};

  private holidays: Record<number, Record<string, boolean>> = {};

  private downloading: Record<number, boolean> = {};

  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private format: FormatService,
  ) {}

  /**
   * Загрузить календарь выходных и праздничных дней
   * @param {Date} [date] Дата, по которой будет вычисляться год для загружаемого календаря. Опционально
   * @param {string} [id] Id сотрудника необходим, если загружается календарь для конкретного сотрудника. Опционально
   * @returns {Promise<void>}
   */
  async download(date = new Date(), id?: string) {
    const year = getYear(date);
    const defaultYear = 2021;

    if (year < defaultYear) return;

    try {
      if (!this.holidaysAndWeekends[year] && !this.downloading[year]) {
        this.downloading[year] = true;
        const result = await this.unmarshaller.unmarshall(
          await this.http.get(
            `/work_calendar/${year}`,
            id ? { employee_id: id } : {},
            { withSide: false },
          ),
          WorkCalendar,
        );

        this.holidaysAndWeekends[year] = result.dates
          .filter(({ type }) => type === 'holiday' || type === 'weekend')
          .reduce((acc, { date: day }) => ({ ...acc, [day]: true }), {});
        this.holidays[year] = result.dates
          .filter(({ type }) => type === 'holiday')
          .reduce((acc, { date: day }) => ({ ...acc, [day]: true }), {});
        this.downloading[year] = false;
      }
    } catch (e) {
      // proceed anyway
      this.downloading[year] = false;
    }
  }

  /** Получить массив праздничных дней за выбранный год
   * на основе ранее загруженных календарей
   * @param {number} year Год, для которого получаем массив праздничных дней
   * @returns {Date[]}
   */
  getHolidays(year: number): Date[] {
    return Object.keys(this.holidays[year] || {}).map((day) =>
      this.startOfDay(new Date(day)),
    );
  }

  /** Получить массив праздничных и выходных дней за выбранный год
   * на основе ранее загруженных календарей
   * @param {number} year Год, для которого получаем массив праздничных и выходных дней
   * @returns {Date[]}
   */
  getHolidaysOrWeekends(year: number): Date[] {
    return Object.keys(this.holidaysAndWeekends[year] || {}).map((day) =>
      this.startOfDay(new Date(day)),
    );
  }

  /** Сбросить все данные по сохранненным ранее календарям */
  reset() {
    this.holidaysAndWeekends = {};
    this.holidays = {};
    this.downloading = {};
  }

  /** Преобразовать дату в формат ISO
   * @param {Date} date Дата, которая должна быть преобразована
   * @param {Object} [options] Параметры для преобразования к ISO
   * @param {'complete' | 'date' | 'time' } options.representation Параметры для преобразования к ISO
   * @returns {string}
   */
  formatISO(
    date: Date,
    options: { representation: 'complete' | 'date' | 'time' } = {
      representation: 'complete',
    },
  ) {
    return formatISO(date, options);
  }

  /** Получить новую дату путем прибавления количества дней к выбранной дате
   * @param {Date} date Дата, на основе которой будет получена новая дата
   * @param {number} amount Количество дней
   * @returns {Date}
   */
  add(date: Date, amount: number): Date {
    return add(date, { days: amount });
  }

  /** Получить новую дату путем прибавления количества дней к выбранной дате
   * за вычетом праздничных дней
   * @param {Date} date Дата, на основе которой будет получена новая дата
   * @param {number} amount Количество дней
   * @returns {Date}
   */
  addWithoutHolidayDays(date: Date, amount: number): Date {
    let count = 0;
    let result = date;

    while (count < amount) {
      result = add(result, { days: 1 });

      if (!this.isHoliday(result)) {
        count += 1;
      }
    }

    return result;
  }

  /** Получить новую дату путем прибавления количества рабочих дней к выбранной дате
   * @param {Date} date Дата, на основе которой будет получена новая дата
   * @param {number} amount Количество рабочих дней
   * @returns {Date}
   */
  addBusinessDays(date: Date, amount: number): Date {
    let count = 0;
    let result = date;

    while (count < amount) {
      result = add(result, { days: 1 });

      if (!this.isHolidayOrWeekend(result)) {
        count += 1;
      }
    }

    return result;
  }

  /** Получить новую дату путем вычитания количества дней из выбранной даты
   * @param {Date} date Дата, на основе которой будет получена новая дата
   * @param {number} amount Количество дней
   * @returns {Date}
   */
  sub(date: Date, amount: number): Date {
    return sub(date, { days: amount });
  }

  /** Получить новую дату путем вычитания количества минут из выбранной даты
   * @param {Date} date Дата, на основе которой будет получена новая дата
   * @param {number} amount Количество минут
   * @returns {Date}
   */
  subMinutes(date: Date, amount: number): Date {
    return sub(date, { minutes: amount });
  }

  /** Получить новую дату путем вычитания количества рабочих дней из выбранной даты
   * @param {Date} date Дата, на основе которой будет получена новая дата
   * @param {number} amount Количество рабочих дней
   * @returns {Date}
   */
  subBusinessDays(date: Date, amount: number): Date {
    let count = 0;
    let result = date;

    while (count < amount) {
      result = sub(result, { days: 1 });

      if (!this.isHolidayOrWeekend(result)) {
        count += 1;
      }
    }

    return result;
  }

  /** Определить является выбранная дата выходным или праздничным днем
   * на основе ранее загруженных календарей
   * @param {Date} date Выбранная дата
   * @returns {boolean}
   */
  isHolidayOrWeekend(date: Date): boolean {
    const year = getYear(date);

    if (this.holidaysAndWeekends[year]) {
      return !!this.holidaysAndWeekends[year][
        formatISO(date, { representation: 'date' })
      ];
    }

    return isWeekend(date);
  }

  /** Определить является выбранная дата праздничным днем
   * на основе ранее загруженных календарей
   * @param {Date} date
   * @returns {boolean}
   */
  isHoliday(date: Date): boolean {
    const year = getYear(date);

    if (this.holidays[year]) {
      return !!this.holidays[year][formatISO(date, { representation: 'date' })];
    }

    return false;
  }

  /** Определить совпадает год, месяц и день у двух выбранных дат
   * @param {Date} date1 Дата №1
   * @param {Date} date2 Дата №2
   * @returns {boolean}
   */
  isSameDay(date1: Date, date2: Date): boolean {
    return isSameDay(date1, date2);
  }

  /** Определить совпадает год и месяц у двух выбранных дат
   * @param {Date} date1 Дата №1
   * @param {Date} date2 Дата №2
   * @returns {boolean}
   */
  isSameMonth(date1: Date, date2: Date): boolean {
    return isSameMonth(date1, date2);
  }

  /** Определить количество полных дней между двумя датами
   * Дробные дни округляются до нуля
   * @param {Date} date1 Дата №1
   * @param {Date} date2 Дата №2
   * @returns {number}
   */
  differenceInDays(
    date1: Date,
    date2: Date,
    options?: { excludeHolidays?: boolean; excludeWorkingDays?: boolean },
  ): number {
    if (options?.excludeHolidays) {
      return (
        differenceInDays(date1, date2) -
        this.countOfHolidaysInInterval(date2, date1)
      );
    }

    if (options?.excludeWorkingDays) {
      return differenceInBusinessDays(date1, date2);
    }

    return differenceInDays(date1, date2);
  }

  /** Получить дату начала выбранного дня
   * Результат будет в локальной временной зоне
   * @param {Date} date Выбранная дата
   * @returns {Date}
   */
  startOfDay(date: Date): Date {
    return startOfDay(date);
  }

  /** Получить дату конца выбранного дня
   * Результат будет в локальной временной зоне
   * @param {Date} date Выбранная дата
   * @returns {Date}
   */
  endOfDay(date: Date): Date {
    return endOfDay(date);
  }

  hasAvailableDatesAfter(
    since: Date,
    intervals: { active_from: string; active_to?: string }[],
  ) {
    if (!intervals.length || intervals[0].active_to) {
      return true;
    }

    const start = startOfDay(since);
    let end = startOfDay(new Date(intervals[0].active_from));

    if (intervals.length === 1) {
      if (subDays(end, 1) <= start) {
        return false;
      } else {
        return true;
      }
    }

    for (let i = 0; i < intervals.length; i++) {
      const { active_from: activeFrom, active_to: activeTo } = intervals[i];

      const dateFrom = startOfDay(new Date(activeFrom));
      const dateTo = startOfDay(
        activeTo ? new Date(activeTo) : new Date('2100-01-01'),
      );

      if (isSameDay(dateTo, end) || isSameDay(dateTo, subDays(end, 1))) {
        end = dateFrom;
      }

      if (subDays(end, 1) <= start) {
        return false;
      }
    }

    return true;
  }

  /** Вернуть дату из массива дат, ближайшую к выбранной дате
   * Если в массиве отсутствуют валидные даты, то возвращается undefined
   * @param {Date} date Выбранная дата
   * @returns {Date|undefined}
   * */
  closestTo(date: Date, dates: Date[]) {
    return closestTo(date, dates);
  }

  /** Определить количество календарных дней между двумя датами
   * Часть даты, отвечающая за время, исключяется из рассмотрения
   * @param {Date} date1 Дата №1
   * @param {Date} date2 Дата №2
   * @returns {number}
   */
  differenceInCalendarDays(date1: Date, date2: Date) {
    return differenceInCalendarDays(date1, date2);
  }

  /** Определить количество дней отпуска между двумя датами
   * @param {Date} date1 Дата №1
   * @param {Date} date2 Дата №2
   * @returns {number}
   */
  countOfVacationDays(date1: Date, date2: Date) {
    return (
      differenceInCalendarDays(date1, date2) +
      1 -
      this.countOfHolidaysInInterval(date2, date1)
    );
  }

  /** Входит ли дата в один из интервалов
   * @param {Date} date Выбранная дата
   * @param {[start: Date, end: Date]} intervals Массив интервалов
   * @returns {Date}
   * */
  isWithinIntervals(date: Date, intervals: [start: Date, end: Date][]) {
    return intervals.some((interval) =>
      isWithinInterval(date, { start: interval[0], end: interval[1] }),
    );
  }

  /** Вернуть дату конца месяца на основе выбранной даты
   * @param {Date} date Выбранная дата
   * @returns {Date}
   * */
  endOfMonth(date: Date) {
    return endOfMonth(date);
  }

  /** Определить идет Дата №1 раньше Даты №2
   * @param {Date} date Дата №1
   * @param {Date} minDate Дата №2
   * @returns {boolean}
   * */
  isLessThan(date: Date, minDate: Date) {
    return date < minDate;
  }

  /** Определить идет Дата №1 позже Даты №2
   * @param {Date} date Дата №1
   * @param {Date} minDate Дата №2
   * @returns {boolean}
   * */
  isMoreThan(date: Date, maxDate: Date) {
    return date > maxDate;
  }

  /** Определить одинаковы даты или Дата №1 идет позже Даты №2
   * с учетом UTC
   * @param {Date} date Дата №1
   * @param {Date} minDate Дата №2
   * @returns {boolean}
   * */
  isEqualOrMore(date1: Date, date2: Date): boolean {
    return date1.getTime() >= date2.getTime();
  }

  isTimeForNewYear() {
    const currentDay = new Date().getDate();
    const currentMonth = new Date().getMonth();

    if (currentMonth === 0 && currentDay <= NEW_YEAR_ICON_END_DAY) {
      return true;
    }

    if (currentMonth === DECEMBER && currentDay >= NEW_YEAR_ICON_START_DAY) {
      return true;
    }

    return false;
  }

  /** Дата является валидной, если она наступает раньше даты, вычисляемой из опций
   * @param {Date} date Валидируемая дата
   * @param options
   * @param {number} options.days_count Количество дней, которое будет прибавлено к начальной дате
   * @param {Date} options.from_date Некоторая начальная дата, к которой будут прибавлены дни
   * @param {boolean} options.working_days_only Параметр в значении true указывает, что для вычисления новой даты
   * нужно прибавлять рабочие дни, иначе календарные
   * @returns {boolean}
   * */
  isNotWithinDays(
    date: Date,
    options: {
      days_count: number;
      from_date: Date;
      working_days_only: boolean;
    },
  ) {
    const minDate = options.working_days_only
      ? this.addBusinessDays(
          this.startOfDay(options.from_date),
          options.days_count,
        )
      : this.addWithoutHolidayDays(
          this.startOfDay(options.from_date),
          options.days_count,
        );

    return date < minDate;
  }

  /** Дата является валидной, если она наступает не раньше даты, вычисляемой из опций
   * @param {Date} date Валидируемая дата
   * @param options
   * @param {number} options.days_count Количество дней, которое будет прибавлено к начальной дате
   * @param {Date} options.from_date Некоторая начальная дата, к которой будут прибавлены дни
   * @param {boolean} options.working_days_only Параметр в значении true указывает, что для вычисления новой даты
   * нужно прибавлять рабочие дни, иначе календарные
   * @param {boolean} options.exclude_holidays Параметр в значении true указывает, что для вычисления новой даты
   * нужно прибавлять количество праздничных дней из интевала
   * @returns {boolean}
   * */
  isWithinDays(
    date: Date,
    options: {
      days_count: number;
      from_date: Date;
      working_days_only: boolean;
      exclude_holidays?: boolean;
    },
  ) {
    const toDate = options.working_days_only
      ? this.addBusinessDays(
          this.startOfDay(options.from_date),
          options.days_count,
        )
      : add(this.startOfDay(options.from_date), { days: options.days_count });

    const holidaysCount = this.countOfHolidaysInInterval(
      this.startOfDay(options.from_date),
      toDate,
    );

    const dateToCompareWith = options.exclude_holidays
      ? add(this.startOfDay(toDate), { days: holidaysCount })
      : toDate;

    return this.startOfDay(date) >= dateToCompareWith;
  }

  /** Дата является валидной, если она попадает в любой интервал из переданных в options
   * @param {Date} date Валидируемая дата
   * @param options
   * @param {{ from_date: string; to_date: string }[]} options.existing_dates Массив интервалов дат
   * @returns {boolean}
   * */
  isInNotWithinIntervals(
    date: Date,
    options: {
      existing_dates: { from_date: string; to_date: string }[];
    },
  ) {
    return options.existing_dates.some((interval) => {
      return (
        date >= this.startOfDay(new Date(interval.from_date)) &&
        date <= new Date(interval.to_date)
      );
    });
  }

  /** Возвращает интервал, содержащий валидируемую дату
   * @param {Date} date Валидируемая дата
   * @param options
   * @param {{ from_date: string; to_date: string, action: 'error' | 'warning', message: string }[]} options.existing_dates Массив интервалов дат
   * @returns {{ from_date: string; to_date: string, action: 'error' | 'warning', message: string } | undefined}
   * */
  getOverlapInterval(
    date: Date,
    options: {
      existing_dates: {
        from_date: string;
        to_date: string;
        action: 'error' | 'warning';
        message: string;
      }[];
    },
  ) {
    return options.existing_dates.find((interval) => {
      return (
        date >= this.startOfDay(new Date(interval.from_date)) &&
        date <= new Date(interval.to_date)
      );
    });
  }

  /** Дата является валидной, если среди переданных интервалов дат есть хотя бы один, чье начало не раньше from_date,
   * но раньше валидируемой даты
   * @param {Date} date Валидируемая дата
   * @param options
   * @param {Date} options.from_date Дата, которая участвует в отборе подходящих интервалов
   * @param {{ from_date: string; to_date: string }[]} options.existing_dates Массив интервалов дат
   * @returns {boolean}
   * */
  /**
   * Попадает в любой интвервал, больше from-date
   * */
  isInNotWithinIntervalsAfterDate(
    date: Date,
    options: {
      from_date: Date;
      existing_dates: { from_date: string; to_date: string }[];
    },
  ) {
    if (this.startOfDay(options.from_date) > date) {
      return true;
    }

    const startDays = [...options.existing_dates]
      .sort((first, second) =>
        new Date(first.from_date) > new Date(second.from_date) ? 1 : -1,
      )
      .map(({ from_date }) => from_date);

    const maxDate = startDays.find(
      (day) =>
        this.startOfDay(new Date(day)) >= this.startOfDay(options.from_date),
    );

    if (!maxDate) {
      return false;
    }

    return date >= this.startOfDay(new Date(maxDate));
  }

  /** Определить все дни из интервала являются праздничными/выходными
   * @param {Date} date Выбранная дата
   * @param {Object} options Опции
   * @param {Date} options.from_date Опция устанавливает конец интервала
   * @param {boolean} [options.exclude_weekends] Опция в значении true устанавливает проверку, что все дни из интервала праздничные
   * @returns {boolean}
   */
  areAllHolidaysInInterval(
    date: Date,
    options: {
      from_date: Date;
      exclude_weekends?: boolean;
    },
  ) {
    const fromDate = this.startOfDay(options.from_date);

    if (this.startOfDay(options.from_date) > date) {
      return false;
    }

    return eachDayOfInterval({
      start: fromDate,
      end: date,
    }).every((day) =>
      options.exclude_weekends
        ? this.isHoliday(day)
        : this.isHolidayOrWeekend(day),
    );
  }

  /** Определить количество праздничных дней между двумя датами
   * на основе ранее загруженных календарей
   * @params {Date} date1 Дата №1
   * @params {Date} date2 Дата №2
   * @returns {number}
   * */
  countOfHolidaysInInterval(date1: Date, date2: Date) {
    return eachDayOfInterval({
      start: date1 < date2 ? date1 : date2,
      end: date1 < date2 ? date2 : date1,
    }).filter((day) => this.isHoliday(day)).length;
  }

  /** Определить количество выходных дней между двумя датами
   * на основе ранее загруженных календарей
   * @params {Date} date1 Дата №1
   * @params {Date} date2 Дата №2
   * @returns {number}
   * */
  countOfWeekendsInInterval(date1: Date, date2: Date) {
    return (
      eachDayOfInterval({
        start: date1 < date2 ? date1 : date2,
        end: date1 < date2 ? date2 : date1,
      }).filter((day) => this.isHolidayOrWeekend(day)).length -
      this.countOfHolidaysInInterval(date2, date1)
    );
  }

  /** Определить является выбранная дата валидной
   * @params {Date|null|string} date Выбранная дата
   * @returns {boolean}
   * */
  isValidDate(date: string | null | Date) {
    return isValid(date);
  }

  getPreviousDayIsWeekendOrHoliday(date: Date, previousDayCount: number) {
    const yesterday = subDays(date, previousDayCount);
    return this.isHolidayOrWeekend(yesterday);
  }

  getNextDayIsWeekendOrHoliday(date: Date, afterDaycount: number) {
    const afternoon = addDays(date, afterDaycount);
    return this.isHolidayOrWeekend(afternoon);
  }

  /** Получить все дни между двумя датами
   * @params {Date} start Дата начала интервала
   * @params {Date} end Дата конца интервала
   * @returns {Date[]}
   * */
  getAllDaysOfInterval(start: Date, end: Date) {
    return eachDayOfInterval({ start, end });
  }

  /** Получить все интервалы выходных или праздничных дней
   * @params {Date[]} range Диапазон дат
   * @returns {string}
   * */
  getHolidaysOrWeekendsIntervals(range: Date[]) {
    const intervals: Date[][] = [];

    range.forEach((day, index) => {
      if (this.isHolidayOrWeekend(day)) {
        const intervalsLength = intervals.length;
        if (range[index - 1] && this.isHolidayOrWeekend(range[index - 1])) {
          intervals[intervalsLength - 1] = [
            intervals[intervalsLength - 1][0],
            day,
          ];
        } else {
          intervals[intervalsLength] = [day];
        }
      }
    });

    return intervals
      .map((interval) =>
        interval
          .map((date) => this.format.toDate(date.toDateString()))
          .join(' - '),
      )
      .join(', ');
  }
}
